import { CartSubDetails } from "services/network/_schema";

export const totalProductCost = (lookbookProduct: [CartSubDetails]) => {
  const totalProductsCost = lookbookProduct.reduce((initialAmount: any, currentAmount: any) => {
    return initialAmount + currentAmount.quantity * Number(currentAmount.amount);
  }, 0);

  return parseFloat(totalProductsCost).toLocaleString();
};

export const totalProductQuantity = (lookbookProduct: [CartSubDetails]) => {
  const productsQuantity = lookbookProduct.reduce((initialAmount: any, currentAmount: any) => {
    return initialAmount + currentAmount.quantity;
  }, 0);

  return productsQuantity;
};
