import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Icon } from "@iconify/react";
import "./styles.scss";

function CustomToggle({ children, eventKey, callback, activeEventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <p
      onClick={decoratedOnClick}
      className="fw-semi-bold text-lg text-black-500 d-flex justify-content-between click py-4"
    >
      {children}

      <Icon icon={isCurrentEventKey ? "akar-icons:minus" : "akar-icons:plus"} className="ms-2" />
    </p>
  );
}

export const FAQ = () => {
  const [activeEventKey, setActiveEventKey] = useState<number | undefined>();

  return (
    <section className="container mb-9 pt-8">
      <div className="row" id="faq-wrapper">
        <div className="col-lg-10 col-12 mx-auto">
          <p className="sub-title text-center mb-4">Frequently asked questions</p>

          <Accordion defaultActiveKey="0" flush>
            <>
              <CustomToggle
                eventKey={"0"}
                callback={(e: any) => {
                  if (e === activeEventKey) {
                    setActiveEventKey(undefined);
                  } else {
                    setActiveEventKey(e);
                  }
                }}
                activeEventKey={activeEventKey}
              >
                How does this service work?
              </CustomToggle>
              <hr />
              <Accordion.Collapse eventKey={"0"} className="p-3">
                <p className="text-lg">
                  Otitolab matches you with a personal stylist that will help you reach your style goals no matter your
                  budget, size, event, etc. Your session will start off with your stylist sending an inspirational mood
                  board to set the tone of the session. From there, your stylist will send over your shoppable style
                  boards where you can buy what you love!
                </p>
              </Accordion.Collapse>
            </>
            <>
              <CustomToggle
                eventKey={"1"}
                callback={(e: any) => {
                  if (e === activeEventKey) {
                    setActiveEventKey(undefined);
                  } else {
                    setActiveEventKey(e);
                  }
                }}
                activeEventKey={activeEventKey}
              >
                How does this service work?
              </CustomToggle>
              <hr />
              <Accordion.Collapse eventKey={"1"} className="p-3">
                <p className="text-lg">
                  Otitolab matches you with a personal stylist that will help you reach your style goals no matter your
                  budget, size, event, etc. Your session will start off with your stylist sending an inspirational mood
                  board to set the tone of the session. From there, your stylist will send over your shoppable style
                  boards where you can buy what you love!
                </p>
              </Accordion.Collapse>
            </>
            <>
              <CustomToggle
                eventKey={"2"}
                callback={(e: any) => {
                  if (e === activeEventKey) {
                    setActiveEventKey(undefined);
                  } else {
                    setActiveEventKey(e);
                  }
                }}
                activeEventKey={activeEventKey}
              >
                How does this service work?
              </CustomToggle>
              <hr />
              <Accordion.Collapse eventKey={"2"} className="p-3">
                <p className="text-lg">
                  Otitolab matches you with a personal stylist that will help you reach your style goals no matter your
                  budget, size, event, etc. Your session will start off with your stylist sending an inspirational mood
                  board to set the tone of the session. From there, your stylist will send over your shoppable style
                  boards where you can buy what you love!
                </p>
              </Accordion.Collapse>
            </>
          </Accordion>
        </div>
      </div>
    </section>
  );
};
