import React, { Fragment } from "react";

const TextInput = ({
  className,
  field,
  form: { touched, errors },
  variant,
  appendedComponent: AppendedComponent,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  return (
    <Fragment>
      <input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        className={`form-control ${className}`}
        id="description"
        placeholder="Description"
        {...props}
      />

      {hasError && (
        <div className="text-danger text-xs font-weight-normal pt-1">
          {errors[field.name]}
        </div>
      )}
    </Fragment>
  );
};

export default TextInput;
