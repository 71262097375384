import { Header } from "sharedComponents/header";
import { ItemCard } from "sharedComponents/ItemCard";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import useNetworkData from "hooks/useNetworkData";
import { GetBrandByIdRequest, GetProductByBrandOrTagIdRequest } from "services/network/products";
import { useParams } from "react-router-dom";
import { BoxLoading } from "sharedComponents/loader";
import FilterSortComponent from "sharedComponents/lookbook/filter-sort/FilterSortComponent";
import { productsSchema } from "services/network/_schema";
import { sortvalues } from "utilities";

export const BrandByIdPage = () => {
  const { isLoading, getData } = useNetworkData();
  const [brand, setBrand] = useState<any>();
  const [brandProducts, setBrandProducts] = useState([]);
  const { id: brandId } = useParams();
  const [sortValues, setSortValues] = useState<string[]>([]);
  const [sortOrder] = useState(["ascending", "descending"]);
  const [sortingOrder, setSortingOrder] = useState<string>("ascending");
  const [hideFilter] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);

  const getBrand = async () => {
    const res = await getData({
      requestName: "getBrandByNameOrID",
      apiRequest: GetBrandByIdRequest,
      payload: { value: brandId },
    });
    if (res) {
      setBrand(res.data[0]);
    }
  };

  const getBrandProducts = async () => {
    const res = await getData({
      requestName: "getProductByBrandCategoryOrTagId",
      apiRequest: GetProductByBrandOrTagIdRequest,
      payload: { value: brandId, limit: 0, offset: 0 },
    });
    if (res) {
      setBrandProducts(res.data);
      setTotalCount(res.totalCount);
    }
  };

  const getSortedData = (sortvalue: string, sortData: productsSchema[], isDate?: boolean) => {
    const sortedData = sortData.sort((a: any, b: any) => {
      if (isDate) {
        return (new Date(a[sortvalue]) as any) - (new Date(b[sortvalue]) as any);
      }
      return a[sortvalue] - b[sortvalue];
    });

    return sortedData;
  };

  const findSortedValues = (sortvalue: string) => {
    const sortedValues = sortValues.find((item) => item === sortvalue);
    return sortedValues;
  };

  const handleProductSorting = (brandProductsList: productsSchema[]) => {
    let brandProductList = brandProductsList;

    if (findSortedValues("latest")) {
      brandProductList = getSortedData("createdAt", brandProductsList, true).reverse();
    }

    if (findSortedValues("new")) {
      brandProductList = getSortedData("newPrice", brandProductsList, false);
    }

    if (findSortedValues("used")) {
      brandProductList = getSortedData("usedPrice", brandProductsList, false);
    }

    return brandProductList;
  };

  useEffect(() => {
    getBrand();
    getBrandProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={brand?.name} loading={isLoading} />
      <section className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9 col-12 mx-auto mb-5">
            {isLoading ? (
              <>
                {[...Array(6)].map((item, index) => (
                  <BoxLoading height={"3rem"} key={index} />
                ))}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center my-5">
                  <img src={brand?.image} alt="" />
                </div>
                <p className="text-lg fw-medium text-center">{brand?.description}</p>
              </>
            )}
          </div>
        </div>
      </section>
      <hr />
      {!isLoading && (
        <section className="container">
          {brandProducts.length > 0 ? (
            <>
              <section className="container ">
                <FilterSortComponent
                  hideFilter={!hideFilter}
                  sortValues={sortValues}
                  sortOrder={sortOrder}
                  sortingOrder={sortingOrder}
                  setSortingOrder={setSortingOrder}
                  setSortValues={setSortValues}
                  totalCount={totalCount}
                />
              </section>

              <div className="row mb-9 mt-5">
                {handleProductSorting(brandProducts).map(
                  (item: productsSchema, index: React.Key | null | undefined) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-5" key={index}>
                      <ItemCard
                        item={item}
                        showCheck={true}
                        imgStyles={{ height: "35rem" }}
                        cardStyles={{ padding: "2rem", marginInline: "auto" }}
                      />
                    </div>
                  )
                )}
              </div>
            </>
          ) : (
            <p className="my-9 text-xl text-center">This brand have no products</p>
          )}
        </section>
      )}
    </>
  );
};
