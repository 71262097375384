import React, { useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getStorageData, removeStorageData, StorageKeys } from "lib/storageManager";
import { confirmPaymentRequest } from "services/network/products";
import Loader from "sharedComponents/loader";
import { useQuery } from "utilities";
import { AppContext } from "context/appContext";
import { toast } from "react-toastify";

export const ConfirmPaymentPage = () => {
  const navigate = useNavigate();
  const paymentRef = useRef(true);
  const params = useQuery();
  const {
    getCart,
    mutateData,
    isMutating,
  }: {
    getCart: Function;
    mutateData: any;
    isMutating: boolean;
  } = useContext(AppContext);
  const confirmPayment = async () => {
    try {
      const paymentDetail = getStorageData(StorageKeys.paymentDetail);
      if (paymentDetail) {
        const res: any = await mutateData({
          requestName: "completeCheckout",
          apiRequest: confirmPaymentRequest,
          payload: {
            paymentIntentId: params.get("payment_intent"),
            shippingFee: paymentDetail.shippingFee,
            vat: paymentDetail.vat,
          },
        });
        getCart();
        if (res.status === "success") {
          navigate("/orders/success");
        } else {
          navigate("/orders/failed");
          toast.error(`${res.status}: ${res.message}`);
        }

        removeStorageData(StorageKeys.paymentDetail);
      }
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (paymentRef.current) {
      paymentRef.current = false;
      confirmPayment();
    }
  }, []);

  return (
    <>
      <section className="container py-6">{isMutating && <Loader title="Please wait..." />}</section>
    </>
  );
};
