import React from "react";
import { ImageComponent } from "../ImageComponent";
import "./styles.scss";
import logo from "assets/images/otito-logo-purple.svg";
import { Link } from "react-router-dom";

export const Footer = ({ navigate }: any) => {
  return (
    <footer className="bg-black-500 py-5">
      <div className="container">
        <section className="footer-line">
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div className="mb-3">
              <ImageComponent src={logo} className="mb-lg-0 mb-md-5 mb-sm-4" alt="" />
            </div>
            <div className="mb-3">
              <ul className="d-flex flex-wrap justify-content-md-start justify-content-lg-end gap-4 list-unstyled">
                <li>
                  <Link to="/our-story" className="text-decoration-none text-white">
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link to="/lookbooks" className="text-decoration-none text-white">
                    Lookbooks
                  </Link>
                </li>
                <li>
                  <Link to="/brands" className="text-decoration-none text-white">
                    Brands
                  </Link>
                </li>
                <li>
                  <Link to="/shop" className="text-decoration-none text-white">
                    Shop
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-decoration-none text-white">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="row">
            <div className="col-lg-6 col-md-10 col-sm-10">
              <ul className="d-flex flex-wrap gap-5 list-unstyled">
                <li>
                  <Link to="#" className="text-decoration-none text-white">
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link to="#" className="text-decoration-none text-white">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/#faqs" className="text-decoration-none text-white bg-transparent">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-10 col-sm-10">
              <p className="text-sm text-white d-flex justify-content-md-start justify-content-lg-end">
                © 2022 Otitolab. All rights reserved.
              </p>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};
