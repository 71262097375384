import { Header } from "sharedComponents/header";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import useNetworkData from "hooks/useNetworkData";
import Button from "sharedComponents/forms/Button";
import { ForgotPassRequest } from "services/network/auth";
import { toast } from "react-toastify";
import FloatingInput from "sharedComponents/forms/FloatingInput";

export const ResetPasswordPage = () => {
  const { isMutating, mutateData } = useNetworkData();
  const navigate = useNavigate();

  const initialValues = {
    emailAddress: "",
    newPassword: "",
    newPassword2: "",
    authCode: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup.string().label("Email Address").required(),
    authCode: yup.string().label("Authorization Code").required(),
    newPassword: yup
      .string()
      .label("New Password")
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/, "Enter at least 8 alphanumeric characters")
      .required(),
    newPassword2: yup
      .string()
      .label("Confirm Password")
      .oneOf([null, yup.ref("newPassword")], "Both passwords need to be the same")
      .required(),
  });

  const handleReset = async (formValues: any) => {
    const res: any = await mutateData({
      requestName: "forgotPassword",
      apiRequest: ForgotPassRequest,
      payload: formValues,
    });
    if (res) {
      toast.success(res.message);
      navigate("/login");
    }
  };

  return (
    <>
      <Header title="Account - Reset Password" />
      <section className="container">
        <div className="row my-9">
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
            <div className="border px-5 py-6">
              <p className="text-2xl text-black-500 fw-bold text-center mb-5">Reset Password</p>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleReset}
              >
                {({ handleSubmit, isValid }) => {
                  return (
                    <>
                      <Form onSubmit={handleSubmit} className="form-wrapper">
                        <Field
                          component={FloatingInput}
                          label="Email Address"
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          className="mt-4"
                          placeholder="Email address"
                        />
                        <Field
                          component={FloatingInput}
                          label="New Password"
                          name="newPassword"
                          id="newPassword"
                          type="password"
                          className="mt-4"
                          placeholder="New Password"
                        />
                        <Field
                          component={FloatingInput}
                          label="Confirm Password"
                          name="newPassword2"
                          id="newPassword2"
                          type="password"
                          className="mt-4"
                          placeholder="Confirm Password"
                        />
                        <Field
                          component={FloatingInput}
                          label="Authorization Code"
                          name="authCode"
                          id="authCode"
                          className="mt-4"
                          placeholder="Enter Auth Code"
                        />
                        <Link
                          to="/forgot-password"
                          className="text-decoration-none text-primary-500 fw-medium text-end d-block ms-auto w-fit"
                        >
                          Resend Code?
                        </Link>
                        <Button
                          className="btn btn-black btn-lg w-100 mt-5"
                          type="submit"
                          title={"Reset Password"}
                          loading={isMutating}
                          loadingTitle={"Resetting..."}
                          disabled={!isValid || isMutating}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <div className="d-flex justify-content-center text-sm mt-5 fw-medium">
                <Link to="/register" className="text-decoration-none primary-500">
                  New account
                </Link>
                <div className="border mx-3"></div>
                <Link to="/login" className="text-decoration-none primary-500">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
