import { useState } from "react";
import { Link } from "react-router-dom";
import { ImageComponent } from "sharedComponents/ImageComponent";
import useAddProduct from "hooks/useAddProduct";
import { Icon } from "@iconify/react";
import "./styles.scss";
import { BoxLoading } from "sharedComponents/loader";

const SavedItemsCard = ({ data, showCheck, loading, setItems, items }) => {
  const productWishList = {
    ...data.product,
    inWishList: true,
  };

  const lookbookWishList = {
    ...data.lookbook,
    inWishList: true,
  };

  const { wishlistLoading, toggleWishlist } = useAddProduct();
  const [usedPrice, setUsedPrice] = useState(true);

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 mb-5">
      <div className={`item-card`} style={{ padding: "2rem", marginInline: "auto" }}>
        {loading ? (
          <BoxLoading />
        ) : (
          <>
            {showCheck && data.sourceType === "product" && (
              <div className="d-flex gap-3 right-checkbox">
                <div className="text-sm fw-semi-bold text-primary-500">{usedPrice ? "Used" : "New"}</div>
                <div className="text-sm fw-semi-bold form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={usedPrice}
                    id="flexSwitchCheckDefault"
                    onChange={({ target: { checked } }) => {
                      setUsedPrice(checked);
                    }}
                  />
                </div>
              </div>
            )}
            <Link
              to={data.sourceType === "product" ? `/product/${data.product.id}` : `/lookbooks/${data.lookbook.id}`}
              style={{ height: "32rem" }}
            >
              <ImageComponent
                src={data.sourceType === "product" ? data.product.image[0] : data.lookbook.image}
                alt=""
              />
            </Link>
            <p className="text-sm fw-semi-bold mt-4">
              {data.sourceType === "product" ? data.product.name : `${data.lookbook.name}- Lookbook`}
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-sm text-black-500 fw-bold mb-0">
                {data.sourceType === "product"
                  ? usedPrice
                    ? `$${data.product.usedPrice.toLocaleString()}`
                    : `$${data.product.newPrice.toLocaleString()}`
                  : null}
              </p>

              {data.sourceType === "product" && (
                <>
                  {wishlistLoading === productWishList.id ? (
                    <div className="spinner-border spinner-border-sm" />
                  ) : (
                    <div
                      className="fav-icon"
                      onClick={() => {
                        toggleWishlist(productWishList, setItems, items, "product");
                      }}
                    >
                      <Icon icon="ri:heart-3-fill" className={`click text-xxl text-error`} />
                    </div>
                  )}
                </>
              )}

              {data.sourceType === "lookbook" && (
                <>
                  {wishlistLoading === lookbookWishList.id ? (
                    <div className="spinner-border spinner-border-sm" />
                  ) : (
                    <div
                      className="fav-icon"
                      onClick={() => toggleWishlist(lookbookWishList, setItems, items, "lookbook")}
                    >
                      <Icon icon="ri:heart-3-fill" className={`click text-xxl text-error`} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className=" mt-3">
              <p className="item-flag m-0">{data.sourceType === "product" ? "product" : "lookbook"}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SavedItemsCard;
