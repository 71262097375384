import React from "react";
import item14 from "assets/images/banner.png";
import { ImageComponent } from "sharedComponents/ImageComponent";
import { Link } from "react-router-dom";

export const HomeBanner = () => {
  return (
    <section className="container" id="home-banner">
      <div className="row">
        <div className="col-lg-7 col-md-11 col-12 my-auto order-2 order-lg-1 pe-lg-7">
          <p className="text-sm fw-extra-bold">OTITOLAB</p>
          <h1 className="fw-extra-bold mb-4">
            Your one stop for traditional curated lookbooks
          </h1>
          <p className="text-lg fw-semi-bold mb-5 me-5">
            Hand picked lookbooks all at your request, just order and you will
            get it at your doorstep...
          </p>
          <Link to={"/lookbooks"} className="text-decoration-none">
            <button className="btn btn-black btn-lg">Shop lookbooks</button>
          </Link>
        </div>
        <div className="col-lg-5 col-md-11 col-12 order-1 order-lg-2 mb-4 mb-lg-0">
          <ImageComponent src={item14} className="banner-image" alt="" />
          {/* <ImageComponent src={item15} className="two" alt="" />
          <ImageComponent src={item16} className="three" alt="" /> */}
        </div>
      </div>
    </section>
  );
};
