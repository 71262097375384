import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getStorageData, StorageKeys } from "lib/storageManager";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const token = getStorageData(StorageKeys.token);
  const online = navigator.onLine;

  return !online ? (
    <Navigate to="/offline" state={{ from: location.pathname }} />
  ) : token ? (
    children
  ) : (
    <Navigate replace to="/login" state={{ from: location.pathname }} />
  );
};

export default PrivateRoute;
