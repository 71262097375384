import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./styles.scss";

export const FloatingArrow = () => {
  const [showArrow, setShowArrow] = useState(false);

  const scrollTop = () => {
    window.scroll(0, 0);
  };

  const checkScroll = () => {
    if (window.scrollY === 0 || window.scrollY < 500) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`floating-arrow ${showArrow ? "" : "d-none"}`}
      onClick={scrollTop}
      onScroll={checkScroll}
    >
      <Icon icon="fontisto:angle-up" />{" "}
    </div>
  );
};
