import React from "react";
import "./styles.scss";
import Skeleton from "react-skeleton-loader";

export default function Loader({ title }: { title: string }) {
  return (
    <div className="loader-component">
      <div className="loading">
        <h2 className="loader-title">{title}</h2>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

Loader.defaultProps = {
  title: "",
};

export const BoxLoading = ({
  height,
  width,
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return (
    <Skeleton
      color="#ced4da"
      width={width || "100%"}
      height={height || "40rem"}
    />
  );
};
