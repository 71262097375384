import useNetworkData from "hooks/useNetworkData";
import React from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import { toast } from "react-toastify";
import { updatePasswordRequest } from "services/network/account";
import FloatingInput from "sharedComponents/forms/FloatingInput";
import { logoutService } from "services/network/auth";
import { useNavigate } from "react-router-dom";

export const AccountPassword = () => {
  const { isMutating, mutateData } = useNetworkData();
  const navigate = useNavigate();

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = yup.object().shape({
    currentPassword: yup.string().label("Current Password").required(),
    newPassword: yup
      .string()
      .label("New password")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        "Enter at least 8 alphanumeric characters"
      )
      .required(),
    confirmNewPassword: yup
      .string()
      .label("Confirm Password")
      .oneOf(
        [null, yup.ref("newPassword")],
        "Both passwords need to be the same"
      )
      .required(),
  });

  const handleUpdate = async (formValues: any, formikHelper: any) => {
    const res: any = await mutateData({
      requestName: "updatePassword",
      apiRequest: updatePasswordRequest,
      payload: formValues,
    });
    if (res.status === "success") {
      formikHelper.resetForm();
      toast.success("Password update successful!");
      logoutService();
      navigate("/login");
    }
  };

  return (
    <section className="container my-6">
      <div className="row">
        <div className="col-lg-5 col-md-6 col-sm-8 mx-auto">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdate}
          >
            {({ handleSubmit, isValid }) => {
              return (
                <Form onSubmit={handleSubmit} className="form-wrapper">
                  <p className="text-2xl fw-bold text-black-500 text-center">
                    Change Password
                  </p>
                  <Field
                    component={FloatingInput}
                    label="Current Password"
                    name="currentPassword"
                    id="currentPassword"
                  />
                  <Field
                    component={FloatingInput}
                    label="New Password"
                    name="newPassword"
                    id="newPassword"
                    className="mt-4"
                  />
                  <Field
                    component={FloatingInput}
                    label="Confirm Password"
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    className="mt-4"
                  />
                  <Button
                    className="btn btn-lg btn-black w-100 my-5"
                    title="Change Password"
                    loading={isMutating}
                    disabled={isMutating || !isValid}
                    loadingTitle="Please wait..."
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};
