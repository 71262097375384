import React, { useContext, useEffect, useState } from "react";
import { CartModal } from "../cartModal";
import logo from "assets/images/otito-logo-blk.svg";
import "./styles.scss";
import { ImageComponent } from "../ImageComponent";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { CustomDropdownToggle } from "sharedComponents/CustomDropdownToggle";
import { logoutService } from "services/network/auth";
import ProductSearch from "./ProductSearch";
import { AppContext } from "context/appContext";
import { Cart } from "services/network/_schema";

export const Navbar = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const [user, setUser] = useState(getStorageData(StorageKeys.user));
  const {
    cart,
    setCart,
    getCart,
  }: {
    cart: Cart[];
    setCart: any;
    getCart: Function;
  } = useContext(AppContext);

  const updateUser = () => {
    setUser(getStorageData(StorageKeys.user));
    if (getStorageData(StorageKeys.user)) {
      getCart();
    } else {
      setCart([]);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", updateUser);
    return () => window.removeEventListener("storage", updateUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav align-items-center">
              <NavLink
                to="/lookbooks"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Lookbook
              </NavLink>
              <NavLink
                to="/shop"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Shop
              </NavLink>
              <NavLink
                to="/brands"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Brands
              </NavLink>
              <ProductSearch />
            </div>
          </div>
          <NavLink className="navbar-brand" to="/">
            <ImageComponent src={logo} alt="" />
          </NavLink>

          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto">
              <NavLink
                to="/our-story"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Our Story
              </NavLink>
              {user ? (
                <Dropdown className="nav-link bg-white">
                  <Dropdown.Toggle
                    as={CustomDropdownToggle}
                    className="nav-item"
                    id="dropdown-toggle no_w_translate"
                  >
                    {user && (
                      <Icon icon="bx:user-circle" className="me-2 text-sm" />
                    )}
                    {user?.fullName || "Account"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <NavDropdown.Item
                      as={Link}
                      to="/account"
                      className="click nav-link"
                    >
                      <Icon icon="bx:user-circle" className="me-3" />
                      My Account
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="click text-error nav-link"
                      onClick={() => logoutService()}
                    >
                      <Icon icon="bx:power-off" className="me-3" />
                      Logout
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Link className="nav-link" to={"/login"}>
                  Login/Register
                </Link>
              )}
              <button
                className="nav-link bg-transparent position-relative"
                type="button"
                onClick={() => setCartOpen(!cartOpen)}
              >
                Cart{" "}
                {cart.length > 0 ? (
                  <span className="count">
                    {cart.length > 10 ? "10+" : cart.length}
                  </span>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <CartModal show={cartOpen} handleClose={() => setCartOpen(false)} />
    </>
  );
};
