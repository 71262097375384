import { Icon } from "@iconify/react";
import { Header } from "sharedComponents/header";
import React, { useContext, useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import useAddProduct from "hooks/useAddProduct";
import { AppContext } from "context/appContext";
import { Cart, shippingSchema } from "services/network/_schema";
import { ImageComponent } from "sharedComponents/ImageComponent";
import Button from "sharedComponents/forms/Button";
import { useShareCart } from "hooks/useShareCart";
import { UpdateCartModal } from "sharedComponents/updateCartModal";
import { totalProductCost, totalProductQuantity } from "lib/lookbookDetails";

export const OrderSummaryPage = () => {
  const {
    cart,
    setCart,
    totalPrice,
    subTotalPrice,
    shipping,
    vat,
    vatPercent,
    totalCount,
    mutateData,
  }: {
    cart: Cart[];
    setCart: any;
    totalPrice: number;
    subTotalPrice: number;
    totalCount: number;
    vatPercent: number;
    vat: number;
    shipping: shippingSchema;
    mutateData: any;
  } = useContext(AppContext);
  const { removeFromCart, isRemoveCart } = useAddProduct();
  const { addingEmail, emailAddresses, setEmailAddresses, shareCart } = useShareCart(mutateData);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState<Cart>();

  const toggleEditModal = (cart?: Cart | undefined) => {
    setSelectedCart(cart);
    setShowEditModal(!showEditModal);
  };

  const getTotal = (item: Cart) => {
    if (item) return Number(item.details[0].amount) * item.details[0].quantity;

    return 0;
  };

  return (
    <>
      <Header
        title={`Order Summary (${totalCount})`}
        subTitle="Complete your autumn wardrobe with trendy pieces"
        progress={1}
      />
      <section className="container order-summary-wrapper mt-6">
        {cart.map((item) => (
          <div className="order mb-5" key={item.cartId}>
            <div className="d-flex align-items-center sec_1">
              <div className="me-3">
                {item.product && item.itemCategory === "product" && (
                  <ImageComponent
                    src={item.product.heroImage || item.product.image[0]}
                    alt="lookbook-thumbnail"
                    className="itemImage"
                  />
                )}

                {item.lookbook && item.itemCategory === "lookbook" && (
                  <ImageComponent src={item.lookbook.image} alt="lookbook-thumbnail" className="itemImage" />
                )}
              </div>
              <div>
                {item.product && (
                  <Link
                    to={`/product/${item.product.id}`}
                    className="text-xl text-black-500 fw-semi-bold mb-1 text-decoration-none"
                  >
                    {item.product.name}
                  </Link>
                )}

                {item.lookbook && (
                  <Link
                    to={`/lookbooks/${item.lookbook.id}`}
                    className="text-xl text-black-500 fw-semi-bold mb-1 text-decoration-none"
                  >
                    {item.lookbook.name}
                  </Link>
                )}

                <p className="text-sm text-grey-300 fw-medium">
                  {item.product ? item.product.shortInfo : item.lookbook && `${item.lookbook.name} Lookbook`}
                </p>
              </div>
            </div>

            {item.product && (
              <div className="sec_2 increment-btn" onClick={() => toggleEditModal(item)}>
                <span className="click">
                  <Icon icon="ant-design:minus-outlined" />
                </span>
                <span className="text-nowrap">
                  {item.details[0].quantity} pc{item.details[0].quantity > 1 && "s"}
                </span>
                <span className="click">
                  <Icon icon="akar-icons:plus" className="click" />
                </span>
              </div>
            )}

            {item.lookbook && (
              <div className="sec_2 increment-btn">
                <span className="text-nowrap">
                  {totalProductQuantity(item.details)} product{totalProductQuantity(item.details) > 1 && "s"}
                </span>
              </div>
            )}

            <div className="sec_3 color-box-wrapper">
              <div className="color-box" style={{ backgroundColor: item.details[0].color }}></div>
              <Icon icon="akar-icons:chevron-down" className="click" onClick={() => toggleEditModal(item)} />
            </div>

            <div className="sec_4 size-wrapper">
              <p className="text-lg text-black-500 my-auto text-uppercase">{item.details[0].size}</p>
              <Icon icon="akar-icons:chevron-down" className="click" onClick={() => toggleEditModal(item)} />
            </div>

            <p className="sec_4 text-lg fw-medium text-black-500 mb-0">
              $
              {item.product && item.itemCategory === "product"
                ? Number(getTotal(item)).toLocaleString()
                : item.lookbook && totalProductCost(item.details)}
            </p>
            <div className="sec_5">
              {isRemoveCart === item.cartId ? (
                <span className="spinner-border spinner-border-sm text-error" />
              ) : (
                <Icon
                  icon="fluent:delete-32-regular"
                  className="text-xl click text-error"
                  onClick={() => {
                    removeFromCart(item.cartId);
                  }}
                />
              )}
            </div>
          </div>
        ))}
        <hr className="mt-5" />
        <div className="row gy-5 my-5">
          <div className="col-lg-5 col-md-12 col-sm-12 mb-5">
            <p className="text-lg fw-bold text-black-500">Share cart with friends</p>
            <input
              type="email"
              className="form-control"
              id="emailAddresses"
              name="emailAddresses"
              value={emailAddresses}
              placeholder="Email address (s)"
              onChange={({ target: { value } }) => {
                setEmailAddresses(value);
              }}
            />
            <p className="text-xs fw-medium text-purple">
              Separate each address with a comma (,). E.G: Johndoe@gmail.com, Jane@gmail.com
            </p>
            <Button
              className="btn btn-black btn-lg px-9"
              title="Share"
              loading={addingEmail}
              disabled={addingEmail || !emailAddresses}
              loadingTitle={"Sharing..."}
              onClick={() => shareCart()}
            />
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 ms-auto">
            <div className="border p-5">
              {/* <p className="text-black-500 fw-semi-bold text-base">Do you have a coupon or gift certificate?</p>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Coupon code"
                  aria-label="Recipient's username"
                />
                <button
                  className="btn btn-outline-secondary bg-black-500 text-white text-sm"
                  type="button"
                  id="button-addon2"
                >
                  Apply coupon
                </button>
              </div> */}
              {/* <br />
              <br /> */}
              <p className="fw-bold text-xxl text-black-500 mb-4">Total in cart</p>
              <div className="d-flex justify-content-between mb-3">
                <p className="text-sm">Total without delivery :</p>
                <p className="text-sm"> ${subTotalPrice.toLocaleString()}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="text-sm">Total VAT ({vatPercent}%) :</p>
                <p className="text-sm"> ${vat.toLocaleString()}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="text-sm text-black-500 fw-semi-bold">Total price :</p>
                <p className="text-sm text-black-500 fw-semi-bold">
                  ${(totalPrice - (shipping?.fee || 0)).toLocaleString()}
                </p>
              </div>
              <Link to="customer-info" className="text-decoration-none">
                <button className="btn btn-black btn-lg w-100">Proceed to checkout</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <UpdateCartModal
        selectedCart={selectedCart}
        cart={cart}
        setCart={setCart}
        show={showEditModal}
        handleClose={toggleEditModal}
        mutateData={mutateData}
      />
    </>
  );
};
