import { AppProvider } from "context/appContext";
import { OurStoryPage } from "pages/guest/OurStory";
import OfflinePage from "pages/guest/_offlinePage";
import { ForgotPasswordPage } from "pages/guest/auth/forgotPasswordPage";
import { LoginPage } from "pages/guest/auth/loginPage";
import { RegisterPage } from "pages/guest/auth/registerPage";
import { ResetPasswordPage } from "pages/guest/auth/resetPasswordPage";
import { BrandsPage } from "pages/guest/brands";
import { BrandByIdPage } from "pages/guest/brands/brandById";
import { ContactPage } from "pages/guest/contact";
import { HomePage } from "pages/guest/homePage";
import { LookBookPage } from "pages/guest/lookBookPage";
import { ProductDetailPage } from "pages/guest/productDetail";
import { ShopPage } from "pages/guest/shopPage";
import { useLayoutEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountRoutes from "routes/accountRoute";
import OrdersRoutes from "routes/ordersRoutes";
import { Layout } from "sharedComponents/Layout";
import ScrollToTop from "sharedComponents/ScrollToTop";
import LookBookDetails from "pages/guest/lookbookDetails";
import "./App.scss";
// import Loader from "sharedComponents/loader";
// const AccountRoutes = lazy(() => import("routes/accountRoute"));
// const OrdersRoutes = lazy(() => import("routes/ordersRoutes"));

function App() {
  useLayoutEffect(() => {
    document.title = "Otito Lab";
  }, []);

  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        {/* <Suspense
          fallback={
            <Layout>
              <div className="d-flex justify-content-center align-items-center vh-100">
                <Loader />
              </div>
            </Layout>
          }
        > */}
        <Routes>
          <Route element={<Layout>{<Outlet />}</Layout>}>
            <Route path="/" element={<HomePage />} />
            <Route path="/offline" element={<OfflinePage />} />
            <Route path="/lookbooks" element={<LookBookPage />} />
            <Route path="/lookbooks/:id" element={<LookBookDetails />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/our-story" element={<OurStoryPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/account/*" element={<AccountRoutes />} />
            <Route path="brands">
              <Route path="" element={<BrandsPage />} />
              <Route path=":id/:name" element={<BrandByIdPage />} />
            </Route>
            <Route path="/orders/*" element={<OrdersRoutes />} />
            <Route path="*" element={<HomePage />} />
          </Route>
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          toastClassName="px-2 shadow-sm rounded-md font-weight-normal"
        />
        {/* </Suspense> */}
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
