import { useEffect, useState } from "react";
import "./styles.scss";
import { ItemCard } from "../ItemCard";
import { SliderNextArrow, SliderPrevArrow } from "../customSliderArrows";
import Slider from "react-slick";
import { BoxLoading } from "sharedComponents/loader";
import { lookbookSchema, productsSchema } from "services/network/_schema";
import { GetLookbookRequest } from "services/network/lookbooks";
import useNetworkData from "hooks/useNetworkData";
import { LookbookBanner } from "sharedComponents/lookbookBanner";
import FilterSortComponent from "sharedComponents/lookbook/filter-sort/FilterSortComponent";
import { lookBookFilters } from "services/network/_schema";
import { Link, useLocation } from "react-router-dom";

const Grid = ({
  className,
  isLoading,
  products,
}: {
  className?: string;
  isLoading?: boolean;
  products?: productsSchema[];
}) => {
  return (
    <div className="lookbook-grid-template">
      {isLoading ? (
        <>
          {[...Array(4)].map((_item, index) => (
            <BoxLoading height={"19rem"} key={index} />
          ))}
        </>
      ) : (
        <>
          {products && !!products.length
            ? products.map((item, index) => <ItemCard item={item} key={index} className={className} />)
            : null}
        </>
      )}
    </div>
  );
};
export const LookbookGrid = ({
  displayCount,
  sectionClassNames,
  hideFilterAndSort,
}: {
  displayCount?: number;
  sectionClassNames?: string;
  hideFilterAndSort?: boolean;
}) => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [productsFilterValue, setproductsFilterValue] = useState<string[]>([]);
  const { isLoading, getData, response } = useNetworkData();
  const [lookbooks, setLookbooks] = useState<lookbookSchema[]>([]);
  const [filterOptions, setFilterOptions] = useState<lookBookFilters>({
    limit: 0,
    offset: 0,
    category: undefined,
    tags: undefined,
    products: undefined,
  });

  const [sortValues, setSortValues] = useState<string[]>([]);
  const sortingOrder = "ascending";

  // category filter
  const updateFilterList = (filterValue: string) => {
    const currentlyExists = selectedFilter.find((item) => item === filterValue);
    if (currentlyExists) {
      setSelectedFilter(selectedFilter.filter((item) => item !== filterValue));
      return;
    }
    const newItem = filterValue;
    setSelectedFilter([...selectedFilter, newItem]);
  };

  // filter tags
  const updateTagsList = (tagsValue: string) => {
    const currentlyExists = selectedTags.find((item) => item === tagsValue);
    if (currentlyExists) {
      setSelectedTags(selectedTags.filter((item) => item !== tagsValue));
      return;
    }

    const newItem = tagsValue;
    setSelectedTags([...selectedTags, newItem]);
  };

  const updateProductsFilter = (productValue: string) => {
    const currentlyExists = productsFilterValue.find((item: any) => item === productValue);

    if (currentlyExists) {
      setproductsFilterValue(productsFilterValue.filter((item) => item !== productValue));
      return;
    }

    const newItem = productValue;
    setproductsFilterValue([...productsFilterValue, newItem]);
  };

  const getSortedData = (sortvalue: string, sortData: productsSchema[], isDate?: boolean) => {
    const sortedData = sortData.sort((a: any, b: any) => {
      if (isDate) {
        return (new Date(a[sortvalue]) as any) - (new Date(b[sortvalue]) as any);
      }
      return a[sortvalue] - b[sortvalue];
    });

    return sortedData;
  };

  const findSortedValues = (sortvalue: string) => {
    const sortedValues = sortValues.find((item) => item === sortvalue);
    return sortedValues;
  };

  const formattedProducts = (products: productsSchema[]) => {
    const formattedList: productsSchema[][] = [];
    let getsorteddata = products;

    if (displayCount) {
      let list = getsorteddata.slice(0, 4);
      formattedList.push(list);
    } else {
      if (findSortedValues("new")) {
        getsorteddata =
          sortingOrder === "ascending"
            ? getSortedData("newPrice", products, false)
            : getSortedData("newPrice", products, false).reverse();
      }

      if (findSortedValues("used")) {
        getsorteddata =
          sortingOrder === "ascending"
            ? getSortedData("usedPrice", products, false)
            : getSortedData("usedPrice", products, false).reverse();
      }

      const totalLength = products.length;
      const perfectDivision = Math.floor(totalLength / 4);
      let current = 0;

      for (let index = 0; index <= perfectDivision; index++) {
        const list = getsorteddata.slice(current, current + 4);
        if (list.length > 0) {
          formattedList.push(list);
        }
        current = current + 4;
      }
    }

    return formattedList;
  };

  const formattedLookbooks = (lookbookData: lookbookSchema[]) => {
    let getSortedLookbook;

    if (findSortedValues("latest")) {
      getSortedLookbook = lookbookData
        .sort((a: any, b: any) => {
          return (new Date(a["createdAt"]) as any) - (new Date(b["createdAt"]) as any);
        })
        .reverse();
    }

    getSortedLookbook = lookbookData;

    return getSortedLookbook;
  };

  useEffect(() => {
    const getLookbookData = async () => {
      const res = await getData({
        requestName: "getAllLookBooks",
        apiRequest: GetLookbookRequest,
        payload: filterOptions,
      });

      if (res) {
        setLookbooks(displayCount ? res.data.slice(0, displayCount) : res.data);
      }
    };

    getLookbookData();
  }, [filterOptions, displayCount, getData]);

  useEffect(() => {
    setFilterOptions((prevFilterOptions) => ({
      ...prevFilterOptions,
      category: selectedFilter.length === 0 ? undefined : selectedFilter,
      tags: selectedTags.length === 0 ? undefined : selectedTags,
      products: productsFilterValue.length === 0 ? undefined : productsFilterValue,
    }));
  }, [selectedFilter, selectedTags, productsFilterValue]);

  return (
    <>
      {!hideFilterAndSort && (
        <section className="container ">
          <FilterSortComponent
            selectedFilter={selectedFilter}
            selectedTags={selectedTags}
            updateFilterList={updateFilterList}
            sortValues={sortValues}
            setSortValues={setSortValues}
            updateTagsList={updateTagsList}
            updateProductsFilter={updateProductsFilter}
            productsFilterValue={productsFilterValue}
            totalCount={response?.totalCount}
            hideFilter={true}
          />
        </section>
      )}

      {isLoading ? (
        <section className={`container ${sectionClassNames}mt-7`} id="lookbook-grid-wrapper">
          <p className="sub-title">Lookbooks</p>
          <div className="row gy-5">
            <div className="col-lg-6 col-12">
              <BoxLoading />
            </div>
            <div className="col-lg-6 col-12">
              <Grid isLoading={isLoading} />
            </div>
          </div>
        </section>
      ) : (
        <>
          {formattedLookbooks(lookbooks).map((lookbook) => (
            <section className={`container ${sectionClassNames} mt-7`} id="lookbook-grid-wrapper" key={lookbook.id}>
              <div className="d-flex align-items-center justify-content-between">
                <p className="sub-title">{currentLocation === "/" ? "Lookbooks" : lookbook.name || "Lookbooks"}</p>

                {currentLocation === "/" && (
                  <Link to={"/lookbooks"} className="text-decoration-none">
                    <button className="btn btn-black btn-lg">See more</button>
                  </Link>
                )}
              </div>

              <div className="row gy-5 lookbook-wrapper">
                <div className="col-lg-6 col-12 ">
                  <LookbookBanner lookbook={lookbook} />
                </div>
                <div className="col-lg-6 col-12 lookbook-product-wrapper">
                  <Slider
                    speed={1000}
                    infinite={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    className="general-carousel test"
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                  >
                    {formattedProducts(lookbook.products).map((each, index) => (
                      <div className="slider-item" key={index}>
                        <Grid products={each} />
                        <div></div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </section>
          ))}
        </>
      )}
    </>
  );
};
