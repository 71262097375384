import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import { GetProductByIdRequest } from "services/network/products";
import { productsSchema } from "services/network/_schema";
import { Link } from "react-router-dom";

const RenderSuggestions = ({
  suggestions,
  click,
}: {
  suggestions: productsSchema[];
  click: any;
}) => {
  return suggestions.length ? (
    <div className="search-dropdown text-base fw-medium">
      {suggestions.slice(0, 10).map((suggestion) => (
        <Link
          key={suggestion.id}
          className="d-flex align-items-center w-100 p-2 text-decoration-none border-bottom"
          to={`/product/${suggestion.id}`}
          onClick={click}
        >
          <span className="ms-2">{suggestion.name}</span>
        </Link>
      ))}
    </div>
  ) : null;
};

export default function ProductSearch(props: {
  inputClassName?: string;
  inputPlaceHolder?: string;
}) {
  const { isLoading, getData, response } = useNetworkData();
  const [search, setSearch] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  const searchRef = useRef<any>(null);

  const handleChange = (value: string) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getData({
        requestName: "getProductByNameOrId",
        apiRequest: GetProductByIdRequest,
        payload: { value },
      });
    }, 500);
    setTimer(newTimer);
  };

  const handleClickOutside = (event: { target: any }) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className="product-search-wrapper d-flex flex-row align-items-center justify-content-center mx-4 position-relative"
      ref={searchRef}
    >
      {!search ? (
        <Icon
          icon="bytesize:search"
          className="text-lg mt-2 click"
          onClick={() => setSearch(true)}
        />
      ) : (
        <>
          <input
            type="search"
            onChange={({ target: { value } }) => handleChange(value)}
            placeholder={`${props.inputPlaceHolder || "Enter your search"}`}
            className={props.inputClassName || "text-lg form-control"}
          />
          {isLoading && <div className="spinner-border spinner-border-sm" />}
          {response && (
            <RenderSuggestions
              suggestions={response.data}
              click={() => {
                setSearch(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
