import React, { useContext } from "react";
import { Icon } from "@iconify/react";
import { ImageComponent } from "sharedComponents/ImageComponent";
import "./styles.scss";
import useAddProduct from "hooks/useAddProduct";
import { AppContext } from "context/appContext";
import { Cart, shippingSchema } from "services/network/_schema";
import { Link } from "react-router-dom";
import { totalProductCost, totalProductQuantity } from "lib/lookbookDetails";

export const CartSummary = () => {
  const {
    cart,
    subTotalPrice,
    totalPrice,
    totalCount,
    vat,
    vatPercent,
    shipping,
  }: {
    cart: Cart[];
    subTotalPrice: number;
    totalPrice: number;
    totalCount: number;
    vat: number;
    vatPercent: number;
    shipping: shippingSchema;
  } = useContext(AppContext);
  const { removeFromCart, isRemoveCart } = useAddProduct();

  return (
    <div className="cart-summary-container">
      <div className="p-3 p-md-4 p-lg-5 bg-white">
        <div className="d-flex justify-content-between flex-wrap mb-3">
          <p className="fw-medium text-sm text-black-500">
            #214243 Order
            <span className="text-grey-100 fw-regular ps-2 click text-xs">
              change name
              <Icon icon="bx:edit-alt" className="ps-2 text-grey-300 text-xl" />
            </span>
          </p>
          <Link to="/orders" className="fw-medium text-sm text-decoration-none text-black-500">
            Return to cart
          </Link>
        </div>
        <p className="text-lg fw-semi-bold text-black-500">Cart ({totalCount})</p>
        {cart.map((item) => (
          <div className="row mb-3" key={item.cartId}>
            <div className="col-3 image-item">
              <ImageComponent
                src={item.product ? item.product.image[0] : item.lookbook && item.lookbook.image}
                alt=""
              />
            </div>
            <div className="col-9">
              <div className="d-flex justify-content-between">
                <p className="text-lg mb-1 fw-semi-bold text-black-500">
                  {item.product ? item.product.name : item.lookbook && item.lookbook.name}
                </p>
                {isRemoveCart === item.cartId ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <Icon
                    icon="fluent:delete-32-regular"
                    className="text-xl click"
                    onClick={() => {
                      removeFromCart(item.cartId);
                    }}
                  />
                )}
              </div>
              <p className="text-sm mb-1 fw-medium">
                {item.product ? item.product.shortInfo : item.lookbook && `${item.lookbook.name} Lookbook`}
              </p>
              {item.product && (
                <p className="text-sm mb-1 fw-medium">
                  Size : <span className="text-uppercase">{item.details[0].size}</span>
                </p>
              )}

              <div className={`d-flex  ${item.lookbook ? "justify-content-end" : "justify-content-between"}`}>
                {item.product && (
                  <p className="text-sm mb-1 fw-medium">
                    Color : <span className="text-capitalize">{item.product && item.details[0].color}</span>
                  </p>
                )}
                <p className="text-sm mb-1 fw-bold text-black-500">
                  $
                  {item.product && item.itemCategory === "product"
                    ? parseFloat(item.details[0].amount).toLocaleString()
                    : item.lookbook && totalProductCost(item.details)}
                  <span className="text-xs fw-medium text-grey-300">
                    X
                    {item.product && item.itemCategory === "product"
                      ? item.details[0].quantity
                      : item.lookbook && totalProductQuantity(item.details)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}

        <hr className="my-5" />
        <div>
          <div className="d-flex justify-content-between">
            <p className="fw-regular text-sm">Subtotal :</p>
            <p className="fw-regular text-sm"> ${subTotalPrice.toLocaleString()}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="fw-regular text-sm">Total VAT ({vatPercent}%) :</p>
            <p className="fw-regular text-sm"> ${vat.toLocaleString()}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="fw-regular text-sm">Delivery :</p>
            <p className="fw-regular text-sm"> ${shipping?.fee ? shipping.fee.toLocaleString() : "0.00"}</p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <p className="fw-bold text-black-500 text-sm">Total price :</p>
            <p className="fw-bold text-black-500 text-sm"> ${totalPrice.toLocaleString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
