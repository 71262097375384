import React, { useState } from "react";
import { ImageComponent } from "../ImageComponent";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { BoxLoading } from "sharedComponents/loader";
import { productsSchema } from "services/network/_schema";
import useAddProduct from "hooks/useAddProduct";

export const ItemCard = ({
  item,
  showCheck,
  imgStyles,
  cardStyles,
  className,
  loading,
  newItem,
}: {
  item: productsSchema;
  showCheck?: boolean;
  imgStyles?: object;
  cardStyles?: object;
  className?: string;
  loading?: boolean;
  newItem?: boolean;
}) => {
  const { wishlistLoading, toggleWishlist } = useAddProduct();
  const [usedPrice, setUsedPrice] = useState(true);

  return (
    <div className={`item-card ${className}`} style={cardStyles}>
      {loading ? (
        <BoxLoading />
      ) : (
        <>
          {newItem && <div className="just-in text-sm fw-semi-bold text-primary-500">Just in</div>}
          {!newItem && showCheck && (
            <div className="d-flex gap-3 right-checkbox">
              <div className="text-sm fw-semi-bold text-primary-500">{usedPrice ? "Used" : "New"}</div>
              <div className="text-sm fw-semi-bold form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={usedPrice}
                  id="flexSwitchCheckDefault"
                  onChange={({ target: { checked } }) => {
                    setUsedPrice(checked);
                  }}
                />
              </div>
            </div>
          )}

          <Link to={`/product/${item.id}`} style={imgStyles}>
            <ImageComponent src={item.heroImage || item.image[0]} alt="" />
          </Link>
          <p className="text-sm fw-semi-bold mt-4">{item.name}</p>

          <p className="text-xs text-grey-300 fw-medium">{item.shortInfo}</p>
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-sm text-black-500 fw-bold mb-0">
              ${usedPrice ? item.usedPrice.toLocaleString() : item.newPrice.toLocaleString()}
            </p>
            {wishlistLoading === item.id ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              <div className="fav-icon" onClick={() => toggleWishlist(item)}>
                <Icon
                  icon={item?.inWishList ? "ri:heart-3-fill" : "ri:heart-3-line"}
                  className={`click text-xxl ${item.inWishList ? "text-error" : ""}`}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
