import { DateTime } from "luxon";
import React from "react";
import { orderSchema } from "services/network/_schema";
import { ImageComponent } from "sharedComponents/ImageComponent";

export const OrderDetails = ({ item }: { item: orderSchema }) => {
  return (
    <div className="row py-5 border-bottom">
      <div className="col-lg-6 col-md-10 col-sm-12 mb-4">
        {item.items.map((x, index) => (
          <div className="row mb-4" key={index}>
            <div className="col-lg-3 col-4">
              {x.product && <ImageComponent src={x.product.heroImage || x.product.image[0]} alt="" className="w-100" />}
            </div>
            <div className="col-lg-9 col-8">
              <p className="text-lg mb-1 fw-medium text-black-500">{x.product?.name}</p>
              <p className="text-sm mb-1 fw-medium text-grey-300">{x.product?.shortInfo}</p>
              <p className="text-sm mb-1 fw-medium text-grey-300">Size : {x.size}</p>
              <div className="d-flex justify-content-between">
                <p className="text-sm mb-1 fw-medium text-grey-300">Color : {x.color}</p>
                <p className="text-sm mb-1 fw-semi-bold text-black-500">
                  ${x.amount}
                  <span className="text-xs fw-medium text-grey-300">X{x.quantity}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-lg-6 col-md-10 col-sm-12 mb-4 ps-lg-6 ">
        <p className="fw-semi-bold text-xl text-black-500">Shipping details</p>
        <div className="d-flex justify-content-between">
          <p className="text-sm text-nowrap">Contact :</p>
          <p className="text-lg text-black-500 fw-semi-bold text-end">{item.shippingAddress?.phoneNumber}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="text-sm text-nowrap">Ship to :</p>
          <p className="text-lg text-black-500 fw-semi-bold text-end">
            {item.shippingAddress?.street}, {item.shippingAddress?.city}, {item.shippingAddress?.country}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="text-sm text-nowrap">Payment method :</p>
          <p className="text-lg text-black-500 fw-semi-bold text-end">Credit card</p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="text-sm text-nowrap">Payment Status:</p>
          <p className="text-lg text-black-500 fw-semi-bold text-end text-primary-500">{item.paymentStatus}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="text-sm text-nowrap">Date Created:</p>
          <p className="text-lg text-black-500 fw-semi-bold text-end">
            {DateTime.fromJSDate(new Date(item.createdAt)).toFormat("dd LLL, yyyy")}
          </p>
        </div>
        {item.deliveryDate && (
          <p className="text-sm fw-medium text-black-500">
            Delivery Date:{" "}
            <span className="text-decoration-underline">
              {DateTime.fromJSDate(new Date(item.deliveryDate)).toFormat("dd, LLL yyyy")}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
