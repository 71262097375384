import React, { useLayoutEffect } from "react";
import { CustomerInfoPage } from "pages/authenticated/checkout/customerInfo";
import { OrderSummaryPage } from "pages/authenticated/checkout/orderSummary";
import { Route, Routes } from "react-router-dom";
import { DeliveryPage } from "pages/authenticated/checkout/deliveryPage";
import { ConfirmPaymentPage } from "pages/authenticated/checkout/confirmPayment";
import { BillingPage } from "pages/authenticated/checkout/billingPage";
import PrivateRoute from "sharedComponents/PrivateRoute";
import { PaymentFailed } from "pages/authenticated/checkout/paymentFailed";
import { SuccessPage } from "pages/authenticated/checkout/successPage";

const OrdersRoutes = () => {
  useLayoutEffect(() => {
    document.title = "Otito Lab - Orders";
  }, []);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute>
              <OrderSummaryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="customer-info"
          element={
            <PrivateRoute>
              <CustomerInfoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="delivery"
          element={
            <PrivateRoute>
              <DeliveryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="billing"
          element={
            <PrivateRoute>
              <BillingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="success"
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
        />
        <Route
          path="confirm-payment"
          element={
            <PrivateRoute>
              <ConfirmPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="failed"
          element={
            <PrivateRoute>
              <PaymentFailed />
            </PrivateRoute>
          }
        />
        {/* <Route path="*" element={NotFoundPage} /> */}
      </Routes>
    </>
  );
};

export default OrdersRoutes;
