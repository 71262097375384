import React, { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ItemWithDetail } from "sharedComponents/itemWithDetail";
import { getReceivedCartRequest, getSharedCartRequest } from "services/network/account";
import useNetworkData from "hooks/useNetworkData";
import Loader from "sharedComponents/loader";
import { Paginator } from "sharedComponents/paginator";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const ListItem = ({ items, pagingInfo, handlePageClick, received }: any) => {
  return (
    <>
      {items.length > 0 ? (
        <>
          <Accordion flush className="container mt-6">
            {items.map((item: any, index: number) => (
              <ItemWithDetail key={index} shared={true} item={item} received={received} />
            ))}
          </Accordion>
          {pagingInfo.total > 10 && (
            <div className="mt-7 d-flex justify-content-end">
              <Paginator
                pageCount={Math.ceil(pagingInfo.total / pagingInfo.limit)}
                handlePageClick={handlePageClick}
                forcePage={pagingInfo.current}
              />
            </div>
          )}
        </>
      ) : (
        <p className="my-9 text-xl text-center">You have no {received ? "received item" : "shared cart"}</p>
      )}
    </>
  );
};

export const SharedCart = () => {
  const { getData } = useNetworkData();
  const [sharedItems, setSharedItems] = useState([]);
  const [receivedItems, setReceivedItems] = useState([]);
  const [loadingR, setLoadingR] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const [pagingInfo, setPagingInfo] = useState({
    limit: 6,
    offset: 0,
    total: 0,
    current: 1,
  });

  const getSharedCart = useCallback(async (offset: number) => {
    setLoadingS(true);
    const res = await getData({
      requestName: "getAllSharedCart",
      payload: { ...pagingInfo, offset },
      apiRequest: getSharedCartRequest,
    });
    setLoadingS(false);
    if (res) {
      setSharedItems(res.data);
      setPagingInfo((prev) => ({ ...prev, total: res.totalCount, offset }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllReceivedCart = useCallback(async (offset: number) => {
    setLoadingR(true);
    const res = await getData({
      requestName: "getAllReceivedCart",
      payload: { ...pagingInfo, offset },
      apiRequest: getReceivedCartRequest,
    });
    setLoadingR(false);
    if (res) {
      setReceivedItems(res.data);
      setPagingInfo((prev) => ({ ...prev, total: res.totalCount, offset }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSharedCart(0);
    getAllReceivedCart(0);
  }, [getSharedCart]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * pagingInfo.limit) % pagingInfo.total;
    setPagingInfo((prev) => ({
      ...prev,
      offset: newOffset,
      current: event.selected,
    }));
    getSharedCart(newOffset);
  };

  return (
    <section className="shared-cart-wrapper">
      <Tabs className="mb-3" defaultActiveKey={"received"}>
        <Tab eventKey="received" title={<p className="text-sm fw-semi-bold text-black-500 mb-0">Received</p>}>
          {loadingR ? (
            <div className="mt-4">
              <Loader title="Getting details..." />
            </div>
          ) : (
            <ListItem items={receivedItems} handlePageClick={handlePageClick} pagingInfo={pagingInfo} received={true} />
          )}
        </Tab>

        <Tab eventKey="sent" title={<p className="text-sm fw-semi-bold text-black-500 mb-0">Sent</p>}>
          {loadingS ? (
            <div className="mt-4">
              <Loader title="Getting details..." />
            </div>
          ) : (
            <ListItem items={sharedItems} handlePageClick={handlePageClick} pagingInfo={pagingInfo} />
          )}
        </Tab>
      </Tabs>
    </section>
  );
};
