import useNetworkData from "hooks/useNetworkData";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { GetCartRequest } from "services/network/products";
import { Cart, shippingSchema } from "services/network/_schema";

export const AppContext: any = createContext(null);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const token = getStorageData(StorageKeys.token);
  const [cart, setCart] = useState<Cart[]>([]);
  const [shipping, setShipping] = useState<shippingSchema>();
  const { isLoading, getData, mutateData, isMutating } = useNetworkData();
  const vatPercent = 10;

  const subTotalPrice = useMemo(() => {
    if (cart) {
      const total = cart.reduce((prev: number, current: Cart) => {
        const cartItems = current.details.reduce((initialQuantity, currentQuantity) => {
          return initialQuantity + parseFloat(currentQuantity.amount) * currentQuantity.quantity;
        }, 0);

        return prev + cartItems;
      }, 0);

      return total;
    }
    return 0;
  }, [cart]);

  const vat = useMemo(() => {
    if (subTotalPrice) {
      return subTotalPrice * (vatPercent / 100);
    }
    return 0;
  }, [subTotalPrice, vatPercent]);

  const totalPrice = useMemo(() => {
    if (subTotalPrice && vat) {
      return subTotalPrice + vat + (shipping?.fee || 0);
    }
    return 0;
  }, [subTotalPrice, vat, shipping]);

  const totalCount = useMemo(() => {
    if (cart) {
      const total: any = cart.reduce((prev: number, current: Cart) => {
        const cartItems = current.details.reduce((initialQuantity, currentQuantity) => {
          return initialQuantity + currentQuantity.quantity;
        }, 0);

        return prev + cartItems;
      }, 0);
      return total;
    }
  }, [cart]);

  const getCart = async () => {
    try {
      const res = await getData({
        requestName: "getUserCart",
        apiRequest: GetCartRequest,
      });
      if (res?.data) {
        setCart(res.data);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (error) {
      console.log(`An error on CONTEXT PAGE: ${error}`);
    }
  };

  useEffect(() => {
    if (token) {
      getCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        totalPrice,
        subTotalPrice,
        totalCount,
        getCart,
        shipping,
        setShipping,
        vat,
        vatPercent,
        isLoading,
        getData,
        mutateData,
        isMutating,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
