import { Icon } from "@iconify/react";
import { CartSummary } from "sharedComponents/cartSummary";
import { Header } from "sharedComponents/header";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "context/appContext";
import { shippingSchema } from "services/network/_schema";

export const DeliveryPage = () => {
  const {
    shipping,
  }: {
    shipping: shippingSchema;
  } = useContext(AppContext);
  const navigate = useNavigate();

  if (!shipping) {
    navigate("/orders/customer-info");
    return null;
  }

  return (
    <>
      <Header title="Delivery" subTitle="Complete your autumn wardrobe with trendy pieces" progress={3} />
      <section className="container mt-6">
        <div className="row">
          <div className="col-lg-6 col-md-10 col-sm-12 order-2 order-lg-1 mb-5">
            <p className="fw-medium text-black-500 text-xl mb-4">Shipping details</p>
            <div className=" fw-medium grid-display">
              <div className="details-wrapper">
                <p className="text-sm label">Contact Email :</p>
                <p className="text-base text-black-500">{shipping?.email}</p>
              </div>
            </div>
            <div className="fw-medium grid-display">
              <div className="details-wrapper">
                <p className="text-sm label">Contact Phone :</p>
                <p className="text-base text-black-500">{shipping?.phoneNumber}</p>
              </div>
              <Link to="/orders/customer-info" className="text-xs text-primary-300 click text-decoration-none">
                Edit
              </Link>
            </div>
            <div className=" fw-medium grid-display">
              <div className="details-wrapper">
                <p className="text-sm label">Ship to :</p>
                <p className="text-base text-black-500">
                  {shipping?.street},{shipping?.city}.
                </p>
              </div>
              <Link to="/orders/customer-info" className="text-xs text-primary-300 click text-decoration-none">
                Edit
              </Link>
            </div>

            <hr />
            <div className="d-flex align-items-center justify-content-between mt-5">
              <div className="d-flex gap-2">
                <div className="mt-1 click text-primary-500">
                  <Icon icon="akar-icons:check-box" className="text-base" />
                </div>
                <p className="text-sm text-black-500 mb-0">
                  <span className="fw-medium">Delivery fee</span>
                  <span className="text-grey-300">(Delivery within 5 - 7 working days)</span>
                </p>
              </div>
              <p className="text-sm text-black-500 mb-0">${shipping?.fee ? shipping.fee.toLocaleString() : "0.00"}</p>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                <button
                  className="btn btn-black btn-lg w-100"
                  disabled={!shipping}
                  onClick={() => {
                    navigate("/orders/billing");
                  }}
                >
                  Proceed to payment
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-12 order-1 order-lg-2 ms-lg-auto mb-5">
            <CartSummary />
          </div>
        </div>
      </section>
    </>
  );
};
