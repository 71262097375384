import { Icon } from "@iconify/react";
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { editProductInCartRequest } from "services/network/products";
import { Cart } from "services/network/_schema";
import { CustomDropdwonItem } from "sharedComponents/CustomDropdwonItem";
import Button from "sharedComponents/forms/Button";
import { ImageComponent } from "sharedComponents/ImageComponent";
import { totalProductCost, totalProductQuantity } from "lib/lookbookDetails";
import "./styles.scss";

export const UpdateCartModal = ({
  show,
  handleClose,
  cart,
  setCart,
  selectedCart,
  mutateData,
}: {
  show: boolean;
  handleClose: any;
  cart: Cart[];
  setCart: any;
  selectedCart: Cart | undefined;
  mutateData: Function;
}) => {
  const [updating, setUpdating] = useState(false);
  const [tempPayload, setTempPayload] = useState<{
    cartId: string;
    quantity: number;
    color: string;
    size: string;
  }>();

  const handleSave = async () => {
    if (tempPayload) {
      setUpdating(true);
      const res = await mutateData({
        requestName: "editProductInCart",
        apiRequest: editProductInCartRequest,
        payload: tempPayload,
      });
      setUpdating(false);
      if (res?.status === "success") {
        toast.success("Item Updated!");
        // update cart
        const copy = [...cart];
        const index = copy.findIndex((x) => x.cartId === selectedCart?.cartId);

        const updatedItem: Cart = {
          ...cart[index],
          details: [
            {
              ...cart[index].details[0],
              quantity: tempPayload.quantity,
              color: tempPayload.color,
              size: tempPayload.size,
            },
          ],
        };
        copy.splice(index, 1, updatedItem);

        setCart(copy);
        handleClose();
      }
    }
  };

  const handleUpdate = (value: any) => {
    setTempPayload((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    if (selectedCart) {
      setTempPayload({
        cartId: selectedCart.cartId,
        quantity: selectedCart.details[0].quantity,
        color: selectedCart.details[0].color,
        size: selectedCart.details[0].size,
      });
    }
  }, [selectedCart]);

  const totalPrice = useMemo(() => {
    if (tempPayload && selectedCart) return Number(selectedCart.details[0].amount) * tempPayload.quantity;

    return 0;
  }, [selectedCart, tempPayload]);

  if (!selectedCart || !tempPayload) return null;

  return (
    <Modal show={show} onHide={handleClose} centered className="updateCartModal">
      <Modal.Header closeButton className="p-4">
        <Modal.Title className="fw-bold">
          {selectedCart.product ? "Update Cart Item" : selectedCart.lookbook && "View Cart Item Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="order mb-5">
          <div className="d-flex align-items-center mb-4">
            <div className="me-3">
              <ImageComponent
                src={
                  selectedCart.product
                    ? selectedCart.product.image[0]
                    : selectedCart.lookbook && selectedCart.lookbook.image
                }
                alt=""
                className="itemImage"
              />
            </div>
            <div>
              <p className="text-xl text-black-500 fw-semi-bold mb-1 text-decoration-none">
                {selectedCart.product ? selectedCart.product.name : selectedCart.lookbook && selectedCart.lookbook.name}
              </p>
              <p className="text-sm text-grey-300 fw-medium">
                {selectedCart.product
                  ? selectedCart.product.shortInfo
                  : selectedCart.lookbook && `${selectedCart.lookbook.name} Lookbook`}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {selectedCart.product ? (
              <div className="increment-btn">
                <span
                  className="click"
                  onClick={() => {
                    if (tempPayload.quantity > 1) {
                      handleUpdate({ quantity: tempPayload.quantity - 1 });
                    }
                  }}
                >
                  <Icon icon="ant-design:minus-outlined" />
                </span>

                <span className="text-nowrap">
                  {tempPayload.quantity} pc{tempPayload.quantity > 1 && "s"}
                </span>
                <span
                  className="click"
                  onClick={() => {
                    if (selectedCart.product && tempPayload.quantity < selectedCart.product.units) {
                      handleUpdate({ quantity: tempPayload.quantity + 1 });
                    }
                  }}
                >
                  <Icon icon="akar-icons:plus" className="click" />
                </span>
              </div>
            ) : (
              selectedCart.lookbook && (
                <div className="increment-btn">
                  <span className="text-nowrap">
                    {totalProductQuantity(selectedCart.details)} product
                    {totalProductQuantity(selectedCart.details) > 1 && "s"}
                  </span>
                </div>
              )
            )}

            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle id="dropdown-toggle" className="no-toggle p-0">
                <div className="color-box-wrapper">
                  {selectedCart.product ? (
                    <div className="color-box" style={{ backgroundColor: tempPayload.color }}></div>
                  ) : (
                    selectedCart.lookbook && (
                      <div className="color-box" style={{ backgroundColor: selectedCart.details[0].color }}></div>
                    )
                  )}

                  <Icon icon="akar-icons:chevron-down" className="click" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="fw-medium">
                {selectedCart.product &&
                  selectedCart.product.color.map((item) => (
                    <Dropdown.Item className="click" key={item} as={CustomDropdwonItem}>
                      <div
                        className="text-capitalize d-flex gap-3 align-items-center click"
                        onClick={() => handleUpdate({ color: item })}
                      >
                        <div className="color-box" style={{ backgroundColor: item }}></div>
                        <span>{item}</span>
                      </div>
                    </Dropdown.Item>
                  ))}

                {selectedCart.lookbook &&
                  selectedCart.details.map((item, index) => (
                    <Dropdown.Item className="click" key={index} as={CustomDropdwonItem}>
                      <div className="text-capitalize d-flex gap-3 align-items-center click">
                        <div className="color-box" style={{ backgroundColor: item.color }}></div>
                        <span>{item.color}</span>
                      </div>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle id="dropdown-toggle" className="no-toggle p-0">
                <div className="color-box-wrapper">
                  <p className="text-lg text-black-500 my-auto text-uppercase">{tempPayload.size}</p>
                  <Icon icon="akar-icons:chevron-down" className="click" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="fw-medium">
                {selectedCart.product &&
                  selectedCart.product.sizes.map((item) => (
                    <Dropdown.Item className="click" key={item} as={CustomDropdwonItem}>
                      <div
                        className="text-capitalize d-flex gap-3 align-items-center click fw-bold"
                        onClick={() => handleUpdate({ size: item })}
                      >
                        {item}
                      </div>
                    </Dropdown.Item>
                  ))}

                {selectedCart.lookbook &&
                  selectedCart.details.map((item, index) => (
                    <Dropdown.Item className="click" key={index} as={CustomDropdwonItem}>
                      <div className="text-capitalize d-flex gap-3 align-items-center click fw-bold">{item.size}</div>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>

            <p className="text-lg fw-medium text-black-500 mb-0">
              $
              {selectedCart.product && selectedCart.itemCategory === "product"
                ? totalPrice.toLocaleString()
                : selectedCart.lookbook && totalProductCost(selectedCart.details)}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="px-4">
        <Button className="btn btn-md btn-black-000" onClick={() => handleClose()} title="Close" />
        {selectedCart.product && (
          <Button
            className="btn btn-md btn-purple"
            onClick={handleSave}
            loading={updating}
            title="Save Changes"
            loadingTitle="Saving..."
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
