import { Icon } from "@iconify/react";
import React, { useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ImageComponent } from "../ImageComponent";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context/appContext";
import { Cart, shippingSchema } from "services/network/_schema";
import useAddProduct from "hooks/useAddProduct";
import Loader from "sharedComponents/loader";
import { totalProductCost, totalProductQuantity } from "lib/lookbookDetails";

interface Props {
  show: boolean;
  handleClose: Function;
}

export const CartModal = ({ show, handleClose }: Props) => {
  const navigate = useNavigate();
  const {
    cart,
    totalPrice,
    subTotalPrice,
    isLoading,
    totalCount,
    vat,
    vatPercent,
    shipping,
  }: {
    cart: Cart[];
    totalPrice: number;
    subTotalPrice: number;
    isLoading: boolean;
    totalCount: number;
    vat: number;
    vatPercent: number;
    shipping: shippingSchema;
  } = useContext(AppContext);
  const { removeFromCart, isRemoveCart } = useAddProduct();

  const viewCart = () => {
    navigate("/orders");
    handleClose();
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement={"top"} id="cart-modal">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h5 className="text-lg fw-medium text-black-500 mb-0 mt-4 px-4">Cart ({totalCount})</h5>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="cart-item-body">
          {isLoading ? (
            <Loader title="Loading..." />
          ) : (
            <>
              {totalCount === 0 ? (
                <div className="text-black-200 text-center mt-7">
                  <Icon icon="mdi:cart-remove" fontSize={120} />
                </div>
              ) : (
                <>
                  {cart.map((item) => (
                    <div className="row mb-5" key={item.cartId}>
                      <div className="col-3 image-item">
                        {item.product && item.itemCategory === "product" && (
                          <ImageComponent src={item.product.image[0]} alt="" />
                        )}

                        {item.lookbook && item.itemCategory === "lookbook" && (
                          <ImageComponent src={item.lookbook.image} alt="lookbook-hero-image" />
                        )}
                      </div>
                      <div className="col-9">
                        <div className="d-flex justify-content-between">
                          <p className="text-lg mb-1 fw-semi-bold text-black-500">
                            {item.product && item.itemCategory === "product"
                              ? item.product.name
                              : item.lookbook && item.itemCategory === "lookbook" && `${item.lookbook.name}-Lookbook`}
                          </p>
                          {isRemoveCart === item.cartId ? (
                            <span className="spinner-border spinner-border-sm text-error" />
                          ) : (
                            <Icon
                              icon="fluent:delete-32-regular"
                              className="text-xl icon-pointer text-error"
                              onClick={() => {
                                removeFromCart(item.cartId);
                              }}
                            />
                          )}
                        </div>

                        {item.product && (
                          <p className="text-sm mb-1 fw-medium">
                            Size : <span className="text-uppercase">{item.details[0].size}</span>
                          </p>
                        )}

                        {item.product && (
                          <p className="text-sm mb-1 fw-medium">
                            Color : <span className="text-capitalize">{item.product && item.details[0].color}</span>
                          </p>
                        )}

                        <div className={`d-flex justify-content-between`}>
                          {item.product ? (
                            <p className="text-sm mb-1 fw-medium">
                              Quantity : <span className="text-uppercase">{item.details[0].quantity}</span>
                            </p>
                          ) : (
                            item.lookbook && (
                              <p className="text-sm mb-1 fw-medium">
                                No. of products :{" "}
                                <span className="text-uppercase">{totalProductQuantity(item.details)}</span>
                              </p>
                            )
                          )}

                          <p className="text-sm mb-1 fw-bold text-black-500">
                            $
                            {item.product && item.itemCategory === "product"
                              ? parseFloat(item.details[0].amount).toLocaleString()
                              : item.lookbook && totalProductCost(item.details)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div className="mt-auto mb-5">
                <hr className="my-5" />
                <div className="row">
                  <div className="col-9 ms-auto">
                    <div className="d-flex justify-content-between">
                      <p className="fw-regular text-sm">Total without delivery :</p>
                      <p className="fw-regular text-sm"> ${subTotalPrice.toLocaleString()}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="fw-regular text-sm">VAT ({vatPercent}%) :</p>
                      <p className="fw-regular text-sm"> ${vat.toLocaleString()}</p>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <p className="fw-bold text-black-500 text-sm">Total price :</p>
                      <p className="fw-bold text-black-500 text-sm">
                        {" "}
                        ${(totalPrice - (shipping?.fee || 0)).toLocaleString()}
                      </p>
                    </div>
                  </div>

                  <button className="btn btn-black btn-lg w-100 mt-5" disabled={totalCount === 0} onClick={viewCart}>
                    View Cart
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
