import React, { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Icon } from "@iconify/react";
import "./styles.scss";
import { productsSchema, reviewSchema } from "services/network/_schema";
import useNetworkData from "hooks/useNetworkData";
import { GetProductReviewRequest, ReviewProductRequest } from "services/network/products";
import Button from "sharedComponents/forms/Button";
import { Ratings } from "sharedComponents/ratings/ratings";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { getStorageData, StorageKeys } from "lib/storageManager";

function CustomToggle({ children, eventKey, callback, activeEventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <p
      onClick={decoratedOnClick}
      className="fw-semi-bold text-lg text-black-500 d-flex justify-content-between click py-5"
    >
      {children}

      <span className="accordion-icon ms-2">
        <Icon icon={isCurrentEventKey ? "akar-icons:minus" : "akar-icons:plus"} />
      </span>
    </p>
  );
}

export const ReviewDetail = ({ product }: { product: productsSchema }) => {
  const [activeEventKey, setActiveEventKey] = useState<string | undefined>("0");
  const user = getStorageData(StorageKeys.user);
  const [reviews, setReviews] = useState<reviewSchema[]>([]);
  const { getData, isLoading, isMutating, mutateData } = useNetworkData();
  const [newReview, setNewReview] = useState({
    comment: "",
    ratings: 0,
  });

  const getReviews = useCallback(async () => {
    const res = await getData({
      requestName: "getProductReview",
      payload: { id: product.id, limit: 0, offset: 0 },
      apiRequest: GetProductReviewRequest,
    });
    if (res.status === "success") {
      setReviews(res.data);
    }
  }, [getData, product.id]);

  useEffect(() => {
    getReviews();
  }, [getReviews, product.id]);

  const submitReview = async () => {
    if (newReview.comment && newReview.ratings > 0) {
      const rIndex = reviews.findIndex((r) => r.user.id === user.id);

      const res = await mutateData({
        requestName: "reviewProduct",
        payload: { ...newReview, id: product.id },
        apiRequest: ReviewProductRequest,
      });
      if (res?.status === "success") {
        const newR = {
          id: `${Math.random() * 12}`,
          user,
          comment: newReview.comment,
          ratings: newReview.ratings,
          updatedAt: new Date(),
        };
        if (rIndex >= 0) {
          const copy = [...reviews];
          copy.splice(rIndex, 1, newR);
          setReviews(copy);
          toast.success("Thank you for updating your review! 😃");
        } else {
          setReviews((prev) => [...prev, newR]);
          toast.success("Thank you for your feedback! 😃");
        }
        setNewReview({ comment: "", ratings: 0 });
      }
    }
  };

  return (
    <Accordion defaultActiveKey="0" flush>
      <>
        <CustomToggle
          eventKey={"0"}
          callback={(e: any) => {
            if (e === activeEventKey) {
              setActiveEventKey(undefined);
            } else {
              setActiveEventKey(e);
            }
          }}
          activeEventKey={activeEventKey}
        >
          Details{" "}
        </CustomToggle>
        <hr />
        <Accordion.Collapse eventKey={"0"} className="p-4">
          <p className="text-sm fw-medium text-grey-300">{product?.fullDetails}</p>
        </Accordion.Collapse>
      </>
      <>
        <CustomToggle
          eventKey={"1"}
          callback={(e: any) => {
            if (e === activeEventKey) {
              setActiveEventKey(undefined);
            } else {
              setActiveEventKey(e);
            }
          }}
          activeEventKey={activeEventKey}
        >
          Delivery and return
        </CustomToggle>
        <hr />
        <Accordion.Collapse eventKey={"1"} className="p-4">
          <div className="text-sm fw-medium text-grey-300">
            <p className="mb-4">
              Delivery is free for orders over $5,000. For orders less than $50,000, the delivery price is $5,000.
            </p>

            <p className="mb-4">We will deliver the goods within 3-5 working days after receiving the order.</p>

            <p className="mb-4">
              You can return the goods within 30 days of receiving the order. The refund is made to the address:
            </p>

            <p className="mb-4">
              The cost of return is borne by the buyer. The return can also be made in the store during the working
              hours of the store.
            </p>

            <p className="mb-4">
              Item replacement is not possible. If you want to exchange one item for another, you need to make a refund
              and make a new purchase after the refund.
            </p>
          </div>
        </Accordion.Collapse>
      </>
      {!isLoading && (
        <>
          <CustomToggle
            eventKey={"2"}
            callback={(e: any) => {
              if (e === activeEventKey) {
                setActiveEventKey(undefined);
              } else {
                setActiveEventKey(e);
              }
            }}
            activeEventKey={activeEventKey}
          >
            Reviews
          </CustomToggle>
          <hr />
          <Accordion.Collapse eventKey={"2"} className="p-4">
            <div className="text-sm fw-medium text-grey-300">
              <div className="d-flex justify-content-between align-items-center mt-4">
                <p>Reviews ({reviews.length})</p>

                {product.ratings && (
                  <p className="d-flex align-items-center">
                    <span className="mb-1 me-2">
                      <Icon icon="emojione:star" />
                    </span>
                    <span>{product.ratings}</span>
                  </p>
                )}
              </div>
              {reviews.map((review: reviewSchema) => (
                <div className="mb-5" key={review.id}>
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <p className="mb-0 fw-semi-bold line-height-1">{review.user.fullName}</p>
                      <Ratings rating={review.ratings} display={true} />
                    </div>
                    <div className="text-grey-100 text-xs text-capitalize">
                      {DateTime.fromJSDate(new Date(review.updatedAt)).toRelativeCalendar()}
                    </div>
                  </div>
                  <p className="text-xs fw-medium text-black-500">{review.comment}</p>
                </div>
              ))}

              {/* <p className="text-center mt-3 click">
                See more
                <Icon icon="gridicons:dropdown" />
              </p> */}
              <p>Write a comment</p>
              <textarea
                name="comment"
                className="form-control mb-4"
                id=""
                cols={30}
                rows={10}
                value={newReview.comment}
                onChange={({ target: { value } }) => {
                  setNewReview((prev) => ({ ...prev, comment: value }));
                }}
              ></textarea>
              <p>
                <span className="me-3">Leave a rating*</span>
                <Ratings
                  display={false}
                  rating={newReview.ratings}
                  setRating={(value: number) => setNewReview((prev) => ({ ...prev, ratings: value }))}
                />
              </p>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <Button
                    className="btn btn-black btn-lg w-100"
                    onClick={submitReview}
                    title="Submit"
                    loadingTitle="Submitting..."
                    loading={isMutating}
                    disabled={isMutating || !newReview.comment || newReview.ratings === 0}
                  />
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </>
      )}
    </Accordion>
  );
};
