import React, { useEffect } from "react";
import offline from "assets/images/Offline.gif";
import { useLocation, useNavigate } from "react-router";
import "./styles.scss";

const OfflinePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: "/" };

  const online = navigator.onLine;

  const retry = () => {
    navigate(from);
  };

  useEffect(() => {
    if (online) {
      retry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  return (
    <div className="notFound_page">
      <img src={offline} alt="" />
      <p className="text-base">
        Oops...!!! You are not connected to the internet. Please <br />
        check your connection and try again
      </p>
      <div className="d-flex justify-content-center">
        <button className="btn btn-black-100 px-5" onClick={retry}>
          Retry
        </button>
      </div>
    </div>
  );
};

export default OfflinePage;
