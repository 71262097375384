import React, { useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Button from "sharedComponents/forms/Button";
import { Icon } from "@iconify/react";
import { removeStorageData, StorageKeys } from "lib/storageManager";
import { toast } from "react-toastify";
import { shippingSchema } from "services/network/_schema";

export const CheckoutForm = ({
  options,
  totalPrice,
  shipping,
}: {
  options: any;
  totalPrice: number;
  shipping: shippingSchema;
}) => {
  const [agreement, setAgreement] = useState(false);
  const [obligations, setObligations] = useState(false);
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  const makePayment = async (e: { preventDefault: () => void }) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setLoading(true);
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_BASE_URL}/orders/confirm-payment`,
        },
      });

      setLoading(false);
      if (result.error) {
        removeStorageData(StorageKeys.paymentDetail);
        toast.error(result.error.message);
      }
    } catch (error) {}
  };

  return (
    <form onSubmit={makePayment}>
      <div>
        <PaymentElement />
      </div>
      <hr />
      <p className="fw-medium text-black-500 text-lg mt-5">Billing address</p>
      <div className="d-flex align-items-start mb-5">
        <div className="mt-1 me-2">
          <Icon icon="akar-icons:check-box" className="click text-primary-500 text-sm" />
        </div>
        <p className="text-sm text-black-500 mb-0">Same as shipping address</p>
      </div>
      <hr />
      <div className="text-sm text-black-500 fw-medium my-3 form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="agreement"
          id="agreement"
          onChange={({ target: { checked } }) => {
            setAgreement(checked);
          }}
        />
        <label className="form-check-label click" htmlFor="agreement">
          I agree to the Otitolab Terms and Conditions
        </label>
      </div>
      <div className="text-sm text-black-500 fw-medium mb-3 form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="obligations"
          id="obligations"
          onChange={({ target: { checked } }) => {
            setObligations(checked);
          }}
        />
        <label className="form-check-label click" htmlFor="obligations">
          I am aware that the order includes payment obligation
        </label>
      </div>
      <div className="mt-5">
        <Button
          type="submit"
          className="btn btn-black btn-lg w-100"
          title="Confirm and make payment"
          disabled={!shipping || !agreement || !obligations || totalPrice <= 0 || !stripe || loading}
          loading={loading}
        />
      </div>
    </form>
  );
};
