import { Icon } from "@iconify/react";
import React, { useState } from "react";

const PasswordInput = ({
  className,
  field,
  form: { touched, errors },
  variant,
  appendedComponent: AppendedComponent,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="position-relative">
        <input
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          className={`form-control ${className}`}
          id="description"
          placeholder="Description"
          {...props}
          type={showPassword ? "text" : "password"}
        />
        <span
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          style={{
            position: "absolute",
            right: "1.6rem",
            bottom: "0.9rem",
            fontSize: "2rem",
            cursor: "pointer",
          }}
        >
          <Icon
            icon={
              showPassword ? "mdi:eye-minus-outline" : "mdi:eye-lock-outline"
            }
          />
        </span>
      </div>
      {hasError && (
        <div className="text-danger text-xs font-weight-normal pt-1">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
