import { Icon } from "@iconify/react";
import React from "react";
import "./styles.scss";

const ratings: {
  [key: string]: string;
} = {
  1: "Poor 👎",
  2: "Unsatisfactory 😔",
  3: "Good 🙂",
  4: "Very Good 😃",
  5: "Outstanding 😁",
};

export const Ratings = ({
  rating,
  display,
  setRating,
}: {
  rating: number | null;
  display: boolean;
  setRating?: (value: number) => void;
}) => {
  return (
    <span className="ratings-wrapper">
      {display ? (
        <>
          {[...Array(5)].map((item, index) => (
            <Icon
              key={index}
              icon={!rating || rating < index + 1 ? "ei:star" : "emojione:star"}
            />
          ))}
        </>
      ) : (
        <>
          {[...Array(5)].map((item, index) => (
            <Icon
              key={index}
              icon={!rating || rating < index + 1 ? "ei:star" : "emojione:star"}
              className="click text-xxl"
              onClick={() => {
                if (setRating) setRating(index + 1);
              }}
            />
          ))}
          {rating && rating > 0 ? (
            <span className="ms-3 fw-semi-bold">{ratings[rating]}</span>
          ) : null}
        </>
      )}
    </span>
  );
};
