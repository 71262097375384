import { Header } from "sharedComponents/header";
import { SuggestedItems } from "sharedComponents/suggestedItems";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AccountData } from "./accountData";
import { AccountPassword } from "./accountPassword";
import { AccountOrders } from "./orders";
import { SavedItems } from "./savedItems";
import { SharedCart } from "./sharedCart";
import "./styles.scss";
import { useSearchParams } from "react-router-dom";
import cart from "assets/images/shopping-cart.svg";
import heart from "assets/images/heart-add.svg";
import share from "assets/images/share.svg";
import key from "assets/images/key.svg";
import edit from "assets/images/user-edit.svg";

const AccountPage = () => {
  const [activeKey, setActiveKey] = useState<any>("orders");
  let [searchParams, setSearchParams] = useSearchParams();
  const [orderCount, setOrderCount] = useState<string>();

  return (
    <>
      <Header title="My account" />
      <section className="container my-9 account-wrapper">
        <Tabs
          className="mb-3"
          activeKey={searchParams.get("q") || activeKey}
          onSelect={(k: any) => {
            setActiveKey(k);
            setSearchParams({ q: k });
          }}
          fill
        >
          <Tab
            eventKey="orders"
            title={
              <div className="tab-title">
                <span className="position-relative">
                  {orderCount ? <span className="count order-count">{orderCount}</span> : null}
                  <img src={cart} alt="" className="mb-2" />
                </span>
                <p className="text-sm fw-semi-bold text-black-500">Orders</p>
              </div>
            }
          >
            <AccountOrders setOrderCount={setOrderCount} />
          </Tab>
          <Tab
            eventKey="saved-items"
            title={
              <div className="tab-title">
                <img src={heart} alt="" className="mb-2" />
                <p className="text-sm fw-semi-bold text-black-500">Saved items</p>
              </div>
            }
          >
            <SavedItems />
          </Tab>
          <Tab
            eventKey="shared-carts"
            title={
              <div className="tab-title">
                <img src={share} alt="" className="mb-2" />
                <p className="text-sm fw-semi-bold text-black-500">Shared carts</p>
              </div>
            }
          >
            <SharedCart />
          </Tab>
          <Tab
            eventKey="account-data"
            title={
              <div className="tab-title">
                <img src={edit} alt="" className="mb-2" />
                <p className="text-sm fw-semi-bold text-black-500">Account data</p>
              </div>
            }
          >
            <AccountData />
          </Tab>
          <Tab
            eventKey="password"
            title={
              <div className="tab-title">
                <img src={key} alt="" className="mb-2" />
                <p className="text-sm fw-semi-bold text-black-500">Password</p>
              </div>
            }
          >
            <AccountPassword />
          </Tab>
        </Tabs>
      </section>
      <section className="container mb-9">
        <SuggestedItems title="Recently viewed" />
      </section>
    </>
  );
};
export default AccountPage;
