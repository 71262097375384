import React from "react";
import { ImageComponent } from "sharedComponents/ImageComponent";
import item17 from "assets/images/Testimonial.png";
import Slider from "react-slick";
import {
  SliderNextArrow,
  SliderPrevArrow,
} from "sharedComponents/customSliderArrows";

export const Testimonial = () => {
  return (
    <section className="container culture-wrapper">
      <p className="sub-title">Testimonial</p>

      <Slider
        speed={1000}
        infinite={false}
        slidesToShow={1}
        slidesToScroll={1}
        className="general-carousel"
        prevArrow={<SliderPrevArrow />}
        nextArrow={<SliderNextArrow />}
      >
        {[...Array(4)].map((item, index) => (
          <div key={index}>
            <div className="row g-5 ps-lg-5 pe-lg-6 align-items-center">
              <div className="col-lg-7 col-md-7 pt-5 col-sm-12 order-2 order-lg-1 fw-medium pe-lg-5">
                <p className="text-xl">
                  We had an incredible experience working with Adekola and were
                  impressed they made such a big difference in only three weeks.
                  Our team is so grateful for the wonderful improvements he made
                  and his ability to get familiar with the product concept so
                  quickly. It acted as a catalyst to take our design to the next
                  level and get more eyes on our product.
                </p>
                <br />
                <br />
                <p className="text-2xl text-black-500 fw-bold">Sean Luke</p>
                <p className="text-lg text-black-500">CEO at ASD</p>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 order-1 order-lg-2">
                <ImageComponent src={item17} alt="" key={index} />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};
