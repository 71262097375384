
import { useParams, useNavigate } from "react-router-dom";
import "./styles.scss";
import { useState, useRef, useEffect, MouseEvent } from "react";
import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import Button from "sharedComponents/forms/Button";
import { GetLookbookByNameOrId, getLookBookEditInProgress } from "services/network/lookbooks";
import { BoxLoading } from "sharedComponents/loader";
import { lookbookDetailsSchema, productsSchema } from "services/network/_schema";
import useAddProduct from "hooks/useAddProduct";
import { toast } from "react-toastify";

type selectedSchema = {
  newPrice?: boolean;
  color?: string;
  size?: string;
  productId?: string;
};

const IconifyIcon = () => {
  return (
    <>
      <Icon
        icon="mingcute:shopping-bag-2-fill"
        style={{
          marginRight: "6px",
          fontSize: "16px",
        }}
      />
    </>
  );
};

const LookBookDetails = () => {
  const { id } = useParams();
  const { isLoading, getData } = useNetworkData();
  const [lookbookData, setLookbookData] = useState<lookbookDetailsSchema[]>([]);
  const [buyLookbook, setBuyLookbook] = useState(false);
  const [selected, setSelected] = useState<selectedSchema>();
  const [selectedArray, setSelectedArray] = useState<selectedSchema[] | any>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const imageRef = useRef<HTMLDivElement>(null);
  const [imageIndex, setImageIndex] = useState<string>("");
  const { isAddToCart, addLookbookToCart, saveCustomizedLookbook } = useAddProduct();
  const [isLast, setIsLast] = useState(0);
  const navigate = useNavigate();

  const handleScrollintoView = (i: number) => {
    setCurrentProductIndex(i);
    const activeSlide = document.querySelector(`#image-${i}`);
    activeSlide?.scrollIntoView({
      behavior: "smooth",
    });
  };

  // display next and previous item
  const nextProduct = (data: productsSchema[]) => {
    const lastItem = data.length - 1;
    if (currentProductIndex === lastItem) {
      setCurrentProductIndex(lastItem);
      setIsLast((prev) => prev + 1);
      return;
    }

    setCurrentProductIndex((prev) => prev + 1);
    setIsLast((prev) => prev + 1);
  };

  const previousProduct = (data: productsSchema[]) => {
    const lastItem = data.length - 1;
    if (currentProductIndex === 0) {
      setCurrentProductIndex(0);
      setIsLast(0);
      return;
    }

    if (currentProductIndex === lastItem) {
      setCurrentProductIndex(lastItem);
      setIsLast((prev) => prev - 1);
    }

    setCurrentProductIndex((prev) => prev - 1);
    setIsLast((prev) => prev - 1);
  };

  const handleSelectColor = (e: MouseEvent<HTMLButtonElement>): void => {
    const target = e.target as HTMLButtonElement;
    setSelected({
      ...selected,
      color: target.value,
    });
  };

  const handleSelectSize = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    setSelected({
      ...selected,
      size: target.value,
    });
  };

  const handleSelectedFeatures = (selectedArray: any, id: string, data: productsSchema[], selected: any) => {
    const exists = selectedArray.some((product: any) => product.productId === id);

    if (!exists) {
      setSelectedArray([...selectedArray, selected]);
      saveCustomizedLookbook(
        lookbookData[0].id,
        [...selectedArray, selected].map((selectedProducts: any) => {
          return {
            quantity: 1,
            color: selectedProducts.color,
            size: selectedProducts.size,
            productId: selectedProducts.productId,
            newPrice: selectedProducts.newPrice || false,
          };
        })
      );
      nextProduct(data);

      setSelected({});
    } else {
      const newSelectedArray = selectedArray.filter((selected: any) => selected.productId !== id);
      setSelectedArray([...newSelectedArray, selected]);
      saveCustomizedLookbook(
        lookbookData[0].id,
        [...selectedArray, selected].map((selectedProducts: any) => {
          return {
            quantity: 1,
            color: selectedProducts.color,
            size: selectedProducts.size,
            productId: selectedProducts.productId,
            newPrice: selectedProducts.newPrice || false,
          };
        })
      );

      nextProduct(data);
      setSelected({});
    }
  };

  useEffect(() => {
    const getLookbookById = async () => {
      try {
        const res = await getData({
          requestName: "getLookBookByNameOrId",
          apiRequest: GetLookbookByNameOrId,
          payload: { value: id },
        });

        if (res.status === "success") {
          const productId = res.data[0]?.products[currentProductIndex].id;

          setLookbookData(res.data);
          setSelected({
            ...selected,
            productId,
            color: selectedArray.find((product: any) => product.productId === productId)?.color || "",
          });

          setImageIndex(res.data[0]?.products[currentProductIndex].image[0]);
        }
      } catch (error) {
        toast.error("Failed to fetch lookbook details.");
      }
    };

    getLookbookById();
  }, []);

  useEffect(() => {
    if (lookbookData.length > 0) {
      setSelected({
        ...selected,
        productId: lookbookData[0]?.products[currentProductIndex].id,
      });

      setImageIndex(lookbookData[0]?.products[currentProductIndex].image[0]);
    }

    if (isLast === lookbookData[0]?.products.length) {
      setBuyLookbook(true);
    } else {
      setBuyLookbook(false);
    }
  }, [currentProductIndex, isLast]);

  useEffect(() => {
    const getEditLookbookProgress = async () => {
      try {
        const res = await getData({
          requestName: "getLookBookEditInProgress",
          apiRequest: getLookBookEditInProgress,
          payload: { lookbookId: id },
        });

        if (res.data) {
          setSelectedArray(res.data.details);
        }
      } catch (error: any) {
        console.log(error);
      }
    };

    getEditLookbookProgress();
  }, []);

  return (
    <>
      {isLoading ? (
        <BoxLoading />
      ) : (
        <>
          {lookbookData.length > 0 && (
            <>
              {lookbookData.map((lookbook: lookbookDetailsSchema) => {
                return (
                  <section className=" py-5 custom-container " key={lookbook.id}>
                    <div className="back-button d-flex align-items-center">
                      <button onClick={() => navigate(-1)}>
                        <Icon icon="fontisto:angle-left" />
                      </button>

                      <p className="m-0 p-0 text-capitalize">back</p>
                    </div>
                    <article className="w-100 my-5">
                      <h3 className="lh-base sub-title">{lookbook.name || "Lookbook"}</h3>
                      <p className="fs-5 lh-base"> {lookbook.description || "Lookbook Detail"}</p>
                    </article>

                    <section className="products-wrapper d-flex py-5">
                      <div className="product-image-nav" ref={imageRef}>
                        {lookbook.products.map((product: productsSchema, i: number) => {
                          const { name, image } = product;
                          let displayImage = image[0];
                          const findItem = selectedArray.find(
                            (product: selectedSchema) => product.productId === lookbook.products[i].id
                          )
                            ? false
                            : selected?.color === undefined || selected?.size === undefined;

                          return (
                            <div
                              className={`d-block ${findItem && "opacity-low"}`}
                              id={`image-${i}`}
                              key={i}
                              onClick={() => !findItem && handleScrollintoView(i)}
                            >
                              <div className={`image-nav ${currentProductIndex === i && "active-image-nav"}`}>
                                <img src={displayImage} alt="dots" />
                              </div>
                              <p className="dot-name">{name}</p>
                            </div>
                          );
                        })}
                      </div>

                      <section className="product-information d-flex">
                        <div className="product-image d-flex">
                          <div className="product-image-wrapper">
                            <figure className="m-0 p-0">
                              <img src={imageIndex as string} alt="" />
                            </figure>

                            {lookbook.products[currentProductIndex].image.length > 1 && (
                              <>
                                <div className="product-images">
                                  <ul className="product-images-tabs">
                                    <ProductImageTabs
                                      images={lookbook.products[currentProductIndex].image}
                                      setImageIndex={setImageIndex}
                                      imageIndex={imageIndex}
                                    />
                                  </ul>
                                </div>
                              </>
                            )}

                            <div className="control-btns">
                              <div className="control-btn">
                                <button onClick={() => previousProduct(lookbook.products)}>
                                  <Icon icon="fa6-solid:angle-left" className="icon-style" />
                                </button>
                              </div>

                              <div className="control-btn">
                                <button
                                  onClick={() => nextProduct(lookbook.products)}
                                  disabled={
                                    selectedArray.find(
                                      (product: selectedSchema) =>
                                        product.productId === lookbook.products[currentProductIndex].id
                                    )
                                      ? false
                                      : selected?.color === undefined || selected?.size === undefined
                                  }
                                >
                                  <Icon icon="fa6-solid:angle-right" className="icon-style" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="product-details p-5">
                          {/* <div className="wishlist-wrapper d-flex">
                            <button
                              className="btn fav-icon"
                              disabled={wishlistLoading === lookbook.products[currentProductIndex]?.id}
                              onClick={() => toggleWishlist(lookbook.products[currentProductIndex])}
                            >
                              {wishlistLoading === lookbook.products[currentProductIndex]?.id ? (
                                <span className="spinner-border spinner-border-sm" />
                              ) : (
                                <Icon
                                  style={{
                                    fontSize: "14px",
                                    color: "#F76060",
                                    fontWeight: 600,
                                  }}
                                  icon={
                                    lookbook.products[currentProductIndex].inWishList
                                      ? "ri:heart-3-fill"
                                      : "ri:heart-3-line"
                                  }
                                  className={`click text-2xl ${
                                    lookbook.products[currentProductIndex]?.inWishList ? "text-error" : ""
                                  }`}
                                />
                              )}
                            </button>

                            <p className="add-to-wishlist">Add to Wishlist</p>
                          </div> */}

                          <h1 className="product-name mt-5 text-black">
                            {lookbook.products[currentProductIndex].name}
                          </h1>

                          <h3 className="condition text-capitalize mt-5 text-black">
                            condition: {selected?.newPrice ? "new" : "used"}
                          </h3>

                          <div className="condition-btns d-flex gap-5 mt-4">
                            {[
                              {
                                name: "used",
                                id: 1,
                                price: lookbook.products[currentProductIndex].usedPrice,
                              },

                              {
                                name: "new",
                                id: 2,
                                price: lookbook.products[currentProductIndex].newPrice,
                              },
                            ].map((item) => {
                              return (
                                <div
                                  className={`condition-btn rounded overflow-hidden ${
                                    !selected?.newPrice && item.id === 1
                                      ? "active-condition"
                                      : selected?.newPrice && item.id === 2 && "active-condition"
                                  }`}
                                  key={item.id}
                                >
                                  <button
                                    className="w-100 h-100 d-flex flex-column justify-content-center align-items-center bg-transparent"
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        newPrice: item.id === 1 ? false : true,
                                      });
                                    }}
                                  >
                                    <span className="text-black text-capitalize text-black fs-5 mb-1 fw-bold">
                                      {item.name}
                                    </span>
                                    <span className="price-tag">₦ {item.price}</span>
                                  </button>
                                </div>
                              );
                            })}
                          </div>

                          <h3 className="condition text-capitalize mt-5 text-black">color</h3>

                          <div className="colors mt-5 d-flex gap-4">
                            {lookbook.products[currentProductIndex].color.map((item: string, index: number) => {
                              return (
                                <div
                                  className={`color-box ${
                                    (selectedArray.find(
                                      (product: selectedSchema) =>
                                        product.productId === lookbook.products[currentProductIndex].id
                                    )?.color === item ||
                                      selected?.color === item) &&
                                    "active-color"
                                  }`}
                                  key={index}
                                >
                                  <button
                                    className="w-100 h-100"
                                    style={{ backgroundColor: item }}
                                    value={item}
                                    onClick={handleSelectColor}
                                  ></button>
                                </div>
                              );
                            })}
                          </div>

                          <h3 className="condition text-capitalize mt-5 text-black">size</h3>

                          <div className="mt-5 d-flex gap-5 align-items-center sizes">
                            {lookbook.products[currentProductIndex].sizes.map((size: string, index: number) => {
                              return (
                                <div
                                  className={`size-btn rounded overflow-hidden ${
                                    (selectedArray.find(
                                      (product: selectedSchema) =>
                                        product.productId === lookbook.products[currentProductIndex].id
                                    )?.size === size ||
                                      selected?.size === size) &&
                                    "active-size-btn"
                                  }`}
                                  key={index}
                                >
                                  <button
                                    className="w-100 h-100 text-uppercase bg-transparent"
                                    value={size}
                                    onClick={handleSelectSize}
                                  >
                                    {size}
                                  </button>
                                </div>
                              );
                            })}
                          </div>

                          {buyLookbook ? (
                            <div className="mt-5">
                              <Button
                                className="btn btn-black btn-lg w-100"
                                title="Buy lookbook"
                                icon={IconifyIcon}
                                loading={isAddToCart}
                                onClick={() => {
                                  addLookbookToCart(
                                    lookbook.id,
                                    selectedArray.map((selectedProducts: any) => {
                                      return {
                                        quantity: 1,
                                        color: selectedProducts.color,
                                        size: selectedProducts.size,
                                        productId: selectedProducts.productId,
                                        newPrice: selectedProducts.newPrice || false,
                                      };
                                    })
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="mt-5">
                              <Button
                                className="btn btn-black btn-lg w-100"
                                title="Save Product"
                                onClick={() =>
                                  handleSelectedFeatures(
                                    selectedArray,
                                    lookbook.products[currentProductIndex].id,
                                    lookbook.products,
                                    {
                                      color:
                                        selected?.color ||
                                        selectedArray.find((item: any) => item.productId === selected?.productId)
                                          ?.color,
                                      size:
                                        selected?.size ||
                                        selectedArray.find((item: any) => item.productId === selected?.productId)?.size,
                                      newPrice:
                                        selected?.newPrice ||
                                        selectedArray.find((item: any) => item.productId === selected?.productId)
                                          ?.amount === lookbook.products[currentProductIndex].newPrice,
                                      productId: selected?.productId,
                                    }
                                  )
                                }
                                disabled={
                                  selectedArray.find(
                                    (product: selectedSchema) =>
                                      product.productId === lookbook.products[currentProductIndex].id
                                  )
                                    ? false
                                    : selected?.color === undefined || selected?.size === undefined
                                }
                              />
                            </div>
                          )}
                        </div>
                      </section>
                    </section>
                  </section>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};

const ProductImageTabs = ({
  images,
  setImageIndex,
  imageIndex,
}: {
  images: string[];
  setImageIndex: any;
  imageIndex: any;
}) => {
  return (
    <>
      {images
        .filter((imageurl: string) => imageurl !== imageIndex)
        .map((image: string, index: number) => {
          return (
            <li className="image-tab" key={index} onClick={() => setImageIndex(image)}>
              <img src={image} alt="products-tabs" className="w-100 h-100" />
            </li>
          );
        })}
    </>
  );
};

export default LookBookDetails;
