import { gql } from "@apollo/client";

export const contactManagement = gql`
  mutation contactManagement(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $emailAddress: String!
    $message: String!
  ) {
    contactManagement(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      message: $message
    ) {
      status
      message
    }
  }
`;

export const updateShippingRequest = gql`
  mutation updateShippingAddress(
    $state: String
    $firstName: String!
    $lastName: String!
    $zipCode: String!
    $city: String
    $country: String!
    $street: String!
    $phoneNumber: String!
    $email: String!
  ) {
    updateShippingAddress(
      state: $state
      country: $country
      street: $street
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      zipCode: $zipCode
      city: $city
      email: $email
    ) {
      status
      message
    }
  }
`;

export const getShippingAddressRequest = gql`
  query getShippingAddress {
    getShippingAddress {
      status
      message
      data {
        firstName
        lastName
        zipCode
        state
        city
        country
        street
        phoneNumber
        email
      }
    }
  }
`;

export const getSharedCartRequest = gql`
  query getAllSharedCart($limit: Int!, $offset: Int!) {
    getAllSharedCart(limit: $limit, offset: $offset) {
      status
      message
      totalCount
      data {
        id
        sharedWith
        totalAmount
        owner {
          id
          fullName
          emailAddress
          phoneNumber
          profileImage
          createdAt
          lastTransactionDate
          isActive
          isAdmin
          isStaff
        }
        cart {
          itemId
          itemCategory
          product {
            id
            name
            readableProductId
            shortInfo
            fullDetails
            image
            tags
            category
            brand
            usedPrice
            newPrice
            ratings
            sizes
            units
            color
            createdAt
            updatedAt
          }
          lookbook {
            id
            name
            description
            image
            createdAt
          }
          cartId
          details {
            productId
            quantity
            color
            size
            amount
            datetime
          }
        }
      }
    }
  }
`;

export const getReceivedCartRequest = gql`
  query getAllReceivedCart($limit: Int!, $offset: Int!) {
    getAllReceivedCart(limit: $limit, offset: $offset) {
      status
      message
      totalCount
      data {
        id
        sharedCartId
        totalAmount
        recipient {
          id
          fullName
          emailAddress
          phoneNumber
          profileImage
          createdAt
          lastTransactionDate
          isActive
          isAdmin
          isStaff
        }
        sharedBy {
          id
          fullName
          emailAddress
          phoneNumber
          profileImage
          createdAt
          lastTransactionDate
          isActive
          isAdmin
          isStaff
        }
        cart {
          itemId
          itemCategory
          product {
            id
            name
            readableProductId
            shortInfo
            fullDetails
            image
            tags
            category
            brand
            usedPrice
            newPrice
            ratings
            sizes
            units
            color
            createdAt
            updatedAt
          }
          lookbook {
            id
            name
            description
            image
            createdAt
            category {
              id
              name
              description
              image
            }
            tags {
              id
              name
              description
              image
            }
            products {
              id
              name
              readableProductId
              shortInfo
              fullDetails
              image
              tags
              category
              brand
              usedPrice
              newPrice
              ratings
              sizes
              units
              color
              createdAt
              updatedAt
              inWishList
            }
            inWishList
          }
          cartId
          details {
            productId
            quantity
            color
            size
            amount
            datetime
          }
        }
      }
    }
  }
`;

export const getOrdersRequest = gql`
  query getAllOrders($limit: Int!, $offset: Int!) {
    getAllOrders(limit: $limit, offset: $offset) {
      status
      message
      totalCount
      data {
        id
        customerId
        customerEmail
        deliveryStatus
        shippingFee
        paymentStatus
        createdAt
        shippingAddress {
          firstName
          lastName
          zipCode
          state
          city
          country
          street
          phoneNumber
          email
        }
        vat
        deliveryDate
        paymentId
        totalAmount
        items {
          cartId
          quantity
          color
          size
          amount
          datetime
          product {
            id
            name
            readableProductId
            shortInfo
            fullDetails
            image
            tags
            category
            brand
            usedPrice
            newPrice
            ratings
            sizes
            units
            color
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const updatePasswordRequest = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`;
