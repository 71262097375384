import React from "react";
import Form from "react-bootstrap/Form";

const FloatingInput = ({
  className,
  field,
  form: { touched, errors },
  variant,
  appendedComponent: AppendedComponent,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  return (
    <div className="mb-3">
      <Form.Floating>
        <Form.Control
          id="floatingInputCustom"
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          className={`${className || "form-control"}`}
          placeholder="Description"
          {...props}
        />
        <label htmlFor="floatingInputCustom" className="fw-medium text-xs">
          {props.label}
        </label>
      </Form.Floating>

      {hasError && (
        <div className="text-danger text-xs font-weight-normal pt-1">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default FloatingInput;
