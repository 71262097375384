import React from "react";
import { useShareCart } from "hooks/useShareCart";
import useNetworkData from "hooks/useNetworkData";
import { receivedCart, sharedCart } from "services/network/_schema";
import Button from "sharedComponents/forms/Button";
import { Icon } from "@iconify/react";

export const SharedCartDetails = ({ item, received }: { item: sharedCart; received: boolean | undefined }) => {
  const { mutateData } = useNetworkData();
  const { addingEmail, emailAddresses, setEmailAddresses, shareCart, cloneCart, cloning } = useShareCart(mutateData);

  return (
    <div className="row py-6 border-bottom">
      <div className="col-lg-4 col-md-6 col-sm-10">
        {received ? (
          <>
            <div className="d-flex gap-3">
              <p className="fw-bold text-lg text-black-500">Shared by:</p>
              <p className="text-lg text-black-500 fw-semi-bold text-primary-500">
                {(item as any as receivedCart).sharedBy?.fullName}
              </p>
            </div>
            <div className="d-flex gap-3">
              <p className="fw-bold text-lg text-black-500">Total Amount:</p>
              <p className="text-lg text-black-500 fw-semi-bold text-primary-500">
                ${(item as any as receivedCart).totalAmount.toLocaleString()}
              </p>
            </div>
            <Button
              className="btn btn-sm btn-primary-500 px-5 my-4"
              title="Clone Cart"
              loading={cloning}
              disabled={cloning}
              loadingTitle={"Cloning..."}
              onClick={() => cloneCart((item as any as receivedCart).sharedCartId)}
            />
          </>
        ) : (
          <>
            <p className="fw-bold text-lg text-black-500">Friends shared with ({item.sharedWith.length})</p>
            {item.sharedWith.map((email, index) => (
              <div className="d-flex justify-content-between gap-3" key={email}>
                <p className="text-sm fw-medium text-nowrap">Contact {index + 1}:</p>

                <p className="text-sm fw-semi-bold text-black-500 ms-auto">{email}</p>
                <p>
                  <Icon icon="fluent:delete-24-regular" fontSize={"13"} />
                </p>
              </div>
            ))}
          </>
        )}
      </div>
      {!received && (
        <div className="col-lg-6 col-md-6 col-sm-12 ms-auto">
          <p className="fw-bold text-lg text-black-500">Share cart with friend(s)</p>
          <input
            type="email"
            className="form-control"
            id="emailAddresses"
            name="emailAddresses"
            value={emailAddresses}
            placeholder="Email address (s)"
            onChange={({ target: { value } }) => {
              setEmailAddresses(value);
            }}
          />
          <p className="text-xs fw-medium text-purple">
            Separate each address with a comma (,). E.G: Johndoe@gmail.com, Jane@gmail.com
          </p>
          <Button
            className="btn btn-black btn-lg px-9"
            title="Share"
            loading={addingEmail}
            disabled={addingEmail || !emailAddresses}
            loadingTitle={"Sharing..."}
            onClick={() => shareCart(item.id)}
          />
        </div>
      )}
    </div>
  );
};
