import { Header } from "sharedComponents/header";
import { LookbookGrid } from "sharedComponents/lookbookGrid";

export const LookBookPage = () => {
  return (
    <>
      <Header
        title="Lookbooks"
        subTitle="Complete your autumn wardrobe with trendy pieces"
      />
      <section className="container ">
        <LookbookGrid sectionClassNames={"mb-9"} hideFilterAndSort={false} />
      </section>
    </>
  );
};
