import useNetworkData from "hooks/useNetworkData";
import React, { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { getOrdersRequest } from "services/network/account";
import { ItemWithDetail } from "sharedComponents/itemWithDetail";
import Loader from "sharedComponents/loader";
import { Paginator } from "sharedComponents/paginator";

export const AccountOrders = ({
  setOrderCount,
}: {
  setOrderCount: (value: string) => void;
}) => {
  const { getData } = useNetworkData();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagingInfo, setPagingInfo] = useState({
    limit: 6,
    offset: 0,
    total: 0,
    current: 0,
  });

  const getOrders = useCallback(async (offset: number) => {
    setLoading(true);
    const res = await getData({
      requestName: "getAllOrders",
      payload: { ...pagingInfo, offset },
      apiRequest: getOrdersRequest,
    });
    setLoading(false);
    setOrderCount(res.totalCount > 10 ? "10+" : res.totalCount);
    if (res) {
      setItems(res.data);
      setPagingInfo((prev) => ({ ...prev, total: res.totalCount, offset }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrders(0);
  }, [getOrders]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * pagingInfo.limit) % pagingInfo.total;
    setPagingInfo((prev) => ({
      ...prev,
      offset: newOffset,
      current: event.selected,
    }));
    getOrders(newOffset);
  };

  return (
    <>
      {loading && items.length === 0 ? (
        <Loader title="Getting details..." />
      ) : (
        <>
          {items.length > 0 ? (
            <>
              <Accordion flush className="container mt-6">
                {items.map((item, index) => (
                  <ItemWithDetail key={index} item={item} />
                ))}
              </Accordion>
              {pagingInfo.total > 10 && (
                <div className="mt-7 d-flex justify-content-end">
                  <Paginator
                    pageCount={Math.ceil(pagingInfo.total / pagingInfo.limit)}
                    handlePageClick={handlePageClick}
                    forcePage={pagingInfo.current}
                  />
                </div>
              )}
            </>
          ) : (
            <p className="my-9 text-xl text-center">
              You have no current order
            </p>
          )}
        </>
      )}
    </>
  );
};
