import { Header } from "sharedComponents/header";
import { SuggestedItems } from "sharedComponents/suggestedItems";
import React from "react";
import "./styles.scss";
import contact from "assets/images/contact one.svg";
import email from "assets/images/email.svg";
import office from "assets/images/office.svg";
import phone from "assets/images/phone.svg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import useNetworkData from "hooks/useNetworkData";
import { contactManagement } from "services/network/account";
import FloatingInput from "sharedComponents/forms/FloatingInput";
import { toast } from "react-toastify";

type formValuesType = {
  emailAddress: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  message: string;
}

export const ContactPage = () => {
  const { isMutating, mutateData } = useNetworkData();

  const initialValues = {
    emailAddress: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .label("Email Address")
      .email("Invalid email")
      .required(),
    firstName: yup
      .string()
      .label("First Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    lastName: yup
      .string()
      .label("Last Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    phoneNumber: yup
      .string()
      .label("Phone Number")
      .matches(/^[0-9-() +]+$/, "Enter a valid number").test(
      "phone-length",
      "Phone number must be between 7 and 15 digits",
      (value) => {
        if (!value) {
          return false; 
        }
        const phoneLength = value.replace(/[^0-9]/g, "").length;
        return phoneLength >= 7 && phoneLength <= 15;
      }
    )
      .required(),
    message: yup.string().label("Message").required(),
  });

  const handleContact = async (formValues: formValuesType, formikHelper: any) => {
    try {
        const res: any = await mutateData({
      requestName: "contactManagement",
      apiRequest: contactManagement,
      payload: formValues,
    });
   
    if (res.status === "success") {
      toast.success(res.message);
      formikHelper.resetForm();
    } else {
      toast.error(res.message);
    }
    } catch (err) {
      toast.error(`${err}`)
    }
  
  };
  return (
    <>
      <Header title="Contact" />
      <section className="container contact-wrapper">
        <section className="row my-9">
          <div className="col-lg-6 col-md-10 col-12 my-auto order-2 order-lg-1 px-lg-5">
            <p className="xsub-title">Contact us</p>
            <p className="fw-medium text-xl mb-4">
              Our friendly team would love to hear from you.
            </p>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleContact}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <Form onSubmit={handleSubmit} className="form-wrapper">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="First name"
                          name="firstName"
                          id="firstName"
                        />
                      </div>

                      <div className="col-md-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Last Name"
                          name="lastName"
                          id="lastName"
                        />
                      </div>
                      <div className="col-md-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          type="tel"
                        />
                      </div>
                      <div className="col-md-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Email Address"
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          placeholder="Email address"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          as="textarea"
                          name="message"
                          id="message"
                          cols={30}
                          rows={6}
                          className="fw-medium text-grey-300 text-sm form-control mb-5"
                          placeholder="Enter message..."
                        />
                      </div>
                      <div className="col-lg-8 col-md-9 col-12">
                        <Button
                          className="btn btn-black btn-lg w-100"
                          title="Submit"
                          loading={isMutating}
                          disabled={isMutating || !isValid}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="col-lg-6 col-md-10 col-12 order-1 order-lg-2 mb-lg-0 mb-5">
            <img src={contact} alt="" className="contact-img" />
          </div>
        </section>

        <section className="row my-9">
          <div className="col-lg-4 col-md-6 col-12 text-center mb-5">
            <img src={email} alt="" className="mb-4" />
            <p className="text-xl fw-semi-bold text-black-500">Email</p>
            <p className="fw-medium text-sm text-grey-300">
              Our friendly team is here to help.
            </p>
            <p className="text-primary-500 fw-semi-bold text-lg">
              hi@otitola.com
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 text-center mb-5">
            <img src={office} alt="" className="mb-4" />
            <p className="text-xl fw-semi-bold text-black-500">Office</p>
            <p className="fw-medium text-sm text-grey-300">
              Come say hello at our office HQ.
            </p>
            <p className="text-primary-500 fw-semi-bold text-lg">
              100 Pako Street <br />
              Yaba, Lagos
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 text-center mb-5">
            <img src={phone} alt="" className="mb-4" />
            <p className="text-xl fw-semi-bold text-black-500">Phone</p>
            <p className="fw-medium text-sm text-grey-300">
              Mon-Fri from 8am to 5pm.
            </p>
            <p className="text-primary-500 fw-semi-bold text-lg">
              +234 909 487 8471
            </p>
          </div>
        </section>
        <section className="mb-9">
          <SuggestedItems title="Recently viewed" />
        </section>
      </section>
    </>
  );
};
