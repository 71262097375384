import React from "react";
import "./styles.scss";

export const Header = ({
  title,
  subTitle,
  progress,
  loading,
}: {
  title: string;
  subTitle?: string;
  progress?: number;
  loading?: boolean;
}) => {
  const percentage = ((progress || 0) / 5) * 100;
  return (
    <section className="container blk-banner bg-black-500">
      {!loading && (
        <div>
          <h2 className="sub-title text-white fw-bold mb-2">{title}</h2>
          {subTitle && (
            <p className="text-white text-lg fw-medium mt-4">{subTitle}</p>
          )}
        </div>
      )}
      {progress && (
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-valuenow={percentage}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      )}
    </section>
  );
};
