import { CartSummary } from "sharedComponents/cartSummary";
import { Header } from "sharedComponents/header";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "context/appContext";
import { shippingSchema } from "services/network/_schema";
import { createPaymentRequest } from "services/network/products";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { saveStorageData, StorageKeys } from "lib/storageManager";
import { useNavigate } from "react-router-dom";
import { CheckoutForm } from "./checkoutForm";
import Loader from "sharedComponents/loader";

export const BillingPage = () => {
  const {
    vat,
    totalPrice,
    shipping,
    mutateData,
  }: {
    vat: number;
    totalPrice: number;
    shipping: shippingSchema;
    totalCount: number;
    mutateData: any;
  } = useContext(AppContext);
  const navigate = useNavigate();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
  const [options, setOptions] = useState<{ clientSecret: string }>();

  const createPaymentIntent = useCallback(async () => {
    if (totalPrice === 0) {
      navigate("/orders");
    } else {
      const res: any = await mutateData({
        requestName: "createPaymentIntent",
        apiRequest: createPaymentRequest,
        payload: {
          totalPrice,
        },
      });

      if (res.status === "success") {
        setOptions({ clientSecret: res.clientSecret });
        saveStorageData(StorageKeys.paymentDetail, {
          vat,
          shippingFee: shipping.fee,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    createPaymentIntent();
  }, [createPaymentIntent]);

  return (
    <>
      <Header
        title="Payment and confirmation"
        subTitle="Complete your autumn wardrobe with trendy pieces"
        progress={4}
      />
      <section className="container my-6">
        <div className="row g-5">
          <div className="col-lg-6 col-md-10 col-sm-12 order-2 order-lg-1">
            <div className="row mb-5">
              <div className="col-6">
                <p className="text-sm fw-medium">Customer information</p>
                <p className="fw-semi-bold text-black-500 text-base">
                  {shipping?.firstName} {shipping?.lastName}
                </p>
                <p className="fw-semi-bold text-black-500 text-base">{shipping?.city}</p>
                <p className="fw-semi-bold text-black-500 text-base">{shipping?.phoneNumber}</p>
                <p className="text-sm pt-3 fw-medium">Total amount to pay</p>
                <p className="fw-semi-bold text-black-500 text-base">${totalPrice ? totalPrice.toLocaleString() : 0}</p>
              </div>
              <div className="col-6">
                <p className="text-sm fw-medium">Ship to</p>
                <p className="fw-semi-bold text-black-500 text-base">{shipping?.street}</p>
                <p className="fw-semi-bold text-black-500 text-base">{shipping?.phoneNumber}</p>
                <p className="fw-semi-bold text-black-500 text-base">{shipping?.email}</p>

                <p className="fw-semi-bold text-black-500 text-base">
                  {shipping?.state}, {shipping?.country}
                </p>
              </div>
            </div>
            <hr />

            <p className="fw-semi-bold text-black-500 text-lg mt-5">Payment</p>
            <p className="text-sm fw-medium">All transactions are secured and encrypted</p>
            {options?.clientSecret && stripePromise ? (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm options={options} shipping={shipping} totalPrice={totalPrice} />
              </Elements>
            ) : (
              <Loader title="Please wait..." />
            )}
          </div>
          <div className="col-lg-6 col-md-10 col-sm-12 order-1 order-lg-2 ms-lg-auto mb-5">
            <CartSummary />
          </div>
        </div>
      </section>
    </>
  );
};
