import { Header } from "sharedComponents/header";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export const PaymentFailed = () => {
  return (
    <>
      <Header title="Failed payment" />
      <section className="container my-6">
        <div className="row success-wrapper">
          <div className="col-lg-6 col-md-9 col-sm-11 mx-auto text-center">
            <Icon
              icon="material-symbols:cancel-rounded"
              className="mb-5 text-error"
              style={{ fontSize: "9rem" }}
            />
            <p className="text-lg text-black-500 fw-semi-bold">Oops...</p>
            <p className="text-sm fw-medium">
              Sorry, an error occured while trying to make payment.
            </p>
            <div className="col-8 mx-auto my-5">
              <Link to={"/shop"} className="text-decoration-none">
                <button className="btn btn-black btn-lg w-100">
                  Back to store
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
