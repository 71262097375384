import { Header } from "sharedComponents/header";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CartSummary } from "sharedComponents/cartSummary";
import { Link, useNavigate } from "react-router-dom";
import { getShippingAddressRequest, updateShippingRequest } from "services/network/account";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import FloatingInput from "sharedComponents/forms/FloatingInput";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { AppContext } from "context/appContext";
import { convertArrayToSelectOptions } from "utilities";
import ThemedSelect from "sharedComponents/forms/ThemedSelect";
import countries from "utilities/countries+states+cities.json";
import { shippingSchema } from "services/network/_schema";
import ShippingFeeJson from "utilities/shippingFees.json";

type countryType = {
  id: number;
  name: string;
  states: {
    id: number;
    name: string;
    cities: Array<{
      id: number;
      name: string;
    }>;
  }[];
};
const countryList = (countries as countryType[]).map((val) => val.name);

export const CustomerInfoPage = () => {
  const user = getStorageData(StorageKeys.user);
  const navigate = useNavigate();
  const { setShipping, getData, isLoading, mutateData, isMutating }: any = useContext(AppContext);
  const [cityList, setCityList] = useState<string[]>([]);
  const [stateList, setStateList] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState(false);

  const [initialValues, setInitialValues] = useState<any>({
    email: user?.emailAddress || "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    state: "",
    city: "",
    country: "",
    street: "",
    zipCode: "",
  });

  const validationSchema = yup.object().shape({
    email: yup.string().label("Email Address").email("Invalid email").required(),

    firstName: yup
      .string()
      .label("First Name")
      .min(2, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    lastName: yup
      .string()
      .label("Last Name")
      .min(2, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),

    phoneNumber: yup
      .string()
      .label("Phone Number")
      .test("phone-length", "Phone number must be between 7 and 15 digits", (value) => {
        if (!value) {
          return false;
        }
        const phoneLength = value.replace(/[^0-9]/g, "").length;
        return phoneLength >= 7 && phoneLength <= 15;
      })
      .matches(/^[0-9-() +]+$/, "Enter a valid number")
      .required(),
    state: isRequired ? yup.object().label("State").required() : yup.object().label("State"),
    city: isRequired ? yup.object().label("City").required() : yup.object().label("City"),
    country: yup.object().label("Country").required(),
    street: yup.string().label("Full Address").required(),
    zipCode: yup.string().label("Zip Code").required(),
  });

  const getDetails = useCallback(async () => {
    const res = await getData({
      requestName: "getShippingAddress",
      apiRequest: getShippingAddressRequest,
    });

    if (res) {
      const { data }: { data: shippingSchema } = res;

      if (data.country) {
        const country = (countries as countryType[]).find((val) => val.name === data.country);
        setStateList(country?.states.map((val) => val.name) || []);
        const states = country?.states.find((val) => val.name === data.state);
        setCityList(states?.cities.map((val) => val.name) || []);
      }

      if (data.state) {
        const stateToBeDelivered = ShippingFeeJson.find((state) => state.name === data.state);
        // const fee = (stateToBeDelivered?.cities as any)[data.city] ?? 1200;
        let fee: number;

        if (stateToBeDelivered) {
          fee = (stateToBeDelivered?.cities as any)[data.city] ?? 1200;
        } else {
          fee = 1500;
        }

        setShipping((prev: any) => ({
          ...prev,
          fee: fee,
        }));
      }
      setInitialValues({
        ...res.data,
        email: user.emailAddress,
        lastName: res.data.lastName || (user ? user.fullName.split(" ")[1] : ""),
        firstName: res.data.firstName || (user ? user.fullName.split(" ")[0] : ""),
        street: res.data.street || "",
        zipCode: res.data.zipCode || "",
        country: res.data.country
          ? {
              value: res.data.country,
              label: res.data.country,
            }
          : "",
        state: res.data.state
          ? {
              value: res.data.state,
              label: res.data.state,
            }
          : "",
        city: res.data.city
          ? {
              value: res.data.city,
              label: res.data.city,
            }
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, setShipping]);

  useEffect(() => {
    if (user) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetails]);

  const handleContinue = async (formValues: any) => {
    const payload: any = {
      ...formValues,
      country: formValues.country.value,
      state: formValues.state.value || "",
      city: formValues.city.value || "",
    };

    const res: any = await mutateData({
      requestName: "updateShippingAddress",
      apiRequest: updateShippingRequest,
      payload,
    });
    if (res.status === "success") {
      const stateToBeDelivered: any = ShippingFeeJson.find((state) => state.name === payload.state);
      setShipping({
        ...payload,
        fee: stateToBeDelivered?.cities[payload.city] ?? 1200,
      });

      navigate("/orders/delivery");
    }
  };

  return (
    <>
      <Header title="Customer Information" subTitle="Complete your autumn wardrobe with trendy pieces" progress={2} />
      <section className="container my-6">
        <div className="row g-5">
          <div className="col-lg-5 col-md-10 col-sm-12 order-2 order-lg-1">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleContinue}
            >
              {({ handleSubmit, isValid, setFieldValue, values }) => {
                return (
                  <Form onSubmit={handleSubmit} className="form-wrapper">
                    {/* <div className="d-flex justify-content-between fw-semi-bold">
                      <p className="text-xs">
                        Returning customer?{" "}
                        <Link to="/login" className="text-primary-500 text-decoration-none">
                          Log in
                        </Link>
                      </p>
                      <p className="text-xs">
                        Don’t have an account?{" "}
                        <Link to="/register" className="text-primary-500 text-decoration-none">
                          Sign up
                        </Link>
                      </p>
                    </div> */}
                    <p className="fw-medium text-black-500 text-xl">Shipping address</p>
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-4">
                        <Field component={FloatingInput} label="First name" name="firstName" id="firstName" />
                      </div>
                      <div className="col-sm-6 col-12 mb-4">
                        <Field component={FloatingInput} label="Last Name" name="lastName" id="lastName" />
                      </div>
                      <div className="col-sm-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          type="tel"
                        />
                      </div>
                      <div className="col-sm-6 col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Email Address"
                          name="email"
                          id="email"
                          type="email"
                          readOnly={user ? true : false}
                          placeholder="Email address"
                        />
                      </div>

                      <div className="col-12 mb-4">
                        <Field
                          component={FloatingInput}
                          label="Address"
                          name="street"
                          id="street"
                          placeholder="Street"
                        />
                      </div>

                      <div className="col-sm-6 col-12 mb-4">
                        <Field
                          component={ThemedSelect}
                          size="base"
                          name="country"
                          id="country"
                          placeholder="Select Country*"
                          className="react-select"
                          options={convertArrayToSelectOptions(countryList)}
                          onChange={(value: { value: string; label: string }) => {
                            setFieldValue("city", "");
                            setFieldValue("state", "");
                            let countryDetails = (countries as countryType[]).find((val) => val.name === value.value);

                            if ((countryDetails as countryType)?.states.length > 0) {
                              setStateList((countryDetails as countryType)?.states.map((val) => val.name));
                              setIsRequired(true);
                            } else {
                              setStateList([]);
                              setCityList([]);
                              setIsRequired(false);
                            }

                            // setStateList(
                            //   (countries as countryType[])
                            //     .find((val) => val.name === value.value)
                            //     ?.states.map((val) => val.name) || []
                            // );
                            setFieldValue("country", value);
                          }}
                        />
                      </div>
                      <div className="col-6 mb-4">
                        <Field
                          component={ThemedSelect}
                          size="base"
                          name="state"
                          id="state"
                          placeholder="Select State*"
                          className="react-select"
                          options={convertArrayToSelectOptions(stateList)}
                          onChange={(value: { value: string; label: string }) => {
                            const country = (countries as countryType[]).find(
                              (val) => val.name === values.country.value
                            );

                            const statesInfo = country?.states.find((val) => val.name === value.value);

                            if ((statesInfo as any)?.cities.length < 1) {
                              setIsRequired(false);
                            } else {
                              setIsRequired(true);
                            }

                            setCityList(statesInfo?.cities.map((val) => val.name) || []);
                            setFieldValue("city", "");
                            setFieldValue("state", value);
                          }}
                        />
                      </div>

                      <div className="col-12 mb-4">
                        <Field
                          component={ThemedSelect}
                          size="base"
                          name="city"
                          id="city"
                          placeholder="Select City"
                          className="react-select"
                          options={convertArrayToSelectOptions(cityList)}
                          onChange={(value: { value: string }) => {
                            setFieldValue("city", value);
                            let shippingLocation = ShippingFeeJson.find((state) => state.name === values.state.value);

                            let fee: number;

                            if (shippingLocation) {
                              fee = (shippingLocation?.cities as any)[value.value] ?? 1200;
                            } else {
                              fee = 1500;
                            }
                            setShipping((prev: any) => ({
                              ...prev,
                              fee: fee,
                            }));
                          }}
                        />
                        {cityList.length === 0 && (
                          <div className="text-xs ">
                            <b>NB:</b> Select a state to select a LGA under it.
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <Field
                          component={FloatingInput}
                          label="Zip Code"
                          name="zipCode"
                          id="zipCode"
                          placeholder="Zip Code"
                        />
                      </div>
                      <div className="col-12 text-sm text-black-500 fw-medium mb-3 form-check">
                        <Field className="form-check-input" type="checkbox" name="specialOffer" id="specialOffer" />
                        <label className="form-check-label click" htmlFor="specialOffer">
                          Send me special offers through mail
                        </label>
                      </div>
                      <div className="col-12 text-sm text-black-500 fw-medium mb-3 form-check">
                        <Field className="form-check-input" type="checkbox" name="saveInfo" id="saveInfo" />
                        <label className="form-check-label click" htmlFor="saveInfo">
                          Save this information for next time
                        </label>
                      </div>
                      <div className="col-md-9 col-12 mt-5">
                        <Button
                          type="submit"
                          className="btn btn-black btn-lg w-100"
                          title={user ? "Continue" : "Continue as guest"}
                          disabled={!isValid || isMutating || isLoading}
                          loading={isMutating}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-12 order-1 order-lg-2 ms-lg-auto mb-5">
            <CartSummary />
          </div>
        </div>
      </section>
    </>
  );
};
