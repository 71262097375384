import { Header } from "sharedComponents/header";
import React from "react";
import success from "assets/images/Success-Error-Icons.svg";
import { Link } from "react-router-dom";

export const SuccessPage = () => {
  return (
    <>
      <Header title="Order Successful" subTitle="Complete your autumn wardrobe with trendy pieces" progress={5} />
      <section className="container my-6">
        <div className="row success-wrapper">
          <div className="col-lg-6 col-md-9 col-sm-11 mx-auto text-center">
            <img src={success} alt="" className="mb-5" />
            <p className="text-lg text-black-500 fw-semi-bold">Thanks for shopping with Otitolab</p>
            <p className="text-sm fw-medium">
              Your <span className="text-primary-500 click text-decoration-underline">#214243 Order 1</span> has been
              placed. You will find all your order details, and we’ll send you a shipping confirmation email as soon as
              order ships. In the mean time, you can pick other goodies from our store or check your order details in
              your{" "}
              <Link to={"/account"} className="text-primary-500 click text-decoration-underline">
                account
              </Link>
              .
            </p>
            <div className="col-8 mx-auto my-5">
              <Link to={"/shop"} className="text-decoration-none">
                <button className="btn btn-black btn-lg w-100">Back to store</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
