import React, { useEffect } from "react";
import "./styles.scss";
import { ItemCard } from "../ItemCard";
import Slider from "react-slick";
import { SliderNextArrow, SliderPrevArrow } from "../customSliderArrows";
import { GetNewArrivalRequest } from "services/network/products";
import useNetworkData from "hooks/useNetworkData";
import { BoxLoading } from "sharedComponents/loader";
import { productsSchema } from "services/network/_schema";
import { Link } from "react-router-dom";

const responsive = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 780,
    settings: {
      slidesToShow: 1,
      centerMode: true,
    },
  },
];

export const NewArrival = () => {
  const { getData, isLoading, response } = useNetworkData();

  useEffect(() => {
    getData({
      requestName: "getNewProductArrival",
      apiRequest: GetNewArrivalRequest,
    });
  }, [getData]);

  return (
    <section className="container">
      {isLoading ? (
        <div className="row" id="new-arrival-wrapper">
          {[...Array(4)].map((item, index) => (
            <div className="col-lg-3 col-12" key={index}>
              <BoxLoading />
            </div>
          ))}
        </div>
      ) : (
        <>
          {response && response.data.length > 0 && (
            <div id="new-arrival-wrapper" className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <h2 className="sub-title">New Arrival</h2>
                <p className="text-lg">
                  Complete your autumn wardrobe with trendy pieces
                </p>
                <Link to={"/shop"} className="text-decoration-none">
                  <button className="btn btn-black btn-lg w-100">
                    Get it now
                  </button>
                </Link>
              </div>
              <div className="col-lg-9 col-12">
                {response.data.length > 1 ? (
                  <Slider
                    speed={1000}
                    // infinite={true}
                    // centerMode={true}
                    infinite={false}
                    centerMode={false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    responsive={responsive}
                    className="general-carousel new-arrival-carousel mt-md-0 mt-5"
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                  >
                    {response.data.map((item: productsSchema) => (
                      <div key={item.id}>
                        <ItemCard item={item} newItem={true} />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="row">
                    <div className="col-lg-5 col-12">
                      <ItemCard item={response.data[0]} newItem={true} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};
