import { Icon } from "@iconify/react";
import React from "react";
import ReactPaginate from "react-paginate";
import "./styles.scss";

export const Paginator = ({
  handlePageClick,
  pageCount,
  pageRangeDisplayed,
  forcePage,
  ...props
}: {
  handlePageClick: (selectedItem: { selected: number }) => void;
  pageCount: number;
  forcePage: number;
  pageRangeDisplayed?: number;
}) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<Icon icon="akar-icons:arrow-right" />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel={<Icon icon="akar-icons:arrow-left" />}
      renderOnZeroPageCount={undefined}
      forcePage={forcePage}
      {...props}
      className="paginator-wrapper"
    />
  );
};
