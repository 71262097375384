import useNetworkData from "hooks/useNetworkData";
import { ChangeEvent, useState, useCallback, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { CustomDropdwonItem } from "sharedComponents/CustomDropdwonItem";
import { CategorySchema, productsSchema, TagsSchema } from "services/network/_schema";
import { GetAllCategoriesRequest, GetAllTagsRequest, GetProductsRequest } from "services/network/products";
import { filterSortProps } from "services/network/_schema";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { sortvalues } from "utilities";

const FilterSortLookbook = ({
  selectedFilter,
  updateFilterList,
  setSortValues,
  sortValues,
  updateTagsList,
  selectedTags,
  productsFilterValue,
  updateProductsFilter,
  totalCount,
  hideFilter,
}: filterSortProps) => {
  const location = useLocation();
  const { getData } = useNetworkData();

  const [categoryList, setCategoryList] = useState<CategorySchema[]>([]);
  const [tagsList, setTagsList] = useState<TagsSchema[]>([]);
  const [products, setProducts] = useState<productsSchema[]>([]);

  const handleSortChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSortValues((prev: any) => [e.target.value]);
    } else {
      setSortValues(sortValues?.filter((item: any) => item !== e.target.value));
    }
  };

  const getCats = useCallback(async () => {
    const res = await getData({
      requestName: "getAllCategories",
      payload: {
        limit: 0,
        offset: 0,
      },
      apiRequest: GetAllCategoriesRequest,
    });
    if (res && res.data.length > 0) {
      setCategoryList(res.data);
    }
  }, [getData]);

  const getTags = useCallback(async () => {
    const res = await getData({
      requestName: "getAllTags",
      payload: {
        limit: 0,
        offset: 0,
      },
      apiRequest: GetAllTagsRequest,
    });

    if (res && res.data.length > 0) {
      setTagsList(res.data);
    }
  }, [getData]);

  const getProducts = useCallback(async () => {
    const res = await getData({
      requestName: "getAllProducts",
      payload: {
        limit: 0,
        offset: 0,
      },
      apiRequest: GetProductsRequest,
    });

    if (res && res.data.length > 0) {
      setProducts(res.data);
    }
  }, [getData]);
  useEffect(() => {
    getCats();
    getTags();
    getProducts();
  }, [getCats, getTags, getProducts]);

  return (
    <section
      className={`d-flex ${
        hideFilter ? "justify-content-between" : "justify-content-end"
      } mb-6 filter-container-lookbook`}
    >
      {hideFilter && (
        <div className="filter-dropdown w-50">
          <Dropdown className="d-inline filters-card" autoClose="outside" drop="down">
            <Dropdown.Toggle id="dropdown-toggle">
              <span className="text-sm">Filter</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="fw-medium wide-dropdown-filter">
              <section className="row-custom gap-8">
                <div className="">
                  <Dropdown.ItemText>
                    <span className="text-black-500 text-lg fw-semi-bold text-capitalize">categories</span>
                  </Dropdown.ItemText>
                  {categoryList.map((category) => {
                    const { id, name } = category;
                    return (
                      <Dropdown.Item className="click" key={id} as={CustomDropdwonItem}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={id}
                            id={id}
                            onChange={updateFilterList ? () => updateFilterList(id) : void 0}
                            checked={selectedFilter?.includes(id)}
                          />
                          <label className="form-check-label text-capitalize text-sm" htmlFor={id}>
                            {name}
                          </label>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>

                <div className="">
                  <Dropdown.ItemText>
                    <span className="text-black-500 text-lg fw-semi-bold text-capitalize">Tags</span>
                  </Dropdown.ItemText>
                  {tagsList.map((tags: TagsSchema) => {
                    const { id, name } = tags;
                    return (
                      <Dropdown.Item className="click" key={id} as={CustomDropdwonItem}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={id}
                            id={id}
                            onChange={updateTagsList ? () => updateTagsList(id) : void 0}
                            checked={selectedTags?.includes(id)}
                          />
                          <label className="form-check-label text-capitalize text-sm" htmlFor={id}>
                            {name}
                          </label>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>

                <div className="">
                  <Dropdown.ItemText>
                    <span className="text-black-500 text-lg fw-semi-bold text-capitalize">Products</span>
                  </Dropdown.ItemText>
                  {products.map((products: productsSchema) => {
                    const { id, name } = products;
                    return (
                      <Dropdown.Item className="click" key={id} as={CustomDropdwonItem}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={id}
                            id={id}
                            onChange={updateProductsFilter ? () => updateProductsFilter(id) : void 0}
                            checked={productsFilterValue?.includes(id)}
                          />
                          <label className="form-check-label text-capitalize text-sm" htmlFor={id}>
                            {name}
                          </label>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </section>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div
        className={`sort-dropdown  d-flex justify-content-end ${
          location.pathname.includes("brands") ? "w-100" : "w-50"
        }`}
      >
        <Dropdown
          className={`d-inline filters-card ${location.pathname.includes("brands") && "w-100"}`}
          autoClose="outside"
          drop="down"
          style={{ border: "none", margin: 0 }}
        >
          {location.pathname.includes("brands") ? (
            <div className="d-flex align-brands">
              <p className="mb-0 me-3 text-sm fw-semi-bold">
                {totalCount}
                {totalCount === 1 ? " Item" : " Items"}
              </p>

              <Dropdown.Toggle id="dropdown-toggle">
                <span className="text-sm">Sort by</span> <span className="mx-1">:</span>
                {sortValues?.map((item: any, index: any) => {
                  return (
                    <span className="text-primary-500 text-sm text-capitalize" key={index}>
                      {item} {sortValues?.length > 1 && ","}
                    </span>
                  );
                })}
              </Dropdown.Toggle>
            </div>
          ) : (
            <Dropdown.Toggle id="dropdown-toggle">
              <p className="mb-0 me-3 text-sm fw-semi-bold">
                {totalCount}
                {totalCount === 1 ? " Item" : " Items"}
              </p>
              <span className="text-sm">Sort by</span> <span className="mx-1">:</span>
              {sortValues?.map((item: any, index: any) => {
                return (
                  <span className="text-primary-500 text-sm text-capitalize" key={index}>
                    {item} {sortValues?.length > 1 && ","}
                  </span>
                );
              })}
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu className="fw-medium wide-filter-dropdown sort-dropdown-menu">
            <Dropdown.ItemText className="border-bottom mb-2">
              <span className="text-black-500 text-lg fw-semi-bold">Sort by</span>
            </Dropdown.ItemText>
            {sortvalues?.map((sortItem: any, index: any) => {
              return (
                <Dropdown.Item key={index} as={CustomDropdwonItem}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sort-values"
                      value={sortItem}
                      id={sortItem}
                      onChange={handleSortChange}
                      // checked={sortValues.includes(sortItem)}
                    />
                    <label className="form-check-label text-capitalize text-sm" htmlFor={sortItem}>
                      {sortItem}
                    </label>
                  </div>
                </Dropdown.Item>
              );
            })}
            <div className="my-2"></div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </section>
  );
};

export default FilterSortLookbook;
