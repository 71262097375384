import { useLocation } from "react-router-dom";

export const convertArrayToSelectOptions = (values) => {
  return values.map((value) => ({ label: value, value }));
};

export const convertModelArrayToSelectOptions = (items, value, label, includeOriginal) => {
  return items.map((item) => ({
    ...(includeOriginal ? { ...item } : {}),
    value: item[value] || "",
    label: item[label] || "",
  }));
};

export const getOneLinerErrorMessage = (payload) => {
  if (!payload || !payload.errors || payload.errors?.length !== 1 || typeof payload.errors[0].detail !== "string") {
    return null;
  }

  return payload.errors[0].detail;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// This sizes should be same with the that on the admin's app, if you update this update the admin's as well.
export const availableSizes = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];

// This colors should be same with the that on the admin's app, if you update this update the admin's as well.
export const availableColors = [
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "purple",
  "white",
  "cyan",
  "brown",
  "black",
  "beige",
  "silver",
  "pink",
  "grey",
  "gold",
  "multi",
];

export const sortvalues = ["latest", "new", "used"];
