import React, { useCallback, useEffect, useState } from "react";
import { lookbookSchema } from "services/network/_schema";
import { GetLookbookRequest } from "services/network/lookbooks";
import useNetworkData from "hooks/useNetworkData";
import { BoxLoading } from "sharedComponents/loader";
import { LookbookBanner } from "sharedComponents/lookbookBanner";

export const TopCuratedLook = ({ title }: { title?: string }) => {
  const { isLoading, getData } = useNetworkData();
  const [lookbooks, setLookbooks] = useState<lookbookSchema[]>([]);

  const getLookbooks = useCallback(async () => {
    const res = await getData({
      requestName: "getAllLookBooks",
      apiRequest: GetLookbookRequest,
      payload: { limit: 0, offset: 0 },
    });
    if (res) {
      setLookbooks(res.data.slice(0, 2));
    }
  }, [getData]);

  useEffect(() => {
    getLookbooks();
  }, [getLookbooks]);

  return (
    <section className="container tcl-wrapper">
      <p className="sub-title mb-5">{title || "Top Curated lookbook"}</p>
      {isLoading ? (
        <div className="row g-5 tcl-row">
          {[...Array(2)].map((item, index) => (
            <div className="col-lg-6 col-12 mb-3" key={index}>
              <BoxLoading height={"35rem"} />
            </div>
          ))}
        </div>
      ) : (
        <div className="row g-5 tcl-row">
          {lookbooks.map((lookbook) => (
            <div className="col-lg-6 col-12 mb-3" key={lookbook.id}>
              <LookbookBanner lookbook={lookbook} />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
