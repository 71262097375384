import { gql } from "@apollo/client";
import { removeStorageData, StorageKeys } from "lib/storageManager";

export const LoginRequest = gql`
  mutation loginUser($password: String!, $emailAddress: String!) {
    loginUser(password: $password, emailAddress: $emailAddress) {
      token
      status
      data {
        id
        fullName
        emailAddress
        profileImage
        phoneNumber
        isAdmin
        isActive
      }
    }
  }
`;

export const ForgotPassRequest = gql`
  mutation forgotPassword(
    $emailAddress: String!
    $newPassword: String
    $authCode: String
  ) {
    forgotPassword(
      emailAddress: $emailAddress
      newPassword: $newPassword
      authCode: $authCode
    ) {
      status
      message
    }
  }
`;

export const RegisterRequest = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $password: String!
    $isAdmin: Boolean!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      password: $password
      isAdmin: $isAdmin
    ) {
      message
      status
    }
  }
`;

//logout
export const logoutService = () => {
  removeStorageData(StorageKeys.token);
  removeStorageData(StorageKeys.user);
  // window.location.replace("/");
  window.dispatchEvent(new Event("storage"));
};
