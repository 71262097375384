import { FAQ } from "sharedComponents/faq";
import { useEffect, useRef } from "react";
import "./style.scss";
import { LookbookGrid } from "sharedComponents/lookbookGrid";
import { NewArrival } from "sharedComponents/newArrival";
// import { Culture } from "sharedComponents/culture";
import { HowItWorks } from "./how-it-works";
import { HomeBanner } from "./homeBanner";
import { BestOfTheMonth } from "./best-of-month";
import { TopCuratedLook } from "./topCuratedLook";
import { Testimonial } from "./testimonial";
import { About } from "./about";
import { FloatingArrow } from "sharedComponents/floatingArrow";
import { useLocation } from "react-router-dom";

export const HomePage = () => {
  const location = useLocation();
  const lastHash = useRef<any>("");
  const scrollDiv = useRef<HTMLElement | any>(null);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }
    if (lastHash.current && document.querySelector(`#${lastHash.current}`)) {
      setTimeout(() => {
        window.scrollTo({ top: scrollDiv.current?.offsetTop });
        lastHash.current = "";
      }, 1000);
    }
  }, [location]);
  return (
    <section id="home-wrapper">
      <header>
        <HomeBanner />
      </header>
      <About />
      <LookbookGrid displayCount={1} hideFilterAndSort={true} />
      <NewArrival />
      {/* <Culture /> */}
      <HowItWorks />

      <BestOfTheMonth />

      <TopCuratedLook />
      <Testimonial />
      <section id="faqs" ref={scrollDiv}>
        <FAQ />
      </section>
      <FloatingArrow />
    </section>
  );
};
