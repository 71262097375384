import { Icon } from "@iconify/react";
import React from "react";

export const HowItWorks = () => {
  return (
    <section className="container">
      <div className="hiw-wrapper">
        <p className="sub-title">How it works</p>
        <div className="row g-5">
          <div className="col-lg-4 col-md-6 col-12 mb-3">
            <div className="hiw1">
              <Icon
                icon="akar-icons:dashboard"
                width="40"
                height="40"
                className="mb-3 text-black-500"
              />
              <h2 className="text-xl mb-4 text-black-500 fw-bold">
                Select lookbook
              </h2>
            </div>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-3">
            <div className="hiw2">
              <Icon
                icon="bi:minecart-loaded"
                width="40"
                height="40"
                className="mb-3 text-black-500"
              />
              <h2 className="text-xl mb-4 text-black-500 fw-bold">
                Add to cart
              </h2>
            </div>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-3">
            <div className="hiw3">
              <Icon
                icon="carbon:delivery"
                width="40"
                height="40"
                className="mb-3 text-black-500"
              />
              <h2 className="text-xl mb-4 text-black-500 fw-bold">Delivery</h2>
            </div>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
