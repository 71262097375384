import React, { useCallback, useEffect, useState } from "react";
import { FilterSortContainer } from "sharedComponents/FilterSortContainer";
import { Header } from "sharedComponents/header";
import useNetworkData from "hooks/useNetworkData";
import { ItemCard } from "sharedComponents/ItemCard";
import { GetProductsRequest } from "services/network/products";
import { productFilters, productsSchema } from "services/network/_schema";
import "./styles.scss";

export const ShopPage = () => {
  const { isLoading, getData, response } = useNetworkData();
  const [filterOptions, setFilterOptions] = useState<productFilters>({
    limit: 0,
    offset: 0,
    category: [""],
    size: [""],
    color: [""],
  });
  const [sortValues, setSortValues] = useState<Array<string>>([""]);

  const getProducts = useCallback(async () => {
    const payload: any = { ...filterOptions };

    if (filterOptions.size.filter((item) => item).length < 1) {
      delete payload.size;
    } else {
      payload.size = filterOptions.size.filter((item) => item);
    }

    if (filterOptions.category.filter((item) => item).length < 1) {
      delete payload.category;
    } else {
      payload.category = filterOptions.category.filter((item) => item);
    }

    if (filterOptions.color.filter((item) => item).length < 1) {
      delete payload.color;
    } else {
      payload.color = filterOptions.color.filter((item) => item);
    }

    await getData({
      requestName: "getAllProducts",
      apiRequest: GetProductsRequest,
      payload,
    });
  }, [filterOptions, getData]);

  // sorting
  const getSortedData = (sortvalue: string, sortData: productsSchema[], isDate?: boolean) => {
    const sortedData = sortData.sort((a: any, b: any) => {
      if (isDate) {
        return (new Date(a[sortvalue]) as any) - (new Date(b[sortvalue]) as any);
      }
      return a[sortvalue] - b[sortvalue];
    });

    return sortedData;
  };

  const findSortedValues = (sortvalue: any) => {
    const sortedValues = sortValues.find((item) => item === sortvalue);
    return sortedValues;
  };

  const handleProductSorting = (shopProductsList: productsSchema[]) => {
    let shopProductList = shopProductsList;

    if (findSortedValues("latest")) {
      shopProductList = getSortedData("createdAt", shopProductsList, true).reverse();
    }

    if (findSortedValues("new")) {
      shopProductList = getSortedData("newPrice", shopProductsList, false);
    }

    if (findSortedValues("used")) {
      shopProductList = getSortedData("usedPrice", shopProductsList, false);
    }

    return shopProductList;
  };

  useEffect(() => {
    getProducts();
  }, [filterOptions, getProducts]);
  return (
    <>
      <Header title="Shop" subTitle="Complete your autumn wardrobe with trendy pieces" />
      <section className="container">
        <FilterSortContainer
          sortValues={sortValues}
          setSortValues={setSortValues}
          totalCount={response?.totalCount}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <div className="products-grid mb-9">
          {isLoading ? (
            <>
              {[...Array(8)].map((item, index) => (
                <div key={index}>
                  <ItemCard
                    item={item}
                    loading={isLoading}
                    showCheck={true}
                    imgStyles={{ height: "35rem" }}
                    cardStyles={{ padding: "2rem" }}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {response && response.data.length > 0 && (
                <>
                  {handleProductSorting(response.data).map((item: productsSchema) => (
                    <div className="w-100" key={item.id}>
                      <ItemCard
                        item={item}
                        loading={isLoading}
                        showCheck={true}
                        imgStyles={{ height: "35rem" }}
                        cardStyles={{
                          padding: "2rem",
                          marginInline: "auto",
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
        {!isLoading && response && response.data.length === 0 && (
          <div className="row mb-9">
            <div className="text-center bg-primary-50 py-4 box-shadow mx-auto col-lg-5 col-md-7 col-12">
              <p className="fw-medium text-lg">We couldn’t find what you were looking for.</p>
              <p className="text-base">Keep calm and consider using different filter values.</p>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
