import { Header } from "sharedComponents/header";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { GetAllBrandsRequest } from "services/network/products";
import useNetworkData from "hooks/useNetworkData";
import { BoxLoading } from "sharedComponents/loader";
import { Icon } from "@iconify/react";

const list = [
  {
    letter: "A",
    name: "Ajike Flows",
  },
  {
    letter: "B",
    name: "Becky Fin",
  },
  {
    letter: "C",
    name: "Camel Active",
  },
  {
    letter: "D",
    name: "Donkey",
  },
  {
    letter: "E",
    name: "Egg",
  },
  {
    letter: "F",
    name: "Egg",
  },
  {
    letter: "G",
    name: "Egg",
  },
  {
    letter: "H",
    name: "Egg",
  },
  {
    letter: "I",
    name: "Egg",
  },
  {
    letter: "J",
    name: "Egg",
  },
  {
    letter: "K",
    name: "Egg",
  },
  {
    letter: "L",
    name: "Egg",
  },
  {
    letter: "M",
    name: "Egg",
  },
  {
    letter: "N",
    name: "Egg",
  },
  {
    letter: "O",
    name: "Egg",
  },
  {
    letter: "P",
    name: "Egg",
  },
  {
    letter: "Q",
    name: "Egg",
  },
  {
    letter: "R",
    name: "Egg",
  },
  {
    letter: "S",
    name: "Egg",
  },
  {
    letter: "T",
    name: "Egg",
  },
  {
    letter: "U",
    name: "Egg",
  },
  {
    letter: "V",
    name: "Egg",
  },
  {
    letter: "X",
    name: "Egg",
  },
  {
    letter: "Y",
    name: "Egg",
  },
  {
    letter: "Z",
    name: "Egg",
  },
];

export const BrandsPage = () => {
  const { isLoading, getData } = useNetworkData();
  const [brands, setBrands] = useState<any[]>([]);
  const [availableLetters, setAvailableLetters] = useState<string[]>([]);

  const getBrands = useCallback(async () => {
    const res = await getData({
      requestName: "getAllBrands",
      apiRequest: GetAllBrandsRequest,
      payload: { limit: 0, offset: 0 },
    });
    if (res) {
      setBrands(
        res.data.map((item: any) => ({
          ...item,
          letter: item.name.split("")[0],
        }))
      );
      const letters: any = [...new Set(res.data.map((item: any) => item.name.split("")[0]))];
      setAvailableLetters([...letters].sort());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="brand-wrapper">
      <Header title="Brands" />
      <section className="container">
        <div className="row brand-img-wrapper">
          <div className="col-lg-9 col-md-11 col-12 mx-auto">
            <div className="d-flex justify-content-between">
              {brands.slice(0, 4).map((item) => (
                <Link to={`${item.id}/${item.name}`} key={item.id}>
                  <img src={item.image} alt="" />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div>
          <ul className="d-flex gap-5 flex-wrap list-unstyled mb-5">
            {list.map((item) => (
              <li className="list-unstyled" key={item.letter}>
                <a
                  href={`#${item.letter}`}
                  className={`brand-letter ${!availableLetters.includes(item.letter) && "inactive"}`}
                >
                  {item.letter}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <hr />
      <section className="container">
        {isLoading ? (
          <>
            {[...Array(10)].map((item, index) => (
              <div key={index} className="my-5">
                <div className="brand-letter big"></div>
                <p className="text-black-500 text-sm fw-medium mt-3 mb-5">
                  <BoxLoading height={"3.5rem"} />
                </p>
                {index + 1 < list.length && <hr />}
              </div>
            ))}
          </>
        ) : (
          <>
            {availableLetters.map((letter, index) => (
              <div key={letter} className="my-5">
                <div className="brand-letter big" id={letter}>
                  {letter}
                </div>
                <p className="mt-3 mb-5 d-flex gap-3">
                  {brands
                    .filter((item) => item.letter === letter)
                    .map((item) => (
                      <Link
                        key={item.id}
                        className="text-black-500 text-sm fw-medium text-decoration-none"
                        to={`${item.id}/${item.name}`}
                      >
                        <Icon icon="carbon:dot-mark" />
                        {item.name}
                      </Link>
                    ))}
                </p>
                {index + 1 < availableLetters.length && <hr />}
              </div>
            ))}
          </>
        )}
      </section>
    </section>
  );
};
