import useNetworkData from "hooks/useNetworkData";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { GetBuyOfTheMonth } from "services/network/products";
import Loader from "sharedComponents/loader";

export const BestOfTheMonth = () => {
  const { isLoading, getData } = useNetworkData();
  const [bestBuy, setBestBuy] = useState<any>({});
  const getBestBuy = async () => {
    try {
      const res = await getData({
        requestName: "getBuyOfTheMonth",
        apiRequest: GetBuyOfTheMonth,
      });
      if (res) {
        setBestBuy(res.data);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getBestBuy();
  }, []);

  return (
    <>
      {!isLoading ? (
        bestBuy ? (
          <section
            className="botm-wrapper"
            style={{
              backgroundImage: `url(${bestBuy.heroImage})`,
            }}
          >
            <h2 className="text-3xl text-white fw-bold">Best of the Month</h2>
            <div>
              <Link to={`/product/${bestBuy.id}`} className="btn btn-black btn-lg">
                Buy Now
              </Link>
            </div>
          </section>
        ) : (
          <section className="empty-best-buy">
            <h2 className="text-3xl fw-bold">Best of the Month</h2>

            <p className="best-buy-text">There is no best of the month available for now.</p>
          </section>
        )
      ) : (
        <Loader title="Loading..." />
      )}
    </>
  );
};
