import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./sidebar.scope.scss";
import logo from "assets/images/otito-logo-blk.svg";
import { CartModal } from "../cartModal";

export const Sidebar = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const sidebarToggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <div
        className={
          isOpen ? "overlay-isOpen sidebar-overlay" : "sidebar-overlay"
        }
        onClick={sidebarToggle}
      >
        <div className="close">
          <Icon icon="clarity:times-line" />
        </div>
      </div>

      <div
        className={
          isOpen
            ? "navbar-toggler menu-icon d-none"
            : "navbar-toggler menu-icon"
        }
        onClick={sidebarToggle}
      >
        <Icon icon="grommet-icons:menu" />
      </div>
      <div className="nav-logo-2">
        <NavLink to="/">
          <img src={logo} alt="" className="logo" />
        </NavLink>
      </div>
      <section className={isOpen ? "sidebar-isOpen sidebar" : "sidebar"}>
        <div className="sidebar-wrapper">
          <div className="side-logo">
            <Link to="/">
              <img src={logo} alt="" className="logo" />
            </Link>
          </div>
          <div className="mt-5">
            <NavLink
              onClick={sidebarToggle}
              to="/lookbooks"
              className={({ isActive }) =>
                isActive ? "active side-item" : "side-item"
              }
            >
              Lookbook
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "active side-item" : "side-item"
              }
              onClick={sidebarToggle}
              to="/shop"
            >
              Shop
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active side-item" : "side-item"
              }
              onClick={sidebarToggle}
              to="/brands"
            >
              Brands
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active side-item" : "side-item"
              }
              onClick={sidebarToggle}
              to="/our-story"
            >
              Our Story
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active side-item" : "side-item"
              }
              onClick={sidebarToggle}
              to="/account"
            >
              My Account
            </NavLink>
            <button
              className="side-item bg-transparent"
              type="button"
              onClick={() => setCartOpen(!cartOpen)}
            >
              Cart
            </button>
          </div>
        </div>
      </section>
      <CartModal show={cartOpen} handleClose={() => setCartOpen(false)} />
    </>
  );
};
