import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import React, { useCallback, useEffect, useState } from "react";
import { getShippingAddressRequest, updateShippingRequest } from "services/network/account";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import FloatingInput from "sharedComponents/forms/FloatingInput";
import { shippingSchema } from "services/network/_schema";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { toast } from "react-toastify";
import ThemedSelect from "sharedComponents/forms/ThemedSelect";
import { convertArrayToSelectOptions } from "utilities";
import countries from "utilities/countries+states+cities.json";

type countryType = {
  id: number;
  name: string;
  states: {
    id: number;
    name: string;
    cities: Array<{
      id: number;
      name: string;
    }>;
  }[];
};
const countryList = (countries as countryType[]).map((val) => val.name);

export const AccountData = () => {
  const { isMutating, mutateData, getData, isLoading } = useNetworkData();
  const user = getStorageData(StorageKeys.user);
  const [cityList, setCityList] = useState<string[]>([]);
  const [stateList, setStateList] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState(false);

  const [initialValues, setInitialValues] = useState<any>({
    email: user.emailAddress,
    lastName: "",
    firstName: "",
    phoneNumber: "",
    state: "",
    city: "",
    country: "",
    street: "",
    zipCode: "",
  });

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .label("First Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    lastName: yup
      .string()
      .label("Last Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    email: yup.string().label("Email Address").email("Invalid email").required(),
    phoneNumber: yup
      .string()
      .label("Phone Number")
      .min(7, "Enter a valid number")
      .max(15, "Enter a valid number")
      .matches(/^[0-9)( -]+$/, "Enter a valid number")
      .required(),
    state: isRequired ? yup.object().label("State").required() : yup.object().label("State"),
    city: isRequired ? yup.object().label("City").required() : yup.object().label("City"),
    country: yup.object().label("Country").required(),
    street: yup.string().label("Full Address").required(),
    zipCode: yup.string().label("Zip Code").required(),
  });

  const handleUpdate = async (formValues: any) => {
    const res: any = await mutateData({
      requestName: "updateShippingAddress",
      apiRequest: updateShippingRequest,
      payload: {
        ...formValues,
        country: formValues.country.value,
        state: formValues.state.value,
        city: formValues.city.value,
      },
    });
    if (res.status === "success") {
      toast.success("Shipping address updated!");
    }
  };

  const getDetails = useCallback(async () => {
    const res = await getData({
      requestName: "getShippingAddress",
      apiRequest: getShippingAddressRequest,
    });
    if (res) {
      const { data }: { data: shippingSchema } = res;

      if (data.country) {
        const country = (countries as countryType[]).find((val) => val.name === data.country);
        setStateList(country?.states.map((val) => val.name) || []);
        const states = country?.states.find((val) => val.name === data.state);
        setCityList(states?.cities.map((val) => val.name) || []);
      }

      setInitialValues((prev: any) => ({
        ...prev,
        lastName: data.lastName || user.fullName.split(" ")[1],
        firstName: data.firstName || user.fullName.split(" ")[0],
        street: data.street || "",
        zipCode: data.zipCode || "",
        phoneNumber: data.phoneNumber || "",
        country: data.country
          ? {
              value: data.country,
              label: data.country,
            }
          : "",
        state: data.state
          ? {
              value: data.state,
              label: data.state,
            }
          : "",
        city: data.city
          ? {
              value: data.city,
              label: data.city,
            }
          : "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <section className="container my-6">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ handleSubmit, isValid, setFieldValue, values }) => {
          return (
            <Form onSubmit={handleSubmit} className="form-wrapper">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                  <div className="d-flex justify-content-between">
                    <p className="text-2xl text-black-500 fw-bold">Personal data</p>
                    <p className="text-xs fw-medium text-grey-100 my-auto click">
                      Edit
                      <Icon icon="carbon:edit" />
                    </p>
                  </div>
                  <div className="mb-4">
                    <Field component={FloatingInput} label="First name*" name="firstName" id="firstName" />
                  </div>
                  <div className="mb-4">
                    <Field component={FloatingInput} label="Last Name*" name="lastName" id="lastName" />
                  </div>
                  <div className="mb-4">
                    <Field
                      component={FloatingInput}
                      label="Phone Number*"
                      name="phoneNumber"
                      id="phoneNumber"
                      type="tel"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      component={FloatingInput}
                      label="Email Address*"
                      name="email"
                      id="email"
                      type="email"
                      readOnly
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 mb-4 ms-md-auto ms-lg-auto">
                  <div className="d-flex justify-content-between">
                    <p className="text-2xl text-black-500 fw-bold">Address</p>
                    <p className="text-xs fw-medium text-grey-100 my-auto">
                      Edit
                      <Icon icon="carbon:edit" />
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Field
                        component={FloatingInput}
                        label="Address*"
                        name="street"
                        id="street"
                        placeholder="Street"
                      />
                    </div>
                    <div className="col-6 mb-4">
                      <Field
                        component={ThemedSelect}
                        size="base"
                        name="country"
                        id="country"
                        placeholder="Select Country*"
                        className="react-select"
                        options={convertArrayToSelectOptions(countryList)}
                        onChange={(value: { value: string; label: string }) => {
                          setFieldValue("city", "");
                          setFieldValue("state", "");

                          const countryDetails = (countries as countryType[]).find((val) => val.name === value.value);

                          if ((countryDetails as countryType)?.states.length > 0) {
                            setStateList((countryDetails as countryType)?.states.map((val) => val.name));
                            setIsRequired(true);
                          } else {
                            setStateList([]);
                            setCityList([]);
                            setIsRequired(false);
                          }
                          setFieldValue("country", value);
                        }}
                      />
                    </div>

                    <div className="col-6 mb-4">
                      <Field
                        component={ThemedSelect}
                        size="base"
                        name="state"
                        id="state"
                        placeholder={isRequired ? "Select State*" : "Select State"}
                        className="react-select"
                        options={convertArrayToSelectOptions(stateList)}
                        onChange={(value: { value: string; label: string }) => {
                          const country = (countries as countryType[]).find((val) => val.name === values.country.value);
                          const statesInfo = country?.states.find((val) => val.name === value.value);

                          if ((statesInfo as any)?.cities.length < 1) {
                            setIsRequired(false);
                          } else {
                            setIsRequired(true);
                          }

                          setCityList(statesInfo?.cities.map((val) => val.name) || []);

                          setFieldValue("city", "");
                          setFieldValue("state", value);
                        }}
                      />
                    </div>

                    <div className="col-12 mb-4">
                      <Field
                        component={ThemedSelect}
                        size="base"
                        name="city"
                        id="city"
                        placeholder={isRequired ? "Select City*" : "Select City"}
                        className="react-select"
                        options={convertArrayToSelectOptions(cityList)}
                      />
                      {cityList.length === 0 && (
                        <div className="text-xs ">
                          <b>NB:</b> Select a state to select a city under it.
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-4">
                      <Field
                        component={FloatingInput}
                        label="Zip Code*"
                        name="zipCode"
                        id="zipCode"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>
                </div>
                {!isValid && (
                  <p className="text-sm text-error fw-semi-bold">Note: All inputs are required to save changes</p>
                )}
                <div className="col-lg-4 col-md-6 col-sm-7 my-5 mx-auto">
                  <Button
                    className="btn btn-lg btn-black w-100"
                    title="Save Changes"
                    loading={isMutating}
                    disabled={isLoading || isMutating || !isValid}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};
