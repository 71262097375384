import React from "react";

export const About = () => {
  return (
    <section className="container">
      <div className="about-section fw-semi-bold px-md-5 text-black-500 text-center text-xl">
        Otito Lab stands for fashion-consious traditional constumes, which, with
        their exclusive and special design, make every lady in a dirndl and
        every gentlemen in lederhosen an eye-catcher in traditional constume
        fashion.
      </div>
    </section>
  );
};
