import { Header } from "sharedComponents/header";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import useNetworkData from "hooks/useNetworkData";
import TextInput from "sharedComponents/forms/TextInput";
import Button from "sharedComponents/forms/Button";
import { ForgotPassRequest } from "services/network/auth";
import { toast } from "react-toastify";

export const ForgotPasswordPage = () => {
  const { isMutating, mutateData } = useNetworkData();
  const navigate = useNavigate();

  const initialValues = {
    emailAddress: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup.string().label("Email Address").required(),
  });

  const handleSend = async (formValues: any) => {
    const res: any = await mutateData({
      requestName: "forgotPassword",
      apiRequest: ForgotPassRequest,
      payload: formValues,
    });
    if (res) {
      toast.success(res.message);
      navigate("/reset-password");
    }
  };

  return (
    <>
      <Header title="Account - Forgot Password" />
      <section className="container">
        <div className="row my-9">
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
            <div className="border px-5 py-6">
              <p className="text-2xl text-black-500 fw-bold text-center mb-5">
                Forgot Password
              </p>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSend}
              >
                {({ handleSubmit, isValid }) => {
                  return (
                    <>
                      <Form onSubmit={handleSubmit} className="form-wrapper">
                        <Field
                          component={TextInput}
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          className="mt-4"
                          placeholder="Email address"
                        />
                        <Button
                          className="btn btn-black btn-lg w-100 mt-5"
                          type="submit"
                          title={"Send Recovery Email"}
                          loading={isMutating}
                          loadingTitle={"Sending..."}
                          disabled={!isValid || isMutating}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <div className="d-flex justify-content-center text-sm mt-5 fw-medium">
                <Link
                  to="/register"
                  className="text-decoration-none text-primary-500 fw-semi-bold"
                >
                  New account
                </Link>
                <div className="border mx-3"></div>
                <Link
                  to="/login"
                  className="text-decoration-none text-primary-500 fw-semi-bold"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
