import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ItemCard } from "../ItemCard";
import Slider from "react-slick";
import { SliderNextArrow, SliderPrevArrow } from "../customSliderArrows";
import useNetworkData from "hooks/useNetworkData";
import { GetMultipleProductByIdRequest } from "services/network/products";
import { productsSchema } from "services/network/_schema";
import { getStorageData, StorageKeys } from "lib/storageManager";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      centerMode: true,
    },
  },
];

export const SuggestedItems = ({
  title,
  products,
}: {
  title?: string;
  products?: productsSchema[];
}) => {
  const { getData } = useNetworkData();
  const [items, setItems] = useState<productsSchema[]>([]);
  const user = getStorageData(StorageKeys.user);

  const getProducts = async () => {
    const recent = getStorageData(StorageKeys.recent);
    if (recent && user) {
      const ids = recent[user.id];
      if (ids) {
        const res = await getData({
          requestName: "getMultipleProductById",
          apiRequest: GetMultipleProductByIdRequest,
          payload: { ids },
        });
        setItems(res.data);
      }
    }
  };

  useEffect(() => {
    if (products) {
      setItems(products);
    } else {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (items.length === 0) return null;
  return (
    <section>
      <p className="sub-title mb-5">
        {title || "You may also be interested in"}
      </p>
      {items.length > 1 ? (
        <Slider
          speed={1000}
          infinite={true}
          slidesToShow={items.length < 2 ? 1 : items.length < 3 ? 2 : 3}
          slidesToScroll={1}
          centerMode={true}
          responsive={responsive}
          className="general-carousel new-arrival-carousel"
          prevArrow={<SliderPrevArrow />}
          nextArrow={<SliderNextArrow />}
        >
          {items.map((item: productsSchema) => (
            <div className="slider-item" key={item.id}>
              <ItemCard
                item={item}
                showCheck={true}
                imgStyles={{ height: "32rem" }}
                cardStyles={{ padding: "2rem", margin: "1.5rem" }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="row">
          <div className="col-lg-5 col-12">
            <ItemCard
              item={items[0]}
              imgStyles={{ height: "32rem" }}
              cardStyles={{ padding: "2rem", margin: "1.5rem" }}
              showCheck={true}
            />
          </div>
        </div>
      )}
    </section>
  );
};
