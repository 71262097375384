import { Header } from "sharedComponents/header";
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RegisterRequest } from "services/network/auth";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import useNetworkData from "hooks/useNetworkData";
import { toast } from "react-toastify";
import PasswordInput from "sharedComponents/forms/PasswordInput";

export const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from }: any = location.state || { from: "/" };
  const { isMutating, mutateData } = useNetworkData();
  const [agreement, setAgreement] = useState(false);

  const initialValues = {
    emailAddress: "",
    password: "",
    // password2: "",
    firstName: "",
    lastName: "",
    isAdmin: false,
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .label("First Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    lastName: yup
      .string()
      .label("Last Name")
      .min(3, "Enter a valid name")
      .matches(/^[a-zA-Z. _-]+$/, "Enter a valid name")
      .required(),
    emailAddress: yup
      .string()
      .label("Email Address")
      .email("Invalid email")
      .required(),
    password: yup
      .string()
      .label("Password")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        "Enter at least 8 alphanumeric characters"
      )
      .required(),
    // password2: yup
    //   .string()
    //   .label("Confirm Password")
    //   .oneOf([null, yup.ref("password")], "Both passwords need to be the same")
    //   .required(),
  });

  const handleRegister = async (formValues: any) => {
    const payload = { ...formValues };
    // delete payload.password2;
    const res: any = await mutateData({
      requestName: "createUser",
      apiRequest: RegisterRequest,
      payload,
    });
    if (res.status === "success") {
      toast.success(`Welcome ${formValues.firstName} 😊!`);
      // navigate("/login");
      // navigate("/login", { state: { from: location.pathname } });
      navigate("/login", {
        state: { from: from },
      });
    }
  };

  return (
    <>
      <Header title="Account - New account" />
      <section className="container">
        <div className="row my-9">
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <Form onSubmit={handleSubmit} className="form-wrapper">
                    <div className="border px-5 py-6">
                      <p className="text-2xl text-black-500 fw-bold text-center mb-5">
                        New account
                      </p>
                      <Field
                        component={TextInput}
                        name="firstName"
                        id="firstName"
                        className="mt-4"
                        placeholder="First name"
                      />
                      <Field
                        component={TextInput}
                        name="lastName"
                        id="lastName"
                        className="mt-4"
                        placeholder="Last Name"
                      />
                      <Field
                        component={TextInput}
                        name="emailAddress"
                        id="emailAddress"
                        type="email"
                        className="mt-4"
                        placeholder="Email address"
                      />
                      <Field
                        component={PasswordInput}
                        name="password"
                        id="password"
                        type="password"
                        autoComplete="on"
                        className="mt-4"
                        placeholder="Password"
                      />
                      {/* <Field
                        component={PasswordInput}
                        name="password2"
                        id="password2"
                        className="mt-4"
                        placeholder="Confirm Password"
                      /> */}

                      <div className="d-flex align-items-center my-5 text-sm fw-medium">
                        <label
                          className="me-2 text-black-500 click"
                          htmlFor="agreement"
                        >
                          <input
                            type="checkbox"
                            name="agreement"
                            id="agreement"
                            checked={agreement}
                            onChange={({ target: { checked } }) => {
                              setAgreement(checked);
                            }}
                          />

                          <span className="ms-2">
                            You agree to our friendly
                          </span>
                        </label>
                        <Link
                          to="/"
                          className="text-decoration-none text-primary-500 fw-semi-bold"
                        >
                          Privacy policy
                        </Link>
                      </div>
                      <Button
                        className="btn btn-black btn-lg w-100 mt-5"
                        type="submit"
                        title="Create Account"
                        loading={isMutating}
                        loadingTitle={"Please wait..."}
                        disabled={!isValid || isMutating || !agreement}
                      />
                      <p className="text-sm text-black-500 text-center fw-medium mt-3">
                        <span className="me-2">Already have an account?</span>
                        <Link
                          to="/login"
                          className="text-decoration-none text-primary-500 fw-semi-bold"
                        >
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};
