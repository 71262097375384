import React, { ReactNode } from "react";
import { Sidebar } from "sharedComponents/sidebar";
import { Navbar } from "sharedComponents/navbar";
import { Footer } from "sharedComponents/footer";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const Layout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  return (
    <section className="layout-wrapper">
      <div>
        <Navbar />
        <Sidebar />
        {children}
      </div>
      <Footer navigate={navigate} />
    </section>
  );
};
