import { Header } from "sharedComponents/header";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import { saveStorageData, StorageKeys } from "lib/storageManager";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import useNetworkData from "hooks/useNetworkData";
import { LoginRequest } from "services/network/auth";
import PasswordInput from "sharedComponents/forms/PasswordInput";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { isMutating, mutateData } = useNetworkData();
  // const token = getStorageData(StorageKeys.token);
  const location = useLocation();
  const { from }: any = location.state || { from: "/" };

  const initialValues = {
    emailAddress: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup.string().label("Email Address").required(),
    password: yup.string().label("Password").required(),
  });

  const handleLogin = async (formValues: any) => {
    const payload = {
      ...formValues,
      emailAddress: formValues.emailAddress.toLowerCase(),
    };
    const res: any = await mutateData({
      requestName: "loginUser",
      apiRequest: LoginRequest,
      payload: payload,
    });
    if (res) {
      saveStorageData(StorageKeys.token, res.token);
      saveStorageData(StorageKeys.user, res.data);
      window.dispatchEvent(new Event("storage"));
      navigate(`${from}`);
    }
  };

  // useEffect(() => {
  //   if (token) navigate("/account");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  return (
    <>
      <Header title="Account - Sign in" />
      <section className="container">
        <div className="row my-9">
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
            <div className="border px-5 py-6">
              <p className="text-2xl text-black-500 fw-bold text-center mb-5">Sign In</p>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ handleSubmit, isValid }) => {
                  return (
                    <>
                      <Form onSubmit={handleSubmit} className="form-wrapper">
                        <Field
                          component={TextInput}
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          className="mt-4"
                          placeholder="Email address"
                        />
                        <Field
                          component={PasswordInput}
                          name="password"
                          id="password"
                          autoComplete="on"
                          className="mt-4"
                          placeholder="Password"
                        />

                        <Button
                          className="btn btn-black btn-lg w-100 mt-5"
                          type="submit"
                          title={"Sign in"}
                          loading={isMutating}
                          loadingTitle={"Please wait..."}
                          disabled={!isValid || isMutating}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <div className="d-flex justify-content-center text-sm mt-5 fw-medium">
                <button
                  onClick={() =>
                    navigate("/register", {
                      state: { from: from },
                    })
                  }
                  className="text-decoration-none text-primary-500 fw-semi-bold bg-transparent"
                >
                  New account
                </button>
                <div className="border mx-3"></div>
                <Link to="/forgot-password" className="text-decoration-none text-primary-500 fw-semi-bold">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
