import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { GQLMutate, GQLQuery } from "services/gql-client";
import { handleGeneralErrors } from "utilities/handleGeneralErrors";

function useNetworkData() {
  const [response, setResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [error, setError] = useState();

  const getData = useCallback(
    async ({
      payload,
      requestName,
      apiRequest,
      returnError,
    }: {
      payload?: any;
      requestName: string;
      apiRequest: any;
      returnError?: boolean;
    }) => {
      setIsLoading(true);
      return GQLQuery(requestName, apiRequest, payload)
        .then((res: any) => {
          setResponse(res);
          if (res.status === "error") {
            toast.error(res.message);
            console.log({ err: res.message });
            return;
          }
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          if (returnError) {
            setError(err);
          } else {
            handleGeneralErrors(err);
          }
        })
        .finally(() => setIsLoading(false));
    },
    []
  );

  const mutateData = useCallback(
    async ({
      payload,
      requestName,
      apiRequest,
      returnError,
    }: {
      payload?: any;
      requestName: string;
      apiRequest: any;
      returnError?: boolean;
    }) => {
      setIsMutating(true);
      return GQLMutate(requestName, apiRequest, payload)
        .then((data: any) => {
          setResponse(data);
          if (data.status === "error") {
            toast.error(data.message);
            return;
          }
          return data;
        })
        .catch((err) => {
          console.log(err);
          setIsMutating(false);
          if (returnError) {
            setError(err);
          } else {
            handleGeneralErrors(err);
          }
        })
        .finally(() => setIsMutating(false));
    },
    []
  );

  return { isLoading, isMutating, getData, mutateData, response, error };
}

export default useNetworkData;
