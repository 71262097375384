import React, { useCallback, useEffect, useState } from "react";
import { GetWishlistRequest } from "services/network/products";
import useNetworkData from "hooks/useNetworkData";
import Loader from "sharedComponents/loader";
import SavedItemsCard from "sharedComponents/ItemCard/saveditems";

export const SavedItems = () => {
  const { getData } = useNetworkData();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSavedItems = useCallback(async () => {
    setLoading(true);
    const res = await getData({
      requestName: "getUserWishList",
      apiRequest: GetWishlistRequest,
    });
    setLoading(false);
    if (res) {
      setItems(res.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSavedItems();
  }, [getSavedItems]);

  return (
    <div className="container">
      {loading ? (
        <Loader title="Getting details..." />
      ) : (
        <>
          {items.length > 0 ? (
            <div className="row mb-9 mt-6">
              {items.map((data: any) => {
                return (
                  <SavedItemsCard
                    data={data}
                    key={data.sourceType === "product" ? data.product.id : data.lookbook.id}
                    showCheck={true}
                    loading={loading}
                    setItems={setItems}
                    items={items}
                  />
                );
              })}
            </div>
          ) : (
            <p className="my-9 text-xl text-center">You have no saved Items</p>
          )}
        </>
      )}
    </div>
  );
};
