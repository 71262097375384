import { gql } from "@apollo/client";

export const GetLookbookRequest = gql`
  query getAllLookBooks(
    $limit: Int!
    $offset: Int!
    $category: [ID]
    $tags: [ID]
    $products: [ID]
    $nameLike: String
    $startDate: Date
    $endDate: Date
  ) {
    getAllLookBooks(
      limit: $limit
      offset: $offset
      category: $category
      tags: $tags
      products: $products
      nameLike: $nameLike
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      message
      totalCount
      data {
        id
        name
        description
        image
        createdAt
        category {
          id
          name
          description
          image
        }
        tags {
          id
          name
          description
          image
        }
        products {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
          inWishList
        }
        inWishList
      }
    }
  }
`;

export const GetProductLookBookDetailsRequest = gql`
  query getProductLookBookDetails($productId: String!, $lookBookId: String) {
    getProductLookBookDetails(productId: $productId, lookBookId: $lookBookId) {
      status
      message
      data {
        id
        name
        description
        image
        category {
          id
          name
          description
          image
        }
        tags {
          id
          name
          description
          image
        }
        products {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GetLookbookByNameOrId = gql`
  query getLookBookByNameOrId($value: String!) {
    getLookBookByNameOrId(value: $value) {
      status
      message
      totalCount
      data {
        id
        name
        description
        image
        category {
          id
          name
          description
          image
        }
        tags {
          id
          name
          description
          image
        }
        products {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
          # inWishList
        }
      }
    }
  }
`;

export const LookBookProductsAddedToCart = gql`
  input LookBookProductsAddedToCart {
    productId: ID!
    quantity: Int!
    color: String
    size: String!
    newPrice: Boolean!
  }
`;

export const addLookBookToCart = gql`
  mutation addLookbookToCart($lookbookId: ID!, $products: [LookBookProductsAddedToCart]!) {
    addLookbookToCart(lookbookId: $lookbookId, products: $products) {
      status
      message
    }
  }
`;

export const saveLookBookCustomEdit = gql`
  mutation saveLookBookCustomEdit($lookbookId: ID!, $products: [LookBookProductsAddedToCart]!) {
    saveLookBookCustomEdit(lookbookId: $lookbookId, products: $products) {
      status
      message
    }
  }
`;

export const getLookBookEditInProgress = gql`
  query getLookBookEditInProgress($lookbookId: ID!) {
    getLookBookEditInProgress(lookbookId: $lookbookId) {
      message
      status
      data {
        cartId
        itemId
        itemCategory
        details {
          productId
          quantity
          color
          size
          amount
          datetime
        }
        product {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          heroImage
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
        }
        lookbook {
          id
          name
          description
          image
        }
      }
    }
  }
`;
