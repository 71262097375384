import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ProductGallery = ({
  pictures,
  setIsOpenGallery,
}: {
  pictures: string[];
  setIsOpenGallery: any;
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      <Lightbox
        mainSrc={pictures[photoIndex]}
        nextSrc={pictures[(photoIndex + 1) % pictures.length]}
        prevSrc={pictures[(photoIndex + pictures.length - 1) % pictures.length]}
        onCloseRequest={() => setIsOpenGallery(false)}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + pictures.length - 1) % pictures.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % pictures.length)
        }
      />
    </>
  );
};
