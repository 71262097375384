import { Icon } from "@iconify/react";
import { SuggestedItems } from "sharedComponents/suggestedItems";
import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { ReviewDetail } from "./reviewDetail";
import useNetworkData from "hooks/useNetworkData";
import { useParams } from "react-router-dom";
import { GetProductByIdRequest } from "services/network/products";
import { BoxLoading } from "sharedComponents/loader";
import useAddProduct from "hooks/useAddProduct";
import Button from "sharedComponents/forms/Button";
import { ProductGallery } from "./productGallery";
import { getStorageData, saveStorageData, StorageKeys } from "lib/storageManager";
import { SimilarProducts } from "./similarProducts";

export const ProductDetailPage = () => {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const cart = getStorageData(StorageKeys.cart);
  const defaultSelection = {
    size: cart?.size || "",
    color: cart?.color || "",
    quantity: cart?.quantity || 1,
    newPrice: cart?.newPrice || false,
  };
  const [selected, setSelected] = useState<{
    size: string;
    color: string;
    quantity: number;
    newPrice: boolean;
  }>(defaultSelection);
  const user = getStorageData(StorageKeys.user);

  const { isLoading, getData, response } = useNetworkData();
  const { isAddToCart, wishlistLoading, addToCart, toggleWishlist } = useAddProduct();
  const data = response?.data[0];
  const { id } = useParams();

  const getProduct = useCallback(async () => {
    setSelected(defaultSelection);
    await getData({
      requestName: "getProductByNameOrId",
      apiRequest: GetProductByIdRequest,
      payload: { value: id },
    });
  }, [getData, id]);

  const increaseQty = () => {
    if (selected.quantity < data?.units) setSelected((prev) => ({ ...prev, quantity: prev.quantity + 1 }));
  };

  const decreaseQty = () => {
    if (selected.quantity > 1) setSelected((prev) => ({ ...prev, quantity: prev.quantity - 1 }));
  };

  useEffect(() => {
    getProduct();
    if (user) {
      const recent = getStorageData(StorageKeys.recent) || {};
      const currentIds = recent[user.id] || [];
      const index = currentIds.findIndex((itemId: string) => itemId === id);
      if (index >= 0) {
        currentIds.splice(index, 1);
      }
      currentIds.unshift(id);
      const newIds = currentIds.slice(0, 10);
      recent[user.id] = newIds;
      saveStorageData(StorageKeys.recent, recent);
    }
  }, [getProduct, id]);

  return (
    <section className="container mt-9 product-wrapper">
      <div className="row pt-5">
        <div className="col-lg-6 col-md-10 mb-4">
          <div className="p-image-grid">
            {isLoading ? (
              <>
                <BoxLoading height={"25rem"} />
                <BoxLoading height={"25rem"} />
                <BoxLoading height={"25rem"} />
                <BoxLoading height={"25rem"} />
              </>
            ) : (
              <>
                {data && (
                  <>
                    {[data.heroImage, ...data.image]
                      .filter((img) => img)
                      .slice(0, 4)
                      .map((item, index) => (
                        <img key={index} src={item} alt="" className="click" onClick={() => setIsOpenGallery(true)} />
                      ))}
                  </>
                )}
              </>
            )}
          </div>
          {/* <p className="text-primary-500 text-xs fw-bold">
            Click images to expand
          </p> */}
        </div>
        <div className="col-lg-6 col-md-10">
          {isLoading ? (
            <>
              <BoxLoading height={"3rem"} />
              <BoxLoading height={"4rem"} />
              <div className="row">
                {[...Array(2)].map((item, index) => (
                  <div className="col-4 mt-4" key={index}>
                    <BoxLoading height={"6rem"} />
                  </div>
                ))}
                <div className="col-12 my-4"></div>
                <div className="col-4">
                  <BoxLoading height={"3rem"} />
                </div>
                <div className="col-12 my-4"></div>
                {[...Array(4)].map((item, index) => (
                  <div className="col-1" key={index}>
                    <BoxLoading height={"3rem"} />
                  </div>
                ))}
                <div className="col-12 my-4"></div>

                {[...Array(4)].map((item, index) => (
                  <div className="col-1" key={index}>
                    <BoxLoading height={"3rem"} />
                  </div>
                ))}
                <div className="col-12 my-4"></div>

                {[...Array(2)].map((item, index) => (
                  <div className="col-6" key={index}>
                    <BoxLoading height={"6rem"} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <p className="fw-medium text-grey-200 text-sm">
                Shop / <span className="text-black-500">{data?.name}</span>
              </p>
              <div className="d-flex justify-content-between">
                <div className="text-black-500 fw-bold text-2xl">{data?.name}</div>
                <button
                  className="btn fav-icon"
                  disabled={wishlistLoading === data?.id}
                  onClick={() => toggleWishlist(data)}
                >
                  {wishlistLoading === data?.id ? (
                    <span className="spinner-border spinner-border-sm" />
                  ) : (
                    <Icon
                      icon={data?.inWishList ? "ri:heart-3-fill" : "ri:heart-3-line"}
                      className={`click text-2xl ${data?.inWishList ? "text-error" : ""}`}
                    />
                  )}
                </button>
              </div>
              <p className="fw-medium text-lg">{data?.shortInfo}</p>
              <div className="col-3 my-4">
                <hr />
              </div>
              <div className="d-flex flex-wrap gap-4 mb-4">
                <div
                  className={`price-tag ${!selected.newPrice ? "active" : ""}`}
                  onClick={() => setSelected((prev) => ({ ...prev, newPrice: false }))}
                >
                  <p className="text-primary-500 fw-semi-bold text-lg mb-1">Used</p>
                  <p className="xsub-title mb-0"> ${data?.usedPrice ? data?.usedPrice.toLocaleString() : ""}</p>
                </div>
                <div
                  className={`price-tag ${selected.newPrice ? "active" : ""}`}
                  onClick={() => setSelected((prev) => ({ ...prev, newPrice: true }))}
                >
                  <p className="text-primary-500 fw-semi-bold text-lg mb-1">New</p>
                  <p className="xsub-title mb-0"> ${data?.newPrice ? data?.newPrice.toLocaleString() : ""}</p>
                </div>
              </div>

              <p className="fw-semi-bold text-sm text-black-500 mb-2">Size</p>
              <div className="sizes-grid">
                {data?.sizes.map((size: string) => (
                  <div
                    key={size}
                    className={`size-number click text-uppercase ${selected.size === size ? "active" : ""}`}
                    onClick={() => setSelected((prev) => ({ ...prev, size }))}
                  >
                    {size}
                  </div>
                ))}
              </div>
              <br />
              <p className="fw-semi-bold text-sm text-black-500 mb-2">Color</p>
              <div className="row gap-4">
                {data?.color.map((color: string) => (
                  <div className="col-1" key={color}>
                    <div
                      className={`bg-white color-box click ${selected.color === color ? "active" : ""}`}
                      onClick={() => setSelected((prev) => ({ ...prev, color }))}
                    >
                      <div
                        className="color-box"
                        style={{
                          backgroundColor: color,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="row my-5">
                <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                  <div className="increment-btn">
                    <span
                      className="click"
                      onClick={() => {
                        decreaseQty();
                      }}
                    >
                      <Icon icon="ant-design:minus-outlined" />
                    </span>
                    <span>
                      {selected.quantity} pc{selected.quantity > 1 && "s"}
                    </span>
                    <span
                      className="click"
                      onClick={() => {
                        increaseQty();
                      }}
                    >
                      <Icon icon="akar-icons:plus" />
                    </span>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-9 mb-3">
                  <Button
                    className="btn btn-black btn-lg w-100"
                    title="Add to Cart"
                    loading={isAddToCart}
                    disabled={isAddToCart || !selected.size || !selected.color}
                    onClick={() =>
                      addToCart({
                        ...selected,
                        productId: data?.id,
                      })
                    }
                  />
                </div>
              </div>
              {data && <ReviewDetail product={data} />}
            </>
          )}
        </div>
      </div>
      {isOpenGallery && (
        <ProductGallery
          pictures={data?.heroImage ? [data.heroImage, ...data?.image] : data?.image}
          setIsOpenGallery={setIsOpenGallery}
        />
      )}
      {data && (
        <section className="my-9">
          <SimilarProducts productId={data.id} title="Items also in this lookbook" />
        </section>
      )}
      <section className="mb-9">
        <SuggestedItems />
      </section>
    </section>
  );
};
