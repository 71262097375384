import { AppContext } from "context/appContext";
import { getStorageData, saveStorageData, StorageKeys, removeStorageData } from "lib/storageManager";
import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { addLookBookToCart, saveLookBookCustomEdit } from "services/network/lookbooks";
import {
  addToCartRequest,
  addItemToWishlist,
  removeItemFromCartRequest,
  removeItemFromWishlist,
} from "services/network/products";
import { Cart, productsSchema, lookbookSchema } from "services/network/_schema";
function useAddProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getStorageData(StorageKeys.token);

  const [isAddToCart, setIsAddToCart] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState<string>("");
  const [isRemoveCart, setIsRemoveCart] = useState<string>("");
  const { getCart, setCart, mutateData }: { getCart: Function; cart: Cart[]; setCart: any; mutateData: any } =
    useContext(AppContext);

  const addToCart = async (selected: {
    productId: string;
    size: string;
    color: string;
    quantity: number;
    newPrice: boolean;
  }) => {
    if (token) {
      setIsAddToCart(true);
      const res: any = await mutateData({
        requestName: "addProductToCart",
        apiRequest: addToCartRequest,
        payload: selected,
      });
      setIsAddToCart(false);
      if (res.status === "success") {
        toast.success("Product added to cart successfully!");
        getCart();
        removeStorageData(StorageKeys.cart);
      }
    } else {
      saveStorageData(StorageKeys.cart, selected);
      navigate("/login", { state: { from: location.pathname } });
    }
  };

  const addLookbookToCart = async (
    lookbookId: string,
    selected: { productId?: string; size?: string; color?: string; quantity?: number; newPrice?: boolean }[]
  ) => {
    try {
      if (token) {
        setIsAddToCart(true);
        const res: any = await mutateData({
          requestName: "addLookbookToCart",
          apiRequest: addLookBookToCart,
          payload: {
            lookbookId,
            products: selected,
          },
        });
        setIsAddToCart(false);

        if (res) {
          toast.success("Lookbook added to cart successfully!");
          getCart();
          return;
        }
      } else {
        navigate("/login", { state: { from: location.pathname } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFromCart = async (cartId: string) => {
    if (window.confirm("Are you sure you want to remove this item from the cart?")) {
      setIsRemoveCart(cartId);
      const res: any = await mutateData({
        requestName: "removeItemFromCart",
        apiRequest: removeItemFromCartRequest,
        payload: { cartId },
      });
      setIsRemoveCart("");
      if (res.status === "success") {
        setCart((prev: Cart[]) => [...prev.filter((item) => item.cartId !== cartId)]);
      }
    }
  };

  const addToWishList = async (data: productsSchema | lookbookSchema) => {
    setWishlistLoading(data.id);
    const res = await mutateData({
      requestName: "addItemToWishlist",
      apiRequest: addItemToWishlist,
      payload: { itemId: data.id },
    });
    setWishlistLoading("");
    if (res?.status === "success") {
      toast.success("Item added to wishlist");
      data.inWishList = true;
      return res;
    }
  };

  const removeFromWishlist = async (
    data: productsSchema | lookbookSchema,
    setItems: any,
    items: any,
    sourcetype: string | undefined
  ) => {
    setWishlistLoading(data.id);
    const res: any = await mutateData({
      requestName: "removeItemFromWishlist",
      apiRequest: removeItemFromWishlist,
      payload: { itemId: data.id },
    });
    setWishlistLoading("");
    if (res?.status === "success") {
      toast.success("Product removed from wishlist");
      if (sourcetype !== undefined) {
        sourcetype === "lookbook"
          ? setItems(items.filter((x: any) => x?.lookbook?.id !== data?.id))
          : setItems(items.filter((x: any) => x?.product?.id !== data?.id));
      }
      data.inWishList = false;
    }
  };

  const toggleWishlist = async (
    data: productsSchema | lookbookSchema,
    setItems?: any,
    items?: any,
    sourcetype?: string
  ) => {
    if (token) {
      if (data.inWishList) {
        removeFromWishlist(data, setItems, items, sourcetype);
      } else {
        addToWishList(data);
      }
    } else {
      navigate("/login", { state: { from: location.pathname } });
    }
  };

  const saveCustomizedLookbook = async (
    lookbookId: string,
    selected: { productId?: string; size?: string; color?: string; quantity?: number; newPrice?: boolean }[]
  ) => {
    try {
      if (token) {
        setWishlistLoading(lookbookId);
        const res = await mutateData({
          requestName: "saveLookBookCustomEdit",
          apiRequest: saveLookBookCustomEdit,
          payload: {
            lookbookId,
            products: selected,
          },
        });

        if (res) {
          setWishlistLoading("");
        } else {
          console.log("res.data not working");
        }
      } else {
        navigate("/login", { state: { from: location.pathname } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isAddToCart,
    wishlistLoading,
    isRemoveCart,
    addToCart,
    toggleWishlist,
    removeFromCart,
    addLookbookToCart,
    saveCustomizedLookbook,
  };
}

export default useAddProduct;
