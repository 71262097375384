import React from "react";
import { TopCuratedLook } from "../homePage/topCuratedLook";
import "./styles.scss";
import irukere_lol from "assets/images/Irukele.svg";
import chain from "assets/images/silver-chain.svg";

export const OurStoryPage = () => {
  return (
    <section className="OurStoryWrapper container">
      <div className="row">
        <div className="col-lg-7 col-md-9 col-sm-12 mx-auto shop-banner">
          <p className="headline fw-bold text-black-500 text-center">
            My mantra has always been “good clothes open doors.” Now with Otito
            Lab, everyone can afford premium, personalized styling!
          </p>
        </div>
      </div>
      <section className="row story">
        <div className="col-lg-10 col-md-11 col-sm-12 mx-auto text-xl fw-medium text-black-500 text-center">
          <img src={irukere_lol} alt="" className="irukere_lol" />
          <p>
            I’ve made a career of giving professional styling advice to an elite
            celebrity client list.
          </p>
          <p>
            I’ve always found great satisfaction from helping many friends and
            family who constantly ask me what to buy and what to wear.
          </p>
          <p>
            So I got thinking: how could I bring what I do for my clients to
            everyone?
          </p>
          <p>
            We all know that online shopping has changed everything, but the
            choices online seem infinite; we can scroll through hundreds of
            little black dresses and thousands of pairs of jeans. Stores have
            associates, (who are not always experts), to help you make choices
            but buying online can be lonely. When I met Clea, I quickly realized
            we shared the same values and goals. I believed we could create the
            perfect chemistry of styling and tech that would enable me to bring
            my process of working with clients - understanding their bodies,
            needs and closets - to everyone. The result of that collaboration is
            Otito Lab.
          </p>
          <img src={chain} alt="" className="chain" />
        </div>
      </section>
      <section className="mb-9">
        <TopCuratedLook />
      </section>
    </section>
  );
};
