import React, { useCallback, useEffect, useState } from "react";
import { productsSchema } from "services/network/_schema";
import { GetProductLookBookDetailsRequest } from "services/network/lookbooks";
import useNetworkData from "hooks/useNetworkData";
import { SuggestedItems } from "sharedComponents/suggestedItems";

export const SimilarProducts = ({
  title,
  productId,
}: {
  title: string;
  productId: string;
}) => {
  const { getData } = useNetworkData();
  const [products, setProducts] = useState<productsSchema[]>([]);

  const getProductLookbkDetails = useCallback(async () => {
    const res = await getData({
      requestName: "getProductLookBookDetails",
      apiRequest: GetProductLookBookDetailsRequest,
      payload: { productId },
    });
    if (res && res.data) {
      setProducts(res.data[0]?.products || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    getProductLookbkDetails();
  }, [getProductLookbkDetails]);

  return (
    <>
      {products.length > 0 && (
        <SuggestedItems title={title} products={products} />
      )}
    </>
  );
};
