import React from "react";
import { lookbookSchema } from "services/network/_schema";
import { ImageComponent } from "sharedComponents/ImageComponent";
import { Icon } from "@iconify/react";
import useAddProduct from "hooks/useAddProduct";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

export const LookbookBanner = ({ lookbook }: { lookbook: lookbookSchema }) => {
  const { wishlistLoading, toggleWishlist } = useAddProduct();
  const navigate = useNavigate();
  return (
    <div className="lookbook-banner cursor-pointer">
      <ImageComponent src={lookbook.image} alt="" />

      <div
        className="lookbook-layer position-absolute text-white pt-0 px-4 d-flex align-items-end"
        onClick={() => navigate(`/lookbooks/${lookbook.id}`)}
      >
        <div className="d-flex mb-custom">
          <div className="col-11 ">
            <p className="text-2xl fw-bold">{lookbook.description}</p>
            <p className="text-sm fw-medium z-index-2">
              {lookbook.tags.map((item, index) => (
                <span key={item.id}>
                  {item.name}
                  {index < lookbook.tags.length - 1 && ", "}
                </span>
              ))}
            </p>
          </div>

          {wishlistLoading === lookbook.id ? (
            <div className="spinner-border spinner-border-sm" />
          ) : (
            <div className="fav-icon" onClick={() => toggleWishlist(lookbook)}>
              <Icon
                icon={lookbook?.inWishList ? "ri:heart-3-fill" : "ri:heart-3-line"}
                className={`click text-xxl ${lookbook.inWishList ? "text-error" : ""}`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
