import React, { useCallback, useEffect, useState } from "react";
import { CustomDropdownMenu } from "sharedComponents/CustomDropdownMenu";
import { CustomDropdwonItem } from "sharedComponents/CustomDropdwonItem";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.scss";
import { availableColors, availableSizes, sortvalues } from "utilities";
import { GetAllCategoriesRequest } from "services/network/products";
import useNetworkData from "hooks/useNetworkData";
import { CategorySchema, productFilters } from "services/network/_schema";

export const FilterSortContainer = ({
  totalCount,
  filterOptions,
  setFilterOptions,
  hideFilter,
  sortValues = [],
  setSortValues,
}: {
  totalCount: number;
  filterOptions?: productFilters;
  setFilterOptions?: any;
  sortValues?: string[];
  setSortValues?: any;
  hideFilter?: boolean;
}) => {
  const [catList, setCatList] = useState<CategorySchema[]>([]);
  const { getData } = useNetworkData();

  const updateSortList = (value: string) => {
    if (sortValues && sortValues.includes(value)) {
      setSortValues(sortValues.filter((v) => v !== value));
    } else {
      setSortValues([value]);
    }
  };

  const updateFilterList = (value: string, key: string) => {
    if ((filterOptions as any)[key].includes(value)) {
      setFilterOptions((prev: any) => ({
        ...prev,
        [key]: prev[key].filter((v: string) => v !== value),
      }));
    } else {
      setFilterOptions((prev: any) => ({
        ...prev,
        [key]: [...prev[key], value],
      }));
    }
  };

  const getCats = useCallback(async () => {
    const res = await getData({
      requestName: "getAllCategories",
      payload: {
        limit: 0,
        offset: 0,
      },
      apiRequest: GetAllCategoriesRequest,
    });
    if (res && res.data.length > 0) {
      setCatList(res.data);
    }
  }, [getData]);

  useEffect(() => {
    getCats();
  }, [getCats]);

  return (
    <div className="d-flex justify-content-between mb-6 filter-container">
      {!hideFilter && filterOptions && (
        <Dropdown className="d-inline mx-2 filters-card" autoClose="outside">
          <Dropdown.Toggle id="dropdown-toggle">
            <span className="text-sm">Filter</span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="fw-medium wide-filter-dropdown" as={CustomDropdownMenu}>
            <div className="row gx-5">
              <div className="col-md-4 col-6">
                <Dropdown.Item>
                  <div className="text-black-500 text-lg fw-semi-bold">Categories</div>
                </Dropdown.Item>
                {catList.map((item) => (
                  <Dropdown.Item className="click" key={item.id} as={CustomDropdwonItem}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item.id}
                        id={item.id}
                        onChange={({ target: { value } }: any) => updateFilterList(value, "category")}
                        checked={filterOptions.category.includes(item.id)}
                      />
                      <label className="form-check-label text-capitalize text-sm" htmlFor={item.id}>
                        {item.name}
                      </label>
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
              <div className="col-md-4 col-6">
                <Dropdown.Item>
                  <div className="text-black-500 text-lg fw-semi-bold">Sizes</div>
                </Dropdown.Item>
                {availableSizes.map((item) => (
                  <Dropdown.Item className="click" key={item} as={CustomDropdwonItem}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item}
                        id={item}
                        onChange={({ target: { value } }: any) => updateFilterList(value, "size")}
                        checked={filterOptions.size.includes(item)}
                      />
                      <label className="form-check-label text-capitalize text-sm" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
              <div className="col-md-4 col-6">
                <Dropdown.Item>
                  <div className="text-black-500 text-lg fw-semi-bold">Colors</div>
                </Dropdown.Item>
                {availableColors.map((item) => (
                  <Dropdown.Item className="click" key={item} as={CustomDropdwonItem}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item}
                        id={item}
                        onChange={({ target: { value } }: any) => updateFilterList(value, "color")}
                        checked={filterOptions.color.includes(item)}
                      />
                      <label className="form-check-label text-capitalize text-sm" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}

      <div className={`d-flex align-items-center ${hideFilter && "justify-content-between w-100"}`}>
        <p className="mb-0 me-3 text-sm fw-semi-bold">{totalCount} Items</p>
        <Dropdown className="d-inline mx-2" autoClose="outside">
          <Dropdown.Toggle id="dropdown-toggle">
            <span className="text-sm">Sort by</span> <span className="mx-1">:</span>
            {sortValues.map((sort, index) => (
              <span className="text-primary-500 text-sm text-capitalize" key={sort}>
                {sort} {index !== sortValues.length - 1 && ","}
              </span>
            ))}
          </Dropdown.Toggle>

          <Dropdown.Menu className="fw-medium">
            <Dropdown.Item className="border-bottom">
              <div className="text-black-500 text-lg fw-semi-bold">Sort by</div>
            </Dropdown.Item>
            {sortvalues.map((item) => (
              <Dropdown.Item className="click" key={item} as={CustomDropdwonItem}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radio-sort"
                    value={item}
                    id={item}
                    onChange={({ target: { value } }: any) => updateSortList(value)}
                    checked={sortValues.includes(item)}
                  />
                  <label className="form-check-label text-capitalize text-sm" htmlFor={item}>
                    {item}
                  </label>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
