import {
  ApolloClient,
  DocumentNode,
  FetchPolicy,
  InMemoryCache,
  OperationVariables,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { StorageKeys, getStorageData } from "lib/storageManager";

type Query = <QV extends OperationVariables | undefined, RT>(
  name: string,
  query: DocumentNode,
  variables?: QV,
  fetchPolicy?: FetchPolicy
) => Promise<RT>;
type Mutate = <MV extends OperationVariables | undefined, RT>(
  name: string,
  mutation: DocumentNode,
  variables?: MV
) => Promise<RT>;

export type GraphQLClient = {
  query: Query;
  mutate: Mutate;
};

const cache = new InMemoryCache({
  addTypename: false,
  resultCaching: false,
});
// Initialize apollo and set authorization token
const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}`,
  includeUnusedVariables: false,
});

const authLink = setContext((_, { headers }) => {
  const token = getStorageData(StorageKeys.token);
  return {
    headers: {
      ...headers,
      "Authorization-Token": token ? `${token}` : "",
    },
  };
});

export const GQLClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
  credentials: "same-origin",
});

export const GQLQuery: Query = (name, query, variables, fetchPolicy) => {
  return GQLClient.query({
    query,
    variables,
    fetchPolicy: fetchPolicy || "no-cache",
  }).then(({ data }: any) => data[name]);
};

export const GQLMutate: Mutate = (name, mutation, variables) => {
  return GQLClient.mutate({
    mutation,
    variables,
  }).then(({ data }: any) => data[name]);
};
