import { gql } from "@apollo/client";

export const GetProductsRequest = gql`
  query getAllProducts(
    $limit: Int!
    $offset: Int!
    $category: [String!]
    $tag: [String!]
    $brand: [String!]
    $size: [String!]
    $color: [String!]
    $nameLike: String
    $startDate: Date
    $endDate: Date
  ) {
    getAllProducts(
      limit: $limit
      offset: $offset
      category: $category
      tag: $tag
      brand: $brand
      size: $size
      color: $color
      nameLike: $nameLike
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      totalCount
      data {
        id
        inWishList
        name
        readableProductId
        shortInfo
        fullDetails
        image
        heroImage
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        createdAt
        updatedAt
      }
    }
  }
`;

export const GetMultipleProductByIdRequest = gql`
  query getMultipleProductById($ids: [ID!]!) {
    getMultipleProductById(ids: $ids) {
      status
      message
      totalCount
      data {
        id
        inWishList
        name
        readableProductId
        shortInfo
        fullDetails
        image
        heroImage
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        createdAt
        updatedAt
      }
    }
  }
`;

export const GetProductByIdRequest = gql`
  query getProductByNameOrId($value: String!) {
    getProductByNameOrId(value: $value) {
      status
      totalCount
      data {
        id
        name
        inWishList
        readableProductId
        shortInfo
        fullDetails
        image
        heroImage
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        createdAt
        updatedAt
      }
    }
  }
`;

export const GetAllTagsRequest = gql`
  query getAllTags($limit: Int!, $offset: Int!) {
    getAllTags(limit: $limit, offset: $offset) {
      status
      totalCount
      data {
        id
        name
        image
        description
      }
    }
  }
`;

export const GetAllCategoriesRequest = gql`
  query getAllCategories($limit: Int!, $offset: Int!) {
    getAllCategories(limit: $limit, offset: $offset) {
      status
      totalCount
      data {
        id
        name
        image
        description
      }
    }
  }
`;

export const GetAllBrandsRequest = gql`
  query getAllBrands($limit: Int!, $offset: Int!) {
    getAllBrands(limit: $limit, offset: $offset) {
      status
      totalCount
      data {
        id
        name
        image
        description
      }
    }
  }
`;

export const GetBrandByIdRequest = gql`
  query getBrandByNameOrID($value: String!) {
    getBrandByNameOrID(value: $value) {
      status
      totalCount
      message
      data {
        id
        name
        image
        description
      }
    }
  }
`;

export const GetProductByBrandOrTagIdRequest = gql`
  query getProductByBrandCategoryOrTagId($value: String!, $limit: Int!, $offset: Int!) {
    getProductByBrandCategoryOrTagId(value: $value, limit: $limit, offset: $offset) {
      status
      totalCount
      message
      data {
        id
        name
        readableProductId
        shortInfo
        image
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        inWishList
        createdAt
      }
    }
  }
`;

export const GetCartRequest = gql`
  query getUserCart {
    getUserCart {
      status
      message
      data {
        cartId
        itemId
        itemCategory
        details {
          productId
          quantity
          color
          size
          amount
          datetime
        }
        product {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          heroImage
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
        }
        lookbook {
          id
          name
          description
          image
        }
      }
    }
  }
`;

export const addToCartRequest = gql`
  mutation addProductToCart($productId: ID!, $quantity: Int!, $color: String!, $size: String!, $newPrice: Boolean!) {
    addProductToCart(productId: $productId, quantity: $quantity, color: $color, size: $size, newPrice: $newPrice) {
      status
      message
    }
  }
`;

export const editProductInCartRequest = gql`
  mutation editProductInCart($cartId: String!, $quantity: Int!, $color: String!, $size: String!) {
    editProductInCart(cartId: $cartId, quantity: $quantity, color: $color, size: $size) {
      status
      message
    }
  }
`;

export const removeItemFromCartRequest = gql`
  mutation removeItemFromCart($cartId: String!) {
    removeItemFromCart(cartId: $cartId) {
      status
      message
    }
  }
`;

export const addItemToWishlist = gql`
  mutation addItemToWishlist($itemId: ID!) {
    addItemToWishlist(itemId: $itemId) {
      status
      message
    }
  }
`;

export const ShareCartRequest = gql`
  mutation shareCart($emailAddress: [String!], $cartId: ID) {
    shareCart(emailAddress: $emailAddress, cartId: $cartId) {
      status
      message
    }
  }
`;

export const CloneCartRequest = gql`
  mutation cloneSharedCart($id: ID!) {
    cloneSharedCart(id: $id) {
      status
      message
    }
  }
`;

export const removeItemFromWishlist = gql`
  mutation removeItemFromWishlist($itemId: ID!) {
    removeItemFromWishlist(itemId: $itemId) {
      status
      message
    }
  }
`;

export const GetWishlistRequest = gql`
  query getUserWishList {
    getUserWishList {
      status
      message
      data {
        sourceType
        product {
          id
          name
          readableProductId
          shortInfo
          fullDetails
          image
          tags
          category
          brand
          usedPrice
          newPrice
          ratings
          sizes
          units
          color
          createdAt
          updatedAt
          # inWishList
        }
        lookbook {
          id
          name
          description
          image
          # inWishList
        }
      }
    }
  }
`;

export const createPaymentRequest = gql`
  mutation createPaymentIntent($totalPrice: Float!) {
    createPaymentIntent(totalPrice: $totalPrice) {
      status
      message
      clientSecret
    }
  }
`;

export const confirmPaymentRequest = gql`
  mutation completeCheckout($paymentIntentId: String!, $shippingFee: Float!, $vat: Float!) {
    completeCheckout(paymentIntentId: $paymentIntentId, shippingFee: $shippingFee, vat: $vat) {
      status
      message
    }
  }
`;

export const GetNewArrivalRequest = gql`
  query getNewProductArrival {
    getNewProductArrival {
      status
      message
      totalCount
      data {
        id
        name
        readableProductId
        shortInfo
        fullDetails
        image
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        createdAt
        updatedAt
      }
    }
  }
`;

export const GetProductReviewRequest = gql`
  query getProductReview($id: ID!, $limit: Int!, $offset: Int!) {
    getProductReview(id: $id, limit: $limit, offset: $offset) {
      status
      totalCount
      message
      data {
        id
        user {
          id
          fullName
        }
        comment
        ratings
        updatedAt
      }
    }
  }
`;

export const ReviewProductRequest = gql`
  mutation reviewProduct($id: ID!, $ratings: Float!, $comment: String!) {
    reviewProduct(id: $id, ratings: $ratings, comment: $comment) {
      status
      message
    }
  }
`;

export const GetBuyOfTheMonth = gql`
  query getBuyOfTheMonth {
    getBuyOfTheMonth {
      status
      message
      data {
        id
        name
        readableProductId
        shortInfo
        fullDetails
        image
        heroImage
        tags
        category
        brand
        usedPrice
        newPrice
        ratings
        sizes
        units
        color
        createdAt
        updatedAt
      }
    }
  }
`;
