import React from "react";

export const CustomDropdownMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy }: any,
    ref: any
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {React.Children.toArray(children).map((child) => child)}
      </div>
    );
  }
);
