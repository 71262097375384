import React, { useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "sharedComponents/PrivateRoute";
import AccountPage from "pages/authenticated/account";

const AccountRoutes = () => {
  useLayoutEffect(() => {
    document.title = "Otito Lab - Account";
  }, []);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute>
              <AccountPage />
            </PrivateRoute>
          }
        />

        {/* <Route path="*" element={NotFoundPage} /> */}
      </Routes>
    </>
  );
};

export default AccountRoutes;
