import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Icon } from "@iconify/react";
import "./styles.scss";
import { SharedCartDetails } from "./sharedCartDetails";
import { OrderDetails } from "./orderDetails";
import { useSearchParams } from "react-router-dom";

function CustomToggle({ children, eventKey, callback, activeEventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <p onClick={decoratedOnClick} className="text-sm fw-semi-bold text-black-500 click d-flex gap-3">
      {children}
      <Icon icon={isCurrentEventKey ? "akar-icons:chevron-down" : "akar-icons:chevron-right"} />
    </p>
  );
}
export const ItemWithDetail = ({ shared, item, received }: { shared?: boolean; item: any; received?: boolean }) => {
  const [activeEventKey, setActiveEventKey] = useState<string>("");
  const btnRef = useRef<any>(null);
  const shortenId = (id: string) => {
    return id ? id.slice(0, 9) : "";
  };
  let [searchParams] = useSearchParams();
  const paramId = searchParams.get("id");
  const autoClick = useAccordionButton(paramId || "");

  useEffect(() => {
    if (paramId) {
      setActiveEventKey(paramId);

      btnRef.current?.click();
    }
  }, [paramId, searchParams]);

  return (
    <div className="mb-5">
      <button ref={btnRef} onClick={autoClick}></button>
      <ul className="list-unstyled item-wrapper">
        <li className="item-price">
          <ul className="d-flex justify-content-between gap-5">
            <li className="d-flex align-items-center">
              <div>
                <Icon icon="ph:shopping-cart-light" className="text-xxl mb-4 me-2" />
              </div>
              <div>
                <p className="text-xs text-grey-300 mb-1 fw-medium">#{shortenId(item.sharedCartId || item.id)}</p>
                <p className="text-sm fw-semi-bold text-black-500">
                  Item(s): {shared ? item.cart.length : item.items.length}
                </p>
              </div>
            </li>
            <li>
              <p className="text-xs text-grey-300 mb-1 fw-medium">Total price</p>
              <p className="text-sm fw-semi-bold text-black-500">${item.totalAmount.toLocaleString()}</p>
            </li>
          </ul>
        </li>

        <li className="products">
          <p className="text-xs text-grey-300 mb-1 fw-medium">Product(s)</p>
          <p className="text-sm fw-semi-bold text-black-500">
            {shared ? (
              <>
                {item.cart &&
                  !item.cart.includes(null) &&
                  item.cart.map((cart: any, index: number) => (
                    <span key={index}>
                      {cart.product && cart.product !== null && cart.product.name}
                      {cart.lookbook && cart.lookbook !== null && `${cart.lookbook.name}- Lookbook`}
                      {index < item?.cart?.length - 1 && ","}{" "}
                    </span>
                  ))}
              </>
            ) : (
              <>
                {item.items.map((cart: any, index: number) => (
                  <span key={index}>
                    {cart.product && cart.product !== null && cart.product.name}
                    {index < item?.cart?.length - 1 && ","}{" "}
                    {cart.lookbook && cart.lookbook !== null && `${cart.lookbook.name}, Lookbook`}
                  </span>
                ))}
              </>
            )}
          </p>
        </li>
        <li className="actions">
          <ul className="d-flex justify-content-between gap-5">
            <li>
              <p className="text-xs text-grey-300 mb-1 fw-medium">
                {received ? "Shared by" : shared ? "Shared with" : "Delivery Status"}
              </p>
              <p className={`text-sm fw-semi-bold text-break ${shared ? "text-black-500" : "text-primary-500"}`}>
                {received
                  ? item.sharedBy?.emailAddress
                  : shared
                  ? `${item.sharedWith.length} friend(s)`
                  : item.deliveryStatus}
              </p>
            </li>
            <li>
              <p className="text-xs text-grey-300 mb-1 fw-medium">Action</p>
              <div>
                <CustomToggle
                  eventKey={`${item.sharedCartId || item.id}`}
                  callback={(e: any) => {
                    if (e === activeEventKey) {
                      setActiveEventKey("");
                    } else {
                      setActiveEventKey(e);
                    }
                  }}
                  activeEventKey={activeEventKey}
                >
                  Details
                </CustomToggle>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <hr />
      <Accordion.Collapse eventKey={`${item.sharedCartId || item.id}`} className="px-lg-6">
        {shared ? <SharedCartDetails item={item} received={received} /> : <OrderDetails item={item} />}
      </Accordion.Collapse>
    </div>
  );
};
