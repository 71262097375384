import { AppContext } from "context/appContext";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { CloneCartRequest, ShareCartRequest } from "services/network/products";

export const useShareCart = (mutateData: any) => {
  const [emailAddresses, setEmailAddresses] = useState("");
  const [cloning, setCloning] = useState(false);
  const [addingEmail, setAddingEmail] = useState(false);
  const emailRegex = /^\S+@\S+\.\S+$/;
  const { getCart }: { getCart: Function } = useContext(AppContext);

  const shareCart = async (cartId?: string) => {
    let errors = 0;
    const emails = emailAddresses.split(",").map((email) => {
      if (email.trim()) {
        if (!emailRegex.test(email.trim())) errors++;
        return email.trim();
      }
      return null;
    });
    if (errors > 0) {
      toast.error("Please enter a valid email address!");
      return;
    }
    setAddingEmail(true);
    const res = await mutateData({
      requestName: "shareCart",
      apiRequest: ShareCartRequest,
      payload: { emailAddress: emails.filter((email) => email), cartId },
    });
    setAddingEmail(false);
    if (res?.status === "success") {
      toast.success("Cart shared!");
    }
  };

  const cloneCart = async (id: string) => {
    setCloning(true);
    const res = await mutateData({
      requestName: "cloneSharedCart",
      apiRequest: CloneCartRequest,
      payload: { id },
    });
    setCloning(false);
    if (res?.status === "success") {
      getCart();
      setTimeout(() => {
        toast.success("Cart cloned!");
      }, 3000);
    }
  };

  return {
    addingEmail,
    emailAddresses,
    setEmailAddresses,
    shareCart,
    cloning,
    cloneCart,
  };
};
